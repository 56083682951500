
<section>
  <header class="sub">
    <h1 class="flexible-space">
      Recents
    </h1>
    <button
      class="mini"
      :disabled="recentParticipants.length<1"
      @click="clearAll"
    >
      Clear
    </button>
  </header>

  <transition-group
    name="sidebar-list"
    tag="ul"
    class="notifications naked"
  >
    <li
      v-for="par in recentParticipants"
      :key="par.id"
      class="par"
      @click="showParticipant(par)"
    >
      <span
        class="bib"
        :style="bibStyle(par)"
      ><span>{{ par.bib }}</span></span>
      <span class="fn">{{ par.firstName }}</span> <span class="ln">{{ par.lastName }}</span>
    </li>
  </transition-group>
</section>
