
<transition name="modal-window" @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @after-leave="afterLeave">
  <div class="modal-window-wrapper">
    <article v-if="showModal" :class="{'modal-window':true, 'scrollable': scrollable, 'has-controls':controlButtons, foreground: foreground}">
      <div class="modal-content">
        <slot />
      </div>
    
      <div v-if="loading" class="modal-progress">
        <spinner />
      </div>
    
      <div v-if="controlButtons && controlButtons.length > 0 && showControlButtons" class="modal-control">
        <i class="flexible-space" />
        <button v-for="(def, index) in controlButtons" :key="index" :class="def['class']" @click="controlButtonClick(def)">
          {{ def['label'] }}
        </button>
      </div>
    </article>
  </div>
</transition>
