<template>
  <keep-alive>
    <transition name="navi" @before-enter="willShowView">
      <component :is="currentComponentName" ref="navView" v-bind="currentProps"/>
    </transition>
  </keep-alive>
</template>

<script>
  export default {
    
    props: {
      navigationStack: {
        type: Array,
        required: true
        // {'component-name', {prop1: 1, prop2: 2}}
      }
    },
    
    data() {
      return {
        views: [],
        $isNavigation: true,
      }
    },
        
    computed: {
      currentEntry() {
        // Return last entry in stack
        if (!this.views || this.views.length < 1) 
          return console.error("Nav: no views");
        
        const entry = this.views[this.views.length-1];
        return entry;
      },

      currentComponentName() {
        const entry = this.currentEntry;
        if (typeof entry === 'string') return entry;
        return entry.name;
      },
      
      currentProps() {
        const entry = this.currentEntry;
        if (typeof entry === 'string') return {};
        return entry.props;
      }
    },
    
    created() {
      // should dup, testing.
      this.$isNavigation = true;
      this.navigationStack.forEach((e) => {
        console.debug("nav: initial pushing", e);
        this.views.push(e);
      });      
    },
    
    updated() {
      // Recursively set all children's $navigationView property
      // to point to this navigationView.
      //
      const setNavigationView = (vm) => { 
        if (!vm.$isNavigation && vm != this) { 
          vm.$navigationView = this 
          vm.$modalWindow = this.$modalWindow;
        }
        vm.$children.forEach((c) => setNavigationView(c));
      };
      
      setNavigationView(this);
    },
        
    methods: {
      push(componentName, props) {
        const entry = {name: componentName, props: props};
        // this.navigationStack.push(entry);
        this.$set(this.views, this.views.length, entry);
      },
      
      pop(count=1) {
        if (count > this.views.length-1)
          count = this.views.length-1;
        for (let i=0; i < count; i++)
          this.views.pop();
      },
      
      willShowView() {
        const newView = this.$children[this.$children.length-1]
        console.debug("nav: will show view", newView);

        if (newView.willShowInNavView)
          newView.willShowInNavView(this);

        this.$nextTick(()=> {
          if (newView.didShowInNavView)
            newView.didShowInNavView(this);
        })
      }
    }
    
  }
</script>