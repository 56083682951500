
<article class="insert-laps">
  <header class="sub">
    <h1>Insert Laps Crossings</h1>
    <i class="flexible-space" />
  </header>
  
  <header class="details">
    <form>
      <div class="form-row">
        <label>Insert</label>
        <input class="mini" type="number" step="1" min="1" v-model.number="insertCount">
        
        <label>Event Source</label>
        <label v-if="selectedSplit">{{ selectedSplit.name }} →</label>
        <select v-model="selectedEventSourceId">
          <option v-for="es in eventSources" :key="es.id" :value="es.id">{{ es.name }}</option>
        </select>
      </div>
      <div class="form-row">
        <label>Apply Jitter</label>
        <input type="checkbox" v-model="applyJitter">
      </div>
    </form>
    <p class="dur">
      Inserting into
      <span class="hours" v-if="deltaTimeDur.hours">{{deltaTimeDur.hours}}</span>
      <span class="min">{{ deltaTimeDur.minutes }}</span>
      <span class="sec">{{ pad(deltaTimeDur.seconds, 2) }}</span>
      <span class="ms">{{ pad(deltaTimeDur.ms, 3) }}</span>
      gap; Will add {{ (finishSplit.distance * newCrossingDescriptors.length) | mToDistance({units: true}) }}
    </p>
  </header>
  
  <div class="table-scroll">
    <table class="lap-preview ">
      <thead>
        <tr>
          <th class="lap">
            Lap
          </th>
          <th class="ts">
            Timestamp
          </th>
          <th class="dur">
            Lap Duration
          </th>
        </tr>
      </thead>

      <tbody class="">
        <tr class="existing from" v-if="prevRealCrossing">
          <td>{{ prevRealCrossing.loopId }}</td>
          <td>{{ prevRealCrossing.timestamp && dateFormatter.format(prevRealCrossing.timestamp) || '-' }}</td>
          <td>-</td>
        </tr>
        <tr v-else>
          <td>-</td>
          <td>Race Start</td>
          <td>-</td>
        </tr>
      
        <tr class="new" v-for="desc in newCrossingDescriptors" :key="desc.loopId">
          <td>{{ desc.loopId }}</td>
          <td>{{ dateFormatter.format(desc.timestamp) }}</td>
<!--needs duration-display -->
          <td class="dur" v-if="desc.duration">
            <span class="hours" v-if="desc.duration.hours">{{desc.duration.hours}}</span>
            <span class="min">{{ desc.duration.minutes }}</span>
            <span class="sec">{{ pad(desc.duration.seconds, 2) }}</span>
            <span class="ms">{{ pad(desc.duration.ms, 3) }}</span>
          </td>
        </tr>

        <tr class="existing to" v-if="beforeCrossing">
          <td>{{ beforeCrossing.loopId + newCrossingDescriptors.length }}</td>
          <td>{{ beforeCrossing.timestamp && dateFormatter.format(beforeCrossing.timestamp) || '-' }}</td>
          <td class="dur" v-if="lastDuration">
            <span class="hours" v-if="lastDuration.hours">{{lastDuration.hours}}</span>
            <span class="min">{{ lastDuration.minutes }}</span>
            <span class="sec">{{ pad(lastDuration.seconds, 2) }}</span>
            <span class="ms">{{ pad(lastDuration.ms, 3) }}</span>
          </td>
          <td class="dur unknown" v-else>-</td>
        </tr>
      </tbody>
    </table>
  </div>
</article>
