export default class UndoStack {
  constructor() {
    this.stack = [];
    this.redoStack = [];
  }
  
  pushChange(participantId, attr, value) {
    if (this.stack.length) {
      // don't push duplicate changes
      const last = this.stack[this.stack.length-1];
      if (last.pid == participantId && last.attr == attr && last.val == value)
        return;
    }
    
    this.stack.push({
      pid: participantId,
      attr,
      val: value
    });
  }
}