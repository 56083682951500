
<div id="app" :class="{'force-expanded-nav': forceExpandedNav, 'expanded-nav': (forceExpandedNav || expandNav), 'expanded-sidebar': expandSidebar}">
  <header class="root main">
    <div class="backdrop-hack"></div>
    <popover-static
      className="hot-nav" 
      :title="raceButtonTitle" 
      :detail-text="raceButtonDetail" prefer="dn" class="race-button" 
      :img="raceEventImageUrl"
      @mouseover="mouseOverNav($event, true)" @mouseleave="mouseOverNav($event, false)"
    >
      <race-events />
      <template v-slot:alternate>
        <race-event-summary :race-event="activeRaceEvent" />
      </template>
    </popover-static>
    <search-bar :autosave-key="searchAutosaveKey" @resultClick="searchResultClick" />
    <race-event-status />
    
    <ul class="header-buttons">
      <li class="header-actions"><button @click="showActions" class="round" title="Actions"></button></li>
      <li class="sidebar-toggle" :data-alert="notifications.length ? notifications.length  : ''"><button class="round" title="Toggle Sidebar" @click="showSidebar(false)">Toggle Sidebar</button></li><!-- https://fontawesome.com/icons/caret-square-left?style=solid -->
    </ul>
    <transition name="toast">
      <div class="progress" v-if="showProgressToast">
        <h1>{{progressTag}}</h1>
        <progress :value="progressPercentage" max="100" />
      </div>
    </transition>
  </header>
  
  <nav id="main" @mouseover="mouseOverNav($event, true)" @mouseleave="mouseOverNav($event, false)" :class="{expanded: expandNav}">
    <ul>
      <li>
        <router-link tag="button" class="dashboard" to="/">Dashboard</router-link>
      </li>
      
      <li>
        <router-link tag="button" 
          class="schedule"
          :to="{name: 'schedule', params: {raceEventId: activeRaceEvent && activeRaceEventId || 0}}"
          :disabled="activeRaceIdMissing">
          Schedule
        </router-link>
      </li>
      
      <li>
        <router-link tag="button"
          class="participants"
          :to="{name: 'participants', params: {raceEventId: activeRaceEvent && activeRaceEventId || 0}}"
          :disabled="activeRaceIdMissing">
          Participants
        </router-link>
      </li>
      
      <li>
        <router-link tag="button"
          class="teams"
          :to="{name: 'teams', params: {raceEventId: activeRaceEvent && activeRaceEventId || 0}}"
          :disabled="activeRaceIdMissing">
          Fastest Teams
        </router-link>
      </li>
      
      <li>
        <router-link tag="button"
          class="manual-entry"
          :to="{name: 'manual', params: {raceEventId: activeRaceEvent && activeRaceEventId || 0}}"
          :disabled="activeRaceIdMissing">
          Manual Time
        </router-link>
      </li>

      <li>
        <router-link tag="button"
          class="addons"
          :to="{name: 'addons', params: {raceEventId: activeRaceEvent && activeRaceEventId || 0}}"
          :disabled="activeRaceIdMissing">
          Addons
        </router-link>
      </li>
      
      <!-- <li>
        <router-link tag="button"
          class="podium"
          :to="{name: 'podium', params: {raceEventId: activeRaceEvent && activeRaceEventId || 0}}"
          :disabled="activeRaceIdMissing">
          Podiums
        </router-link>
      </li> -->
      
      <!-- <li>
        <router-link tag="button"
          class="waves"
          :to="{name: 'wavegrid', params: {raceEventId: activeRaceEvent && activeRaceEventId || 0}}"
          :disabled="activeRaceIdMissing">
          Waves
        </router-link>
      </li> -->
      
      <li>
        <router-link tag="button" 
          class="map"
          :to="{name: 'map', params: {raceEventId: activeRaceEvent && activeRaceEventId || 0}}"
          :disabled="activeRaceIdMissing">
          Map
        </router-link>
      </li>
      
      <li>
        <router-link tag="button" 
          class="crossings"
          :to="{name: 'crossings', params: {raceEventId: activeRaceEvent && activeRaceEventId || 0}}"
          :disabled="activeRaceIdMissing">
          Crossings
        </router-link>
      </li>

      <li>
        <router-link tag="button" 
          class="stats"
          :to="{name: 'stats', params: {raceEventId: activeRaceEvent && activeRaceEventId || 0}}"
          :disabled="activeRaceIdMissing">
          Demographics
        </router-link>
      </li>
      
      
      <li>
        <router-link tag="button" 
          class="livestream"
          :to="{name: 'livestream', params: {raceEventId: activeRaceEvent && activeRaceEventId || 0}}"
          :disabled="activeRaceIdMissing">
          Livestream
        </router-link>
      </li>
      
      <li class="flexible-space">&nbsp;</li>
      <li><hr class="separator">&nbsp;</hr></li>
      
      
      <li class="version hide-collapsed">
        <h1 class="logo" @click="toggleMobileMenu">Version</h1>
        <span class="branch">{{version.branch}} / {{version.hash}}</span>
        <span class="date">{{ version.date }}</span>
      </li>
      
      <li><hr class="separator">&nbsp;</hr></li>
      
      <li>
        <router-link tag="button" class="sources" to="/event-sources">Timing Points</router-link>
      </li>
      
      <li>
        <router-link tag="button" 
          class="settings"
          :to="{name: 'settings'}"
          >
          Settings
        </router-link>
      </li>
    </ul>
    
  </nav>

  <router-view class="rv main"/>
  <notification-bar v-if="expandSidebar" />

  <wave-planner v-if="showWavePlanner" />
  <ticker />
  
</div>
