export function localStorageRef(propName) {
  return function() {
    let x = this.$root.lsProp;// reference hack
    console.log("compute localStorageRef", propName);
    return this.$root[propName];
  }
}

export function fromLocalStorage(propName, transformer=null) {
  let raw = window.localStorage[propName];
  let value = raw && JSON.parse(raw)
  return transformer ? transformer(value) : value;
}

export function localStorageProperty(propName, transformer=null) {
  return {
    get() { 
      console.debug(`localStorage: read '${propName}'`);
      let counter = this.$root.lsProp;
      let raw = window.localStorage[propName];
      let value = undefined;
      
      try {
        value = raw && JSON.parse(raw)
      }
      catch (e) {
        console.error(`Could not parse localStorage[${propName}]`, raw);
        value = raw;
      }
      return transformer ? transformer(value) : value;
    },
    set(newValue) {
      console.log(`localStorage: set ${propName} =>`, newValue);
      if (!this.$root.lsProp) 
        return console.error("please define lsProp as numeric data prop on $root");

      this.$root.lsProp++;
      window.localStorage[propName] = JSON.stringify(newValue);
    }
  }
}