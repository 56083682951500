// If the input is wrapped by elements with v-if, the DOM insertion element will cause it to lose
// focus after the first character is input. This is very bad UX.
<template>
  <div class="search-wrap">
    <input ref="searchbar" :disabled="disabled" :key="'main-search'" v-model="q" type="search" :class="{main: true, 'results-shown': showResults}" :autosave="autosaveKey" @focus="focus" @blur="blur" @keyup="onkey" spellcheck="off" autocomplete="off" autocorrect="off" autocapitalize="off">
    <button v-if="q" key="search-clear" class="clear" @click="clear">
      Clear
    </button>
    <ul v-if="showResults" key="search-results" class="results">
      <li v-if="results.length < 1" class="no-results">
        No Results
      </li>
      <component :is="result.component" v-for="(result, i) in results" v-else :key="i" 
        :subject="result.subject" 
        :class="{selected: selectedResult==result}" 
        @resultClick="onResultClick"
        />
    </ul>
    <div v-if="showResults" key="search-modal-cover" class="modal-cover" @click="showResults=false">
&nbsp;
    </div>
  </div>
</template>

<style lang="scss">
.search-wrap {

  position: relative;
    
  &:before {
    // search magnifying glass
    z-index: 9;
    font-size: 18px;
    font-weight: 900;
    display: block;
    position: absolute;
    z-index: 22;
    left: 12px; 
    top: 50%;
    transform: translateY(-50%);
    font-family: 'Font Awesome 5 Free';
    content: '\f002';
    color: var(--text-color);
    text-shadow: 0 -2px 3px var(--neg-color);
    pointer-events: none;
  }
  
  input.main { 
    -webkit-appearance: none;
    
    position: relative;
    z-index: 8;
    font-size: 28px;
    height: 46px;
    width: 100%;
//width: 100px;
    min-width: 33px;
    border: none;
    margin: 0 !important;
    padding-left: 3ex;
    border-radius: var(--br);
    
    &:not(:placeholder-shown) {
      border: 1px solid rgba(0,0,0,0.4) !important;
    }
    
    &::-webkit-search-cancel-button {
      // We're going to show our own clear button.
      display: none;
    }
    
    &.results-shown {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      z-index: 21;
    }
  }
  
  .clear {
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(128,128,128,0.7);
    z-index: 23;
    border: none;
    border-radius: 50%;
    //    left: calc(50vw - 12px - 18px); //? There's gotta be a reason it was left-origin'd.
    color: var(--neg-color);
    text-align: center;
    text-indent: -9999px;
    
    &:before {
      content: '\f00d';
      color: var(--neg-color);
      text-indent: 2px; // ?
      margin: 0;
      background: transparent;
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-transform: small-caps;
    }
    
    &:hover {
      background: var(--text-color);
      transition: none;
    }
  }
 
  .results {
    position: absolute;
    z-index: 21;
    list-style: none;
    background: var(--popover-abgcolor);
    box-shadow: 0 14px 18px rgba(0,0,0,0.5);
    backdrop-filter: blur(10px);
    margin: 0 1px;
    border-radius: 6px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 1em 0;
    box-sizing: border-box;
    width: 100%;
    max-height: 90vh;
    overflow-y: scroll;
    
    .no-results {
      text-align: center;
      line-height: 56px;
    }
    
    li {
      padding: 0 1em;
    }
    
    li.selected,
    li:hover,
    li:focus {
      background: var(--row-hover-bgcolor);
    }
  }
  
  .modal-cover {
    z-index: 20;
  }
}


@media screen and (max-width: 437px){
  .results {
    left: 0;
    right: 0;
    width: auto;
  }
}

</style>

<script>
import SrrParticipant from "@/components/search-result-row/srr-participant.vue"
import SrrRaceEvent from "@/components/search-result-row/srr-race-event.vue"

export default {
  components: {SrrParticipant, SrrRaceEvent },
  
  props: {
    autosaveKey: {
      type: String,
      required: false,
      default: 'momentum-global'
    },
    
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    }
  },
  
  data: () => ({
    q: '',
    selectedResult: undefined, // keyboard focus for results
    showResults: false
    
  }),
  
  computed: {
    allParticipants() {
      const activeRaces = this.$store.state.activeRaceEvent.races;
      return Object.values(this.$store.state.participants).filter(par=>activeRaces.includes(par.raceId));
    },
    
    results() {
      if (!this.q.length || this.q.length < 1)
        return []
      
      const q = this.q.toLowerCase();
      const terms = q.split(' ');
      
      // Push into different arrays. We want to give up filtering the array when max 
      // results are found. Array.some stops when you return false. Our lambdas
      // return the correct response to either keep going or not.
      //
      let participants = [],
          raceEvents   = [];
      let reject = (dest) => (dest.length > 10);
      let accept = (dest, x) => {
        if (dest.indexOf(x) < 0) dest.push(x);
        return dest.length > 10;
      }
      
      // Iterate current race events
      const allRaceEvents = Object.values(this.$store.state.raceEvents);
      let re;
      for (var i = allRaceEvents.length - 1; i >= 0; i--) {
        re = allRaceEvents[i];
        terms.forEach(term => { if (re.name.toLowerCase().indexOf(term) >= 0) accept(raceEvents, re)});
      }
        
      // Iterate until we have enough matches
      this.allParticipants.some(par => {
        if (par.bib == q) return accept(participants, par);
        
        let match = terms.every(term => {
          if (par.firstName && par.firstName.toLowerCase().indexOf(term) >= 0) return accept(participants, par);
          if (par.lastName && par.lastName.toLowerCase().indexOf(term) >= 0) return accept(participants, par);
        });

        if (match) return accept(participants, par);
        
        if (par.notes && par.notes.includes(q)) return accept(participants, par);
        
        return reject(participants);
      });

      return [
        ...participants.map(x => ({component: SrrParticipant, subject: x})),
        ...raceEvents.map(x   => ({component: SrrRaceEvent, subject: x})),
      ];
    }
  },
  
  watch: {
    q(newVal) {
      this.$nextTick(() => {
        if (this.q) {
          this.showResults = true;
        }
      });
    }
  },
  
  mounted() {
    this.$root.$on('cmd-f', (e => {
      e.preventDefault();

      if (!this.$refs.searchbar)
        // not fully loaded yet? Don't want this codepath poisoned.
        return;
      
      this.$refs.searchbar.focus();
      this.$refs.searchbar.select();
    }).bind(this));
    
    this.$root.$on('escapePressed', (() => {
      this.showResults = false;
      this.$refs.searchbar.blur();
    }).bind(this));
  },
  
  methods: {
    onResultClick(event) {
      this.showResults=false;
      this.$emit('resultClick', event);
    },
  
    focus() {
      if (this.q.length) {
        this.showResults = true;
        this.$refs.searchbar.select();
      }
    },

    blur() {
      console.debug('blur');
    },
    
    clear() {
      this.q = '';
      this.showResults = false;
    },
    
    onkey(e) {
      let i;
      switch (e.key) {
        default: 
          // not interesting
          return;

        case 'Escape':
          this.showResults = false;
          this.$refs.searchbar.blur();
          break;
                    
        case 'ArrowDown':
          e.preventDefault();
          if (!this.selectedResult) {
            this.selectedResult = this.results[0];
            break;
          }
          
          i = this.results.indexOf(this.selectedResult);
          // i might be -1
          this.selectedResult = this.results[++i];
          break;

        case 'ArrowUp':
          e.preventDefault();
          if (!this.selectedResult) {
            this.selectedResult = this.results[this.results.length - 1];
            break;
          }
          
          i = this.results.indexOf(this.selectedResult);
          // i might be -1
          if (i < 0) i = this.results.length;
          this.selectedResult = this.results[--i];
          break;

        case 'Enter': {
          e.preventDefault();
          // Get child component. We're going to simulate a click.
          const index = this.results.indexOf(this.selectedResult);
          if (index < 0) 
            return;

          const vm = this.$children[index];
          vm.handleClick();
          break;
        }
      }
    }
  }
}

</script>