<template>
  <svg class="indicator" xmlns="http://www.w3.org/2000/svg" baseProfile="tiny" version="1.2" :viewBox="viewBox" :width="size" :height="size">
    <defs>
      <filter id="inset-shadow">
        <feFlood flood-color="black" />
        <feComposite operator="out" in2="SourceGraphic"/>
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite operator="atop" in2="SourceGraphic"/>
      </filter>
    </defs>
    <circle :r="size/2" :cx="size/2" :cy="size/2" :fill="fill" filter="url(#inset-shadow)"/>
  </svg>
</template>

<style scoped>
</style>

<script>
  export default {
    props: {
      fill: String,
      size: {
        type: Number,
      default: 12
      }
    },
    
    computed: {
      viewBox() { return `0 0 ${this.size} ${this.size}` }
    }
  }
</script>