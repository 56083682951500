
<article class="wave-assignment wave-planner" :class="{collapsed}">
  <header class="sub flex">
    <h1>Wave Planner</h1>
    <h2>{{race ? race.name : '-' }}</h2>
    <span class="flexible-space" />
    <button class="round center" @click="collapsed=!collapsed">↕️</button>
  </header>
  
  <section class="content-wrap">
    <form v-on:submit.prevent> 
      <fieldset>
        <div class="form-row">
          <label for="elite-waves">Create Waves</label>
          <input type="checkbox" v-model="opts.autoCreateWaves"/>
        </div>
      
        <div class="form-row">
          <label for="separation">Spacing</label>
          <input type="number" step="1" v-model="opts.maxPerWave" class="inline mini" />
          <span> / </span>
          <input type="number" step="1" v-model="opts.waveSpacingMinutes" class="inline mini" /><span class="unit">min</span>
        </div>
        
        <div class="form-row">
          <label for="sort-attr">Sort By</label>
          <select v-model="opts.sortField" title="Choose a Sort Method">
            <option disabled>Choose a Sort Method</option>
            <option v-for="name, field in sortFields" :value="field">{{ name }}</option>
          </select>

          <div v-if="opts.sortField=='ultraSignupRanking'">
            <loading-spinner v-if="loadingRankings" class="tiny-loading" @click="loadingRankings = false"/>
            <span class="status okay" v-else-if="raceEvent && raceEvent.usRankings">✓</span>
            <span class="status err" v-else>⛔️</span>
          </div>
        </div>
        <div class="form-row" v-if="opts.sortField=='ultraSignupRanking'">
          <label for="us-did">UltraSignup dID</label>
          <input type="number" v-model="opts.usdid" :disabled="!this.race"/>
          <button @click="fetchRankings" class="mini" :disabled="!opts.usdid">Fetch</button>
        </div>

        <div class="form-row">
          <label for="group-attribute">Group By Attribute</label>
          <div class="select-wrapper">
            <select v-model="opts.groupByAttribute" placeholder="None">
              <option :value="undefined">None</option>
              <optgroup label="──────────"></optgroup>
              <option v-for="k in availableKeys" :value="k">{{k}}</option>
            </select>
          </div>
        </div>
      </fieldset>
      <fieldset>
        <div class="form-row">
          <label for="gender-weight">Time Pref Weight</label>
          <input type="range" min="0" max="1" step="0.001" v-model="opts.wavePrefWeight" class="inline"/>
          <span class="note freeze" @click="wavePrefWeight=0">{{ parseFloat(opts.wavePrefWeight||0).toFixed(2) }}</span>
        </div>
      
        <div class="form-row">
          <label for="gender-weight">Male/Female Weight</label>
          <input type="range" min="0.25" max="0.75" step="0.001" v-model="opts.genderWeight" class="inline"/>
          <span class="note freeze" @click="opts.genderWeight=0.5">{{ ((0.5 + parseFloat(opts.genderWeight||0.5)) - 1.0).toFixed(2) }}</span>
        </div>
        <div class="form-row">
          <label for="weight-wave-first">Only Weight Leading</label>
          <input type="checkbox" v-model="opts.genderWeightOnlyFirst"/>
          <label for="weight-wave-count">Count</label>
          <input type="number" v-model="opts.genderWeightCount" :disabled="!opts.genderWeightOnlyFirst" class="mini" />
        </div>
        <div class="form-row">
          <label for="elite-waves">Elite Waves</label>
          <input type="checkbox" v-model="opts.eliteWaves"/>
        </div>
        <div class="form-row" v-if="opts.eliteWaves">
          <table>
            <thead><tr><th></th><th>Men</th><th>Women</th></tr></thead>
            <tbody>
              <tr>
                <th>Athletes</th>
                <td><input type="number" class="mini" v-model="opts.eliteSize.M" step="1"/></td>
                <td><input type="number" class="mini" v-model="opts.eliteSize.F" step="1"/></td>
              </tr>
              <tr>
                <th>Waves</th>
                <td><input type="number" class="mini" v-model="opts.mensWaveCount" step="1"/></td>
                <td><input type="number" class="mini" v-model="opts.womensWaveCount" step="1"/></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form-row">
          <label>Average Ranking</label>
          <span class="note">{{ averageRanking }}</span>
        </div>
      </fieldset>
    </form>
    
    <div class="flex-scroller-wrapper inset-box">
      <div class="flex-scroller par-table">
        <table>
          <thead>
            <tr>
              <th>Last</th>
              <th>First</th>
              <th>Gen</th>
              <th>Rank</th>
              <th>Adjusted</th>
              <th>Wave</th>
              <th v-if="opts.groupByAttribute">{{opts.groupByAttribute}}</th>
              <th v-else>{{opts.sortField}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="a in generatedAssignments" :class="rowClassForPar(a.par)" @contextmenu="showWeightMenu(a.par, $event)">
              <td class="last-name">{{ a.par.lastName }}</td>
              <td class="first-name">{{ a.par.firstName }}</td>
              <td :class="['gender', 'gen-'+a.par.gender]">{{a.par.gender}}</td>
              <td class="ranking">{{ (a.ranking * 100).toFixed(1) }}</td>
              <td class="adj ranking" :class="{manual: !!a.par.waveWeight }">{{ (a.adjRanking * 100).toFixed(1) }}</td>
              <td class="wave">{{ a.waveId }}</td>
              <td v-if="opts.groupByAttribute">{{ a.par[opts.groupByAttribute] }}</td>
              <td v-else>{{ opts.sortField ? a.par[opts.sortField] : '-'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</article>
