import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import PortalVue from 'portal-vue'
import ActionCableVue from 'actioncable-vue'
import AxiosVue from './lib/http'
import applyConverters from 'axios-case-converter'

import UndoStack from './lib/undostack'

import {localStorageProperty} from '@/lib/localStorage'


// Implements binding setter, stolen from Vue source
import { parseModel } from './lib/parse-model-hack'

import syserrno from 'syserrno'

// Kill sticky service workers
if ('serviceWorker' in navigator) {
  if (window.location.pathname.includes('.')) {
    let count = 0;
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      //returns installed service workers
      if (registrations.length) {
        for(let registration of registrations) {
          registration.unregister();
          count++
        }
      }
    });
    
    console.log(`Unregistered ${count} serviceWorkers`);
    if (count > 0) window.location.reload();
  }
}


let tickIntervalHandle;

let root = null;
root = new Vue({
  router,
  store,
  data() {
    return {
      fver: Vue.version,
      lsProp: 1,
      progress: new Vue({name: 'Progress'}),
      shortTimestampFmt: new Intl.DateTimeFormat('en-US', {
        weekday: 'short',
        // day: 'numeric', 
        hour: 'numeric', 
        minute: '2-digit', 
        second: '2-digit', 
        hour12: true,
      }),
      shortDateFmt: new Intl.DateTimeFormat('en-US', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      }),
      dayAndTime: new Intl.DateTimeFormat('en-US', {
        weekday: 'short',
        hour: 'numeric', 
        minute: '2-digit', 
        hour12: true,
      }),
    }
  },
  render: h => h(App),
  computed: {
    app() { return this.$children[0]; },
    token: localStorageProperty('token'),
    tokenExpiresAt: localStorageProperty('tokenExpiresAt', v => new Date(v) ),
  },
  
  mounted() {
    if (!tickIntervalHandle) {
      tickIntervalHandle = setInterval(() => this.$emit('tick', new Date()), 1000);
    }
  },
  
  methods: {
    authFailed(response) {
      if (response.status !== 401 && response.data.error != 'Signature has expired') return;
      if (response.headers['www-authenticate']) {
        // Check for realm.
        let m = response.headers['www-authenticate'].match(/realm\s*\=\s*(['"])([\w\d]+)[^\1]/);
        if (m[2] != 'momentum') return; // We can receive 401 status codes for external services.
      }
      if (!response.request.responseURL.includes(this.$apihost)) return;

      // End of guards
      
      console.warn("Detected expired signature; redirecting to login");
      this.token = null;

      let path;
      try { path = this.$router.currentRoute.path; }
      catch (e) { path = '/'; }

      if (!path.startsWith('/login'))
        this.$router.push({ name: 'login', query: {t: path} });
    }
  }
});

window.undostack = new UndoStack();

/* hostname */

if (process.env.NODE_ENV == 'development') { 
  if (window.location.search.includes('live'))
  {
    Vue.prototype.$host = 
    Vue.prototype.$apihost = `https://timing.aravaiparunning.com`;
  }
  else
  {
    Vue.prototype.$host = `${window.location.protocol}//${window.location.hostname}:8080`;
    Vue.prototype.$apihost = `${window.location.protocol}//${window.location.hostname}:3000`;
  }
}
else {
  Vue.prototype.$host = 
  Vue.prototype.$apihost =`${window.location.protocol}//${window.location.hostname}`;
}

/* Configuration */
Vue.config.productionTip = false

Vue.use(PortalVue);

Vue.use(AxiosVue, {
  baseURL: (function(){
    let host = Vue.prototype.$apihost;
    return host + '/api/v1/';
  })(),
  csrfHeader: true,
  token: () => root.token,
  onAuthorizationFailed: root.authFailed,
  headers: {
    'Accept': 'application/json',
    'x-tm-ver': '120',
  },
  tap(axios) {
    return applyConverters(axios, {
      ignoreHeaders: true,
    });
  }
});



// Determine websocket hostname
let needsTLS = false;
let portSuffix = '';
let connectionUrl;
if (location.protocol == 'https:') needsTLS = true;
if (!location.hostname.includes('.com') && !location.hostname.includes('timing')) portSuffix = ':3000';

if (window.location.search.includes('live')) {
  needsTLS = true;
  connectionUrl = `ws${needsTLS ? 's' : ''}://timing.aravaiparunning.com/ws`
}
else {
  connectionUrl = `ws${needsTLS ? 's' : ''}://${location.hostname}${portSuffix}/ws`
}

Vue.use(ActionCableVue, {
  debug: false,
  debugLevel: 'info',
  connectionUrl
});

/* Global Filters */
Vue.filter('adapter-status', function(value) {
  
  if (!value) return ".";
  
  if (typeof value === "number") {
    // Error Codes
    return syserrno.strerror(value)
  }
  
  switch (value.toLowerCase()) {
    case 'connected': return "Connected";
    case 'connecting': return "Connecting...";
    case 'disconnected': return "Disconnected";
    default: return value;
  }
});

Vue.filter('mToDistance', function(value, options={}, isMetric) {
  
  if (value === undefined || value === false)
    return '-';
  
  isMetric = (isMetric === undefined) ? !root.$children[0].wantsMiles : isMetric;
  
  if (value === undefined || value === null)
    return '-';
  
  const val   = (isMetric) ? value / 1000.0 : value / 1609.344;
  const d     = options.decimals === undefined ? 1 : options.decimals;
  const units = options.units ? (isMetric ? ' km' : ' mi') : '';
  
  return val.toFixed(d) + units
});

import moment from 'moment'
Vue.filter('formatDate', function(value, format, notADate) {
  if (!format)
    format = 'MM/DD/YYYY hh:mm:ss';

  if (!notADate)
    notADate = 'Not a date';
  
  if (!value || !value.getYear) 
    return notADate;
  
  return moment(value).format(format);
});

import UnitConvert from "@/lib/unit-convert.js"
Vue.mixin(UnitConvert);


/* Directives */
import {installAttributeChange} from '@/lib/attribute-change'
installAttributeChange(Vue);

import {installTabDirective} from '@/lib/tab-directive'
installTabDirective(Vue);

// Bind a datetime-local using a date through valueAsNumber
Vue.directive('bind-date', {
  bind(el, binding, vnode) {
    console.debug('bind-date: bind called', el, binding);
    const vm = vnode.context;
    const tzOffset = new Date().getTimezoneOffset() * 60000;
    
    // This is the function that will take user input and 
    // set the resulting object. 
    binding.listener = (e) => {
      const ts = new Date(e.target.valueAsNumber + tzOffset);

      console.debug("bind-date: el update", binding.expression);

      var res = parseModel(binding.expression);
      if (res.key === null) {
        vm[binding.expression] = ts;
      } 
      else {
      
        // Get rid of 'this.' if present
        if (res.exp.indexOf('this.') == 0) {
          res.exp = res.exp.substr(5);
        }
        console.debug(`bind-date: calling vm.$set(${res.exp}, ${res.key}, ${ts})`, res);
        vm.$set(vm[res.exp], res.key, ts);
      }
    };

    el.valueAsNumber = binding.value && binding.value && binding.value.getTime() || undefined;
    el.addEventListener('change', binding.listener);
  },
  
  update(el, binding) {
    console.debug("binding updated", el, binding);
    const timestamp = binding.value;

    if (!timestamp) 
      return undefined;

    el.valueAsNumber = timestamp.getTime() - timestamp.getTimezoneOffset() * 60000;
  },
  
  unbind(el, binding) {
    console.debug('bind-date: unbind', el);
    el.removeEventListener('change', binding.listener);
  }
});


/* Global Components */
import ModalWindow from "@/components/modal-window.vue"
window.ModalWindow = ModalWindow; // globally available singlton
Vue.component('modal-window', ModalWindow);


import BackButton from "@/components/back-button.vue"
Vue.component('back-button', BackButton);

import LoadingSpinner from "@/components/loading-spinner.vue"
Vue.component('loading-spinner', LoadingSpinner);

import ProgressBar from "@/components/progress-bar.vue"
Vue.component('progress-bar', ProgressBar);

import ToggleSwitch from "@/components/toggle-switch.vue"
Vue.component('toggle-switch', ToggleSwitch);

import AutocompleteRow from "@/components/autocomplete-row.vue"
Vue.component('autocomplete-row', AutocompleteRow);

import DatetimePicker from "@/components/datetime-picker.vue" // debug
Vue.component('datetime-picker', DatetimePicker);


const secPerDay = 24 * 60 *60;
const secPerHour = 60 * 60;
const secPerMin = 60;
Vue.prototype.$breakDuration = function(ms=0, opts={}) {
  let res = {};
  let t = ms / 1000.0;
  
  if (opts.breakDays) {
    res.days = Math.floor(t / secPerDay);
    if (res.days)
      t -= (res.days * secPerDay);
  }
  
  res.hours = Math.floor(t / secPerHour);
  if (res.hours)
    t -= (res.hours * secPerHour);

  res.minutes = Math.floor(t / secPerMin);
  if (res.minutes)
    t -= (res.minutes * secPerMin);

  res.ms = t * 1000.0;
  res.seconds = Math.floor(t);
  res.ms = Math.floor(res.ms - (res.seconds * 1000));

  return res;
}

let pad = (num, width) => num < 100 ? ('0000'+num).slice(width*-1) : num;
Vue.prototype.$durationString = function(ms=0, opts={}) {
  let b = this.$breakDuration(ms, opts);
  return `${b.neg ? '-':''}${b.hours}:${pad(b.minutes,2)}:${pad(b.seconds,2)}.${pad(b.ms,3)}`
}

/* Bootstrap */


root.config = {
  apiURL: 'localhost:3000'
};

root.$mount('#app')
