
<div class="datetime-picker">
  <input v-model.lazy="year" ref="year" class="d f" title="year" type="text" pattern="[0-9]*" input-mode="numeric" @focus="onFocus" @keydown="keydown">
  <span class="sep">-</span>
  <input v-model="month" ref="month" class="d t" title="month" type="text" pattern="[0-9]*" input-mode="numeric" @focus="onFocus" @keydown="keydown">
  <span class="sep">-</span>
  <input v-model="day" ref="day" class="d t" title="day" type="text" pattern="[0-9]*" input-mode="numeric" @focus="onFocus" @keydown="keydown">
  <span class="sep" />
  <input v-model="hour" ref="hour" class="tm t" title="hour" type="text" pattern="[0-9]*" input-mode="numeric" @focus="onFocus" @keydown="keydown">
  <span class="sep">:</span>
  <input v-model="minute" ref="minute" class="tm t" title="minute" type="text" pattern="[0-9]*" input-mode="numeric" @focus="onFocus" @keydown="keydown">
  <span class="sep">:</span>
  <input v-model="second" ref="second" class="tm t" title="second" type="text" pattern="[0-9]*" input-mode="numeric" @focus="onFocus" @keydown="keydown">
  <span v-if="!is24Hour" class="sep"/>
  <input v-if="!is24Hour" v-model="period" ref="period" class="tm p" title="period" type="text" pattern="[ap]m" @focus="onFocus" @keydown="keydownPeriod">
</div>
