<template>
  <article class="crossing-match-finish">
    <participant-select ref="participantSelect" v-model="selectedParticipant" :disable-selector="par => !par.ft"/>
    
    <hr>
    
    <div class="form-row">
      <label>Selected</label>
      <span>
        {{ selectedParticipant ? `${selectedParticipant.firstName} ${selectedParticipant.lastName}` : 'No Selection' }}
      </span>
      <span class="flexible-space" />
      <span v-if="derivedTimestamp" class="result">{{ dateFormatter.format(selectedParticipant.ft) }}</span>
    </div>

    <div class="form-row">
      <label>Adjust</label>
      <input v-model="adjustmentSeconds" type="range" min="-30" max="30" step="1">
      <span class="indicator">{{ adjustmentSeconds }} sec</span>
      <span class="flexible-space" />
      <span v-if="derivedTimestamp" class="result">{{ dateFormatter.format(derivedTimestamp) }}</span>
      <span v-else class="result no-records">-</span>
    </div>
  </article>
</template>

<script>
import ParticipantSelect from "@/views/participant-select.vue"

export default {
  
  name: 'CrossingMatchFinish',
  components: {ParticipantSelect},
  
  props: {
    participant: {
      type: Object,
      required: true
    }
  },
  
  data() {
    return {
      selectedParticipant: undefined,
      adjustmentSeconds: 0
    }
  },
  
  computed: {
    
    race() {
      return this.$store.state.races[this.participant.raceId]
    },
    
    dateFormatter() { 
      return new Intl.DateTimeFormat('en-US', {
        weekday: 'short', 
        month: 'short', 
        day: 'numeric', 
        hour: 'numeric', 
        minute: '2-digit', 
        second: '2-digit', 
        hour12: true,
      })
    },
    
    derivedTimestamp() {
      if (!this.selectedParticipant) return null;
      if (!this.selectedParticipant.ft) return null;
      
      // Find the selected participant's finish crossing
      return new Date(this.selectedParticipant.ft.getTime() + this.adjustmentSeconds * 1000);
    },
    
    createdCrossing() {
      if (!this.selectedParticipant) return {};
      
      // Determine finish split of the participant
      // we're editing. This is *not* the selectedParticipant's race.
      //
      const splits = this.$store.getters.entity('splits', this.race.splits);
      splits.sort((a,b)=> a.distance - b.distance);
      const fin = splits[splits.length-1];

      const eventSourceIds = fin.eventRoutes.map(r => this.$store.state.eventRoutes[r].eventSourceId);
      const eventSourceId = eventSourceIds[eventSourceIds.length-1];

      return {
        eventSourceId: eventSourceId,
        participantId: this.participant.id,
        readerId: 'manualmatch',
        tag: this.participant.bib,
        timestamp: this.derivedTimestamp,
        vLocked: true,
        splitId: fin.id,
        validCrossing: true,
      };
    }
  },
  
  methods: {
    nextPage() {
      this.$refs.participantSelect.nextPage();
    },

    prevPage() {
      this.$refs.participantSelect.prevPage();
    }
  }
}
</script>