const padding = 16;      // Padding, border, etc from css (?)
const cornerMargin = 12; // Arrow needs to be this far from a corner
const margin = 20;       // menu needs to be this far from the edge
const arrowWidth = 22;   // Width of the arrow overall, including "border"
const arrowHeight = 12;  // height of arrow overall

export function position(menu) {
  let targRect;

  if (this.targetButton.nodeName) {
    targRect = this.targetButton.getBoundingClientRect();
  }
  else if (this.targetButton.width) {
    targRect = this.targetButton;
  }
  else {
    console.error("Can't position to target", this.targetButton);
    return;
  }

  const arrowElm  = menu.querySelector('.arrow-border');
  const targetX   = targRect.left + targRect.width/2 + 8; // 8 off. I don't know why.
  const targetY   = targRect.top + targRect.height/2;
  const targetW   = targRect.width;
  const targetH   = targRect.height;

  const rect         = menu.getBoundingClientRect();
  const popoverWidth = rect.width;
  const popoverHeight = rect.height;
  const viewportRect  = document.documentElement.getBoundingClientRect();

  const computedStyle = window.getComputedStyle(menu.querySelector('.popover-menu'));
  const borderWidth = parseFloat(computedStyle.borderWidth);
  
  
  console.debug(`target: ${targetX}, ${targetY}`);
  console.debug('viewport: ', viewportRect);
  
  
  // Rotate the available directions so that the preferered
  // direction is first.
  let directions = ['up', 'rt', 'lf', 'dn'];
  let i = directions.indexOf(this.prefer);
  if (i > 0) {
    let after = directions.splice(i);
    directions = [...after, ...directions];
  }
  console.debug("will position directions", directions);
  
  const context = {
    arrowElm,
    targetX, targetY, targetW, targetH,
    popoverWidth, popoverHeight,
    viewportRect,
    borderWidth,
    directions,
  };
  
  const positionFuncs = {
    'up': this.positionUp,
    'rt': this.positionRight,
    'lf': this.positionLeft,
    'dn': this.positionDown,
  }

  let pos;
  for (i = 0; i < directions.length; i++) {
    const dir = directions[i];
    const func = positionFuncs[dir];
    console.debug(`will attempt position ${dir}`);
    pos = func(menu, context);

    if (pos) {
      this.direction = dir;
      break;
    }
  }

  let [x, y] = pos;
  console.debug(`x ${x} -= ${viewportRect.left}`);
  console.debug(`y ${y} -= ${viewportRect.top}`);
  x -= viewportRect.left;
  y -= viewportRect.top;
  menu.style.left = x + 'px';
  menu.style.top  = y + 'px';
}

function setArrowPos(elm, x, y) {
  elm.style = `--ax: ${x}px; --ay:${y}px`;
}

function getXForUpDown(menu, context) {
  let halfWidth = (context.popoverWidth/2);
  let x = context.targetX - halfWidth;
  console.log(`right edge: ${x + halfWidth + padding}; viewport: ${context.viewportRect.width}`);
  console.log(`left edge:  ${x - halfWidth - padding}; viewport: 0`);
  if ((x + context.popoverWidth + padding) > context.viewportRect.width) {
    // would overflow off the right
    console.log(`correcting right overflow: -${halfWidth - padding}`)
    x = context.viewportRect.width - context.popoverWidth - padding;
  }
  else
  if (x - halfWidth - padding < 0) {
    // would overflow off the left
    console.log(`correcting right overflow: x=${padding}`)
    x = padding;
  }

  return x;
}

export function positionDown(menu, context) {
  // Try to position going down, menu below the targetButton.
  // We will try to stick the menu at the midpoint across the width 
  // if the context doesn't override
  
  // see if we have room.
  if (context.popoverHeight > context.targetY + context.viewportRect.height)
    return null;

  // We have vertical space, so figure out horizontal space.
  let x = getXForUpDown(menu, context);
  
  // Stick the popover under the target
  let y = context.targetY + (context.targetH/2);
  
  setArrowPos(menu,
    context.targetX - x - arrowWidth/2,
    0 - 1);
  
  return [x,y];
}

export function positionUp(menu, context) {
  console.debug("positionUp");
  
  // Try to position going down, menu below the targetButton.
  // We will try to stick the menu at the midpoint across the width 
  // if the context doesn't override
  
  // see if we have room.
  if (context.targetY - context.popoverHeight < 0)
    return null;

  // We have vertical space, so figure out horizontal space.
  let x = getXForUpDown(menu, context);

  // Stick the popover over the target
  let y = context.targetY - (context.targetH/2) - context.popoverHeight;
  
  // @TODO: check arrow positioning.
  setArrowPos(menu,
    context.targetX - x - arrowWidth/2,
    context.targetY - y - arrowWidth - arrowWidth/2);
  
  return [x,y];
}

function getYForLeftRight(menu, context) {
  let y = context.targetY - (context.popoverHeight / 2);
  if (y < 0) {
    // would overflow off the top
    y = padding;
  }
  else
  if (y + context.popoverHeight + padding > context.viewportRect.height) {
    // would overflow off the bottom
    y = context.viewportRect.height - context.popoverHeight - padding;
  }

  return y;
}

export function positionRight(menu, context) {
  console.log("positionRight");
  // see if we have room.
  if (context.targetX + context.popoverWidth > context.viewportRect.width)
    return null;

  // position vertically
  let y = getYForLeftRight(menu, context);

  // Stick the popover over the target
  let x = context.targetX + (context.targetW/2);
  
  // @TODO: check arrow positioning.
  setArrowPos(menu,
    0-arrowHeight/2,
    context.targetY - y - arrowWidth/2,
  );
  
  return [x,y];
}


export function positionLeft(menu, context) {
  console.log("positionLeft");
  // see if we have room.
  if (context.targetX - context.popoverWidth < 0)
    return null;
  
  // position vertically
  let y = getYForLeftRight(menu, context);
  
  // Stick the popover over the target
  let x = context.targetX - (context.targetW/2) - context.popoverWidth + arrowWidth;
  
  // @TODO: check arrow positioning.
  setArrowPos(menu,
    context.popoverWidth - padding,
    context.targetY - y - arrowHeight/2,
  );
  
  return [x,y];
}

export default {
  position,
  positionUp, positionRight, positionDown, positionLeft,
}

