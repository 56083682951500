<template>
  <div class="login">
    <h1>Login</h1>

    <div ref="loginbox" class="group" :class="{shake: isShaking}">
      <input ref="un" v-model="un" type="text" placeholder="Username" autofocus @keyup="detectReturn">
      <input ref="pw" v-model="pw" type="password" placeholder="Password" @keyup="detectReturn">
      <loading-spinner v-if="loading" />
      <button class="round go" @click="doLogin">
        →
      </button>
    </div>
  </div>
</template>

<style lang="scss">
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-2px, 0, 0);
    }
  
    20%, 80% {
      transform: translate3d(4px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-8px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(8px, 0, 0);
    }
  }

  .login {
    .group {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
  
      input { 
        display: block; 
        width: 80%;
        border-radius: 8px;
        line-height: 2em;
        font-size: 1.25em;
        -webkit-appearance: none;
        background-color: var(--input-bgcolor);
        color: var(--text-color);
        border: 1px solid rgba(0,0,0,0.8);
        padding: 0.5em 1em;
        max-width: 400px;
      }
  
      input:first-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      input+input {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
      
      .spinner {
        position: absolute;
        bottom: -1em;
      }
  
      button.go {
        margin-top: 1em;
      }
  
      &.shake > input {
        animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
      }
    }
  }
</style>

<script>

export default {
  data() {
    return {
      loading: false,
      isShaking: false,
      
      un: '',
      pw: '',
    }
  },
  
  methods: {
    detectReturn(e) {
      if (e.key === 'Enter') {
        e.preventDefault();
        e.target.blur();
        this.doLogin();
      }
    },
    
    doLogin() {
      console.log("Doing Sign In");
      this.loading = true;
      
      this.$axios.request({
        method: 'post',
        url: 'authenticate',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        },
        data: {
          u: this.un,
          p: this.pw, 
        }
      })
      .catch(err => this.loginDidFail())
      .then(r=> {
        console.log("Login Response:", r);
        let data = r.data;
        this.$root.token = data.token;
        this.$root.tokenExpiresAt = data.expiresAt;
        this.loading = false;
        
        this.loginDidSucceed();
      })
      .catch(err => {
        console.error("Login error: ", err); 
        this.loginDidFail()
      })
    },

    shakeLogin() {
      console.log("will shake login");
      this.isShaking = true
      const elm = this.$refs.loginbox;
      const stopShaking = (e) => { 
        this.isShaking = false;  
        elm.removeEventListener("webkitAnimationEnd", stopShaking);
        elm.removeEventListener("animationend", stopShaking);
        elm.removeEventListener("oanimationend", stopShaking);
      };
      elm.addEventListener("webkitAnimationEnd", stopShaking);
      elm.addEventListener("animationend", stopShaking);
      elm.addEventListener("oanimationend", stopShaking);
    },
    
    loginDidFail() {
      this.loading = false;

      this.$refs.pw.focus(); 
      this.$refs.pw.select()

      this.shakeLogin();
    },
    
    loginDidSucceed() {
      const p = this.$route.query.t || '/';
      const r = this.$router;
      console.log("redirecting: ", p);
      r.replace({path: p}).then(() => {
        console.debug("loginDidSucceed");
        this.$root.$emit('loginDidSucceed', true);
      });
    }
  }
}
</script>