<template>
  <li
    class="race-event-result"
    :class="`gen-${subject.gender}`"
    @click="handleClick"
    :title="`${subject.name}\n${subject.startTime}`"
  >
    <figure class="im"><img :src="raceEventImageUrl" @error="raceEventImageError"/></figure>
    <span class="name">
      <span class="first-name">{{ subject.name }}</span>
    </span>
  </li>
</template>

<style lang="scss">
.race-event-result {
//  display: table-row;
  display: flex;
  flex-direction: row;
  line-height: 44px;
  cursor: pointer;
  user-select: none;
  
  &:hover {
    background: var(--row-hover-bgcolor);
  }

  &>span {
    vertical-align: middle;
    vertical-align: -webkit-baseline-middle;
    white-space: nowrap; text-overflow: ellipsis; overflow: hidden
  }
  


  span:not(last-child) {
    margin-right: 8px;
  }
  
  .im {
    width: 50px;
    height: 50px;
    margin: 0 0.5rem 0 0 ;
    text-align: center;
    img { margin: 0.5rem; width: stretch; aspect-ratio: 1.0;}
  }
  
  .wave { width: 5ex; color: var(--text-dim1-color); }
  
  .name { min-width: 25ex;}
  .first-name { min-width: 10ex; overflow: hidden; text-overflow: ellipsis; white-space:nowrap; }
  .last-name  { min-width: 18ex; overflow: hidden; text-overflow: ellipsis; white-space:nowrap; }

  &.gen-F .gender { width: 1.5ex; color: #ee0076; text-align: right}
  &.gen-M .gender { width: 1.5ex; color: #1d606c; text-align: right}
  
  .status {
    text-align:right;
    display: none;
  }
  
}
</style>

<script>
  import aravaipaLogo from '@/assets/aravaipa-logo.png'

  export default {
    props: {
      subject: {
        type: Object, // RaceEvent hash from store
        required: true
      }
    },
  
    computed: {
      raceEventImageUrl() {
        if (this.subject && this.subject.avatarUrl) {
          return this.subject.avatarUrl;
        }
      
        return undefined;
      },
    },
  
    methods: {
      raceEventImageError(e) {
        e.target.src = aravaipaLogo;
      },
      
      setActiveRaceEvent(raceEvent) {
        if (raceEvent == this.activeRaceEvent)
          return this.$parent.hide && this.$parent.hide();
        
        // this.$store.dispatch('setActiveRaceEvent', raceEvent);
        const currentRoute = this.$router.currentRoute
        
        if (currentRoute.params.raceEventId !== undefined) {
          // We are showing raceEvent-specific content; route to the
          // new version of whatever we're showing
          let route = {
            name: currentRoute.name,
            query: currentRoute.query,
            params: Object.assign({}, currentRoute.params),
          };
        
          let r = Object.assign({}, route);
          route.params.raceEventId = raceEvent.id;
          this.$router.push(route);
        
          // Why do I have to do this myself?
          this.$nextTick(() => {
            this.$nextTick(() => {
              // window.location.hash = this.$router.currentRoute.path;
              // console.log("race-events: new route: ", this.$router.currentRoute);
              // this.$router.go();
              this.$parent.hide && this.$parent.hide();
            });
          });
        }
        else {
          // Any navigation from our current route will not change the raceEventId param,
          // which results in duplicate navigation (asking for /thing => /thing, which just throws)
          this.$store.dispatch('setActiveRaceEvent', raceEvent);
          this.$parent.hide && this.$parent.hide();
          return;
        }
      },
      
      handleClick() {
        this.$emit('resultClick', this.subject);
        this.setActiveRaceEvent(this.subject);
      }
    }
  }
</script>