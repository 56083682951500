<template>
  <a href="#" class="btn-back" @click="propogateClick">
    <slot />
  </a>
</template>

<style lang="scss" scoped>
  
  $bgcolor:#23868f;
  
  @function tint($color, $percentage) {
    @return mix(white, $color, $percentage * 1%);
  }
  
  @function shade($color, $percentage) {
    @return mix(black, $color, $percentage * 1%);
  }

  .btn-back {
      display: inline-block;
      position: relative;
      margin: 0 1em 0 -1em; 
      border: none;
      padding: 0 10px 0 30px;
      line-height: 44px;
      text-shadow: 0 -1px #4d4d4d;
      color: #fff;
      font-weight: bold;
      text-decoration: none;
      font-family: sans-serif;
      font-size: 12px;
  }
  .btn-back:before {
      content: '';
    
      position: absolute;
      -moz-box-sizing: border-box;
           box-sizing: border-box;
      left: 20px;
      right: 0;
      height: 34px;
      margin-top: 5px;
      z-index: -1;
    
      border: 1px solid #808080;
      border-bottom-color: #808080;
      border-top-color: #4d4d4d;
      border-left-width: 0;
      border-radius: 5px;
    
      background: $bgcolor;
      transition: all .23s ease;
  }

  .btn-back:after {
      content: '';
    
      position: absolute;
      -moz-box-sizing: border-box;
           box-sizing: border-box;
      left: 11px;
      height: 27px;
      width: 26px;
      top: 8px;
      z-index: -2;
    
      border: 1px solid #FFF;
      border-top: 0 transparent;
      border-right: 0 transparent;
      border-bottom-color: #808080;
      border-left-color: #4d4d4d;
      border-radius: 7px 5px 5px 7px;
    
      background: $bgcolor;
      transition: all .23s ease;

      transform: rotate(45deg);
  }


  .btn-back:hover:before {
    background: tint($bgcolor, 20);
  }
  .btn-back:hover:after {
    background: tint($bgcolor, 20);
  }


  .btn-back:active:before,
  .btn-back:active:after {
    transition: all 0.1s ease;
    background: shade($bgcolor, 40);
    box-shadow: inset 0px 1px 0px #4d4d4d;
  }
  .btn-back:active:after {
      box-shadow: inset 1px 1px 0px #4d4d4d;
  }
</style>

<script>
  export default {
    methods: {
      propogateClick(e) {
        this.$emit('click', e);
      }
    }
  }
</script>