
<li :class="crossing.validCrossing ? 'valid' : 'invalid'" :title="tooltip">
  <span class="bib" :title="tooltip" :style="bibStyle">
    <span>{{ bibContent }}</span>
  </span>
  <span class="name" @click="clickParticipant">{{ name.length && name || "Unknown Participant" }}</span>
  <span class="timestamp">
    <span>{{ timestampFormatter.format(crossing.timestamp) }}</span>
    <span class="ms">{{ }}</span>
  </span>
</li>
