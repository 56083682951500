<template>
  <div class="race-details-map" ref="mapElm">
    <fieldset class="splits varlist-wrapper active-elms">
      <header>
        <h2>Splits</h2>
      </header>
      <ul class="varlist">
        <li v-for="split in splits" @click="selectSplit(split, $event)" :class="{selected:split==selectedSplit}">
          <span class="split-name">{{split && split.name || '---'}}</span>
        </li>
      </ul>
      <footer class="control">
        <button class="add bar" @click="addSplit" title="Add Split">Add</button>
        <button class="del bar" @click="removeSelectedSplit" :disabled="!selectedSplit" title="Remove Split">Delete</button>
      </footer>
    </fieldset>
    
    <div class="inset-box no-data" v-if="!coords || !coords.length">No GPX Track Found</div>
    
    <!-- <ul class="waypoint-list">
      <li v-for="p in suggestedWaypoints" @click="clickedSuggestedWaypoint(p)">
        {{p.properties.name}} ({{p.geometry.coordinates[3] | mToDistance({units: true}) }})
      </li>
    </ul> -->
    
    <button v-if="showSplitOptions" class="clear" @click="closeSplitOptions"></button>
    <split-options v-if="showSplitOptions" :target-split="selectedSplit" ref="splitOptions"></split-options>
  </div>
</template>

<style lang="scss">
  @import 'https://api.mapbox.com/mapbox-gl-js/v2.0.0/mapbox-gl.css';
  
  .race-details-map {
    background: rgba(0,0,0,0.4);
    
    overflow: hidden; // keep border radius
    --inset: 8px;
    
    // Need to show this somewhere else.
    .mapbox-ctrl-logo {
      transform: translateY(34px) !important;
    }
    
    .splits {
      backdrop-filter: blur(20px);
      position: absolute;
      z-index: 10;
      top: 0px;
      left: 0px;
      bottom: 0px;
      width: 200px;
      list-style: none;
      background: var(--popover-abgcolor);
      margin: 0;
      padding: 1em;
      font-size: 1.25em;
      border-radius: 0;
      border-top-left-radius: var(-br);
      border-bottom-left-radius: var(--br);
      box-shadow: 0 1px 7px rgba(0,0,0,0.6);
      overflow:hidden;

      li {
        display: flex;
        justify-items: space-between;
        line-height: 1.65em;
        cursor: pointer;
      }
    }
    
    .no-data {
      position: absolute;
      z-index: 10;
      top: 40%;
      left: 50%;
      width: 185px;
      height: 100px;
      transform: translate(-50%, -50%);
      background: var(--popover-abgcolor);
      backdrop-filter: blur(6px);
      text-align: center;
      font-weight: bold;
      line-height: 100px;
      user-select: none;
    }
    
    button.clear {
      position: absolute;
      z-index: 11;
      top: calc(var(--inset) * 2);
      right: 280px;
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
    }
    
    .split-options {
      backdrop-filter: blur(20px);
      background: var(--popover-abgcolor);
      position: absolute;
      z-index: 10;
      top: var(--inset);
      right: var(--inset);
      bottom: var(--inset);
      width: 290px;
      padding: 1em 0.5em 0;
      border-radius: var(--br);
      box-shadow: 0 1px 7px rgba(0,0,0,0.6);
      
      .form-row {
        label { min-width: 65px}
      }
      
      .event-routes {
        margin-bottom: var(--inset);
      }
    }
  }
</style>

<script>
  import schema from '@/entity.js'
  import SplitOptions from "@/views/split-options.vue"
  import {mapBoxAccessToken} from '@/credentials'
  import SunCalc from 'suncalc'
  import {coordsForDistance} from '@/lib/gpx-tools'

//import GeoJSON from '@mapbox/togeojson'

let mapboxgl;

export default {
  name: 'RaceDetailsMap', components: { SplitOptions },
  props: {
    race: {
      type: Object,
      required: true
    },
    
    defaultCenter: {
      type: Array,
      default: () => [-112.0298509, 33.425679],
    }
  },
  
  data() {
    return {
      updateSunIntervalHandle: undefined,
      
      // map: undefined,
      mapStyleLoaded: false,
      
      showSplitOptions: false,
      selectedSplit: undefined,
      updatingSelectedSplit: false,
      
      center: this.defaultCenter,
      minLng: 0,
      minLat: 0,
      maxLng: 0,
      maxLat: 0,
    }
  },
  
  computed: {
    linestring() {
      if (!this.race?.linestring) return console.log("race.linestring is undefined");
      
      let feature;
      let geometry;
      
      if (this.race.linestring.type === 'FeatureCollection') {
        // We have a whole newly-uploaded GeoJSON file here.
        // Get its LineString out.
        feature = this.race.linestring.features.find(f => f.geometry?.type === 'LineString')
      }
      else 
      if (this.race.linestring.type === 'Feature') {
        // We (hopefully) have a LineString feature here.
        feature = this.race.linestring;
      }

      return feature;
    },
    
    coords() {
      return this.linestring?.geometry?.coordinates || []
    },
    
    splits() {
      let currentSplits = this.$store.getters.entity('splits', this.race.splits);
      currentSplits.sort((a,b) => (a.distance || 0) - (b.distance || 0));
      return currentSplits;
    },
    
    waypoints() {
      if (!this.race?.linestring) return [];
      if (this.race.linestring.type !== 'FeatureCollection') return [];

      // We have a whole newly-uploaded GeoJSON file here.
      // Get its LineString out.
      return this.race.linestring.features.filter(f => {
        if (f.geometry?.type !== 'Point') return false;

        // Cache the distance into the track if not given.
        // If we don't have a linestring, we'll have to wait.
        if (!this.coords) return true;
        
        if ( f.geometry.coordinates[3] === undefined ) {
          console.warn("Waypoint missing m dimension", f.properties?.name);
        }
        
        return true;
      });
    },
    
    waypointsGeoJSON(){
      console.log("waypointsGeoJSON update");
      return {
        type: 'FeatureCollection',
        features: this.splits.map(s => s.point).filter(s=>s)
      }
    },
    
    
  },
    
  mounted() {
    // This will finish first.
    this.recalculateExtents();
  },
  
  watch: {
    coords() {
      console.log("⚠️ coords changed!")
      this.recalculateExtents();

      if (this.mapStyleLoaded) {
        this.updateTrackSource();
      }
      
      this.zoomToExtents();
    },
    
    waypoints() {
      console.log("⚠️ waypoints changed!")
      this.updateWaypointMarkers();
    },
    
    'selectedSplit.distance': function() {
      if (!this.selectedSplit) return;
      if (this.selectedSplit?.point) {
        let coords = coordsForDistance(this.linestring, this.selectedSplit.distance);
        if (!this.selectedSplit.point) this.selectedSplit.point = {type: 'Feature'};
        if (!this.selectedSplit.point.geometry) this.selectedSplit.point.geometry = {type: 'Point'};
        this.selectedSplit.point.geometry.coordinates = coords;
      }
      this.updateWaypointMarkers();
    },
    
    'selectedSplit.name': function() {
      if (!this.selectedSplit) return;
      if (this.selectedSplit?.point) {
        if (!this.selectedSplit.point.properties) {
          this.selectedSplit.point.properties = {};
        }

        this.selectedSplit.point.properties.name = this.selectedSplit.name;
      }
      this.updateWaypointMarkers();
    },

  },
  
  methods: {
    
    // Called by the displaying tab
    didDisplay() {
      this.redraw();
      
      if (!this.updateSunIntervalHandle) {
        this.updateSunIntervalHandle = setInterval(() => {
          if (this.mapStyleLoaded) {
            this.map.setPaintProperty('sky', 'sky-atmosphere-sun', this.getSunPosition());
          }
        }, 60000);
      }
    },
    
    redraw() {
      console.log("race-details-map: redraw", this.map);
      
      if (!this.map) {
        this.loadModule().then(() => {
          this.createMap()
      
          new Promise((resolve, reject) => {
            this.map.once('styledata', () => resolve());
          }).then(() => {
            this.mapStyleLoaded = true;
            this.addTrackSource()
            this.addTrackLayer();
          })
        });
      }
      else {
        this.$nextTick(()=> {
          this.map.resize();
        })
      }
    },
    
    recalculateExtents() {
      console.log("recalculateExtents ", this.coords?.length);
      if (!this.coords || this.coords.length < 1)
        return;
        
      let minLng = Infinity, minLat = Infinity, maxLng=-Infinity, maxLat=-Infinity;
      for (let i = this.coords.length - 1; i >= 0; i--) {
        let [lng, lat, ele] = this.coords[i];
        if (lng < minLng) minLng = lng;
        if (lat < minLat) minLat = lat;
        if (lng > maxLng) maxLng = lng;
        if (lat > maxLat) maxLat = lat;
      }
      
      this.center = [
        (minLng + maxLng) * 0.5,
        (minLat + maxLat) * 0.5
      ];
      
      this.minLng = minLng;
      this.minLat = minLat;
      this.maxLng = maxLng;
      this.maxLat = maxLat;
      
      console.debug(`extents: center: ${this.center}, [${this.minLng},${this.minLat} - ${this.maxLng},${this.maxLat}]`)
    },
    
    zoomToExtents() {
      this.map?.fitBounds([
        [this.minLng, this.minLat],
        [this.maxLng, this.maxLat]
      ])
    },
    
    loadModule() {
      return new Promise((resolve, reject) => {
        this.$nextTick(async () => {
          if (!mapboxgl) {
            console.log("Loading mapbox module");
            const mapboxgl_mod = await import(/* webpackChunkName: "map" */ 'mapbox-gl');
            mapboxgl = mapboxgl_mod.default;
            console.log("Loading mapbox module done");
          }
        
        	mapboxgl.accessToken = 'pk.eyJ1IjoiYWRjIiwiYSI6ImNpc25rdjF1aTA1aG0ycG14OGU3MDl0d2gifQ.jVnAiMoB65joXvfjoBTEZw';

          resolve();
        })
      })
    },
    
    addTrackSource() {
      if (!this.mapStyleLoaded || !this.map) {
        console.warn(`addTrackSource failed: styleLoaded: ${this.mapStyleLoaded}; map exists?`, this.map);
        return false;
      }
      
      // Remove layers that reference this source
      ['route', 'route-offset'].forEach(id=> this.map.getLayer(id) && this.map.removeLayer(id));

      if (this.map.getSource('route'))
        this.map.removeSource('route');
      
      console.debug("addTrackSource");
      if (!this.map) return console.warn("Will not add track; no map");
      if (!this.race || !this.race.linestring) return console.warn("No linestring given");
      
      // this.map.addSource('route', {
      //   'type': 'geojson',
      //   'data': this.race.linestring
      // });
      this.map.addSource('route', {
       'type': 'geojson',
       'data': this.race.linestring,
      });
    },
    
    addTrackLayer() {

      // Remove layers that reference this source
      ['route', 'route-offset'].forEach(id=> this.map.getLayer(id) && this.map.removeLayer(id));

      // This layer references the race's track, which we add later.
      console.log("Adding layer to map", this.map);
      this.map.addLayer({
        'id': 'route-offset',
        'type': 'line',
        'source': 'route',
        'layout': {
          'line-join': 'round',
          'line-cap': 'round'
        },
        'paint': {
          'line-color': '#000000',
          'line-width': 6
        }
      });
      
      this.map.addLayer({
        'id': 'route',
        'type': 'line',
        'source': 'route',
        'layout': {
          'line-join': 'round',
          'line-cap': 'round'
        },
        'paint': {
          'line-color': this.race.color,
          'line-width': 4.0
        }
      });
    },
    
    updateTrackSource() {
      const source = this.map.getSource('route');
      if (!source) {
        this.addTrackSource();
      }
      
      source.setData(this.race.linestring);
    },
    
    createMap() {    
      if (!mapboxgl)
        return console.error("mapbox not loaded");
      
      this.map = new mapboxgl.Map({
        container: this.$refs.mapElm,
        zoom: 10.1,
        center: this.center,
        pitch: 55,
        bearing: 0,
        // style: 'mapbox://styles/mapbox-map-design/ckhqrf2tz0dt119ny6azh975y', // default satelite
        //style: 'mapbox://styles/mapbox/outdoors-v11', // topo map
        style: 'mapbox://styles/adc/ckj1rl6hc7lwu19lfkulvseiu' // studio satellite
      });

      this.map.on('load', this.setupMap); 
      this.map.resize();
    },
    
    setupMap() {
      console.log("setupMap");
      
      this.map.addSource('mapbox-dem', {
        'type': 'raster-dem',
        'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
        'tileSize': 512,
        'maxzoom': 14
      });

      // add the DEM source as a terrain layer with exaggerated height
      this.map.setTerrain({
        'source': 'mapbox-dem',
        'exaggeration': 1.5
      });

      // add a sky layer that will show when the map is highly pitched
      this.map.addLayer({
        'id': 'sky',
        'type': 'sky',
        'paint': {
          'sky-type': 'atmosphere',
          'sky-atmosphere-sun': this.getSunPosition(new Date()),
          'sky-atmosphere-sun-intensity': 10
        }
      });
      
      // Add a symbol layer
      this.updateWaypointMarkers();
      this.map.addLayer({
        'id': 'splitWaypoints',
        'type': 'circle',
        'source': 'splitWaypoints',
        'text-field': 'tf: {name}',
        'paint': {
          'circle-radius': {
            'base': 1.75,
            'stops': [
              [12, 10],
              [22, 180]
            ]
          },
          'circle-color': '#B42222',
          'circle-opacity': 0.7,
          'circle-pitch-alignment': 'map',
        },
      });
      
      this.map.addLayer({
        'id': 'label-style',
        'type': 'symbol',
        'source': 'splitWaypoints',
        'layout': {
          'text-size': 13,
          'text-field': ['get', 'name'],
          'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
          'text-radial-offset': 0.5,
          'text-justify': 'auto',
          'icon-image': ['concat', ['get', 'icon'], '-15']
        },
        'paint': {
          'text-color': '#ffffff',
          'text-halo-color': 'rgba(0,0,0,0.8)',
          'text-halo-width': 2
        }

      });

      // Change the cursor to a pointer when the mouse is over the places layer.
      this.map.on('mouseenter', 'splitWaypoints', () => {
        this.map.getCanvas().style.cursor = 'pointer';
      });

      // Change it back to a pointer when it leaves.
      this.map.on('mouseleave', 'splitWaypoints', () => {
        this.map.getCanvas().style.cursor = '';
      });

      this.map.on('click', 'splitWaypoints', this.clickedWaypoint);
      
      /*
      const rotateCamera = (timestamp) => {
        // clamp the rotation between 0 -360 degrees
        // Divide timestamp by 100 to slow rotation to ~10 degrees / sec
        this.map.rotateTo((timestamp / 100) % 360, { duration: 0 });
        // Request the next frame of the animation.
        requestAnimationFrame(rotateCamera);
      }
      rotateCamera(0);
      */
    },
    
    updateWaypointMarkers() {
      const source = this.map?.getSource('splitWaypoints');
      if (source) {
        source.setData(this.waypointsGeoJSON);
        return;
      }
      
      if (this.map && this.waypointsGeoJSON) {
        this.map.addSource('splitWaypoints', {
          'type': 'geojson',
          'data': this.waypointsGeoJSON,
        });
      }
    },
    
    splitForWaypoint(wp) {
      return this.splits.find(s => (
        (Math.abs(s.point.geometry.coordinates[0] - wp.geometry.coordinates[0]) < 0.001) &&
        (Math.abs(s.point.geometry.coordinates[1] - wp.geometry.coordinates[1]) < 0.001)
      ));
    },
    
    clickedWaypoint(e) {
      console.log("Clicked waypoint", e);
      let wp;
      if (e.type === 'click') {
        // this is a click event
        wp = e.features[0];
      }
      else
      if (e.type === 'Feature') {
        // this is a direct click on a feature
        wp = e;
      }
      
      let coordinates = wp.geometry.coordinates.slice(0,2);

      this.selectSplit(this.splitForWaypoint(wp));
    },
    
    closeSplitOptions(e) {
      e.preventDefault();
      this.selectedSplit = null;
      this.showSplitOptions = false;
    },
    
    selectSplit(s, e) {
      this.showSplitOptions = true;
      this.$nextTick(() => {
        this.selectedSplit = s;
        
        if (!s) {
          this.showSplitOptions = false;
          this.zoomToExtents();
        }
        else {
          let coords;
          if (coords = this.selectedSplit?.point?.geometry?.coordinates) {
            this.map?.flyTo({
              center: coords.slice(0,2),
              zoom: 15,
              essential: false, //respect reduced motion
            });
          }
        }
      })
    },
    
    addSplit() {
      // Interpolate between selectedSplit or penultimate split + next
      let afterSplit = 
        this.selectedSplit || 
        (this.splits.length > 2 && this.splits[this.splits.length - 2]) ||
        this.splits[0];
      
      let afterIndex = this.splits.indexOf(afterSplit);
      let beforeSplit = this.splits[afterIndex+1];

      console.debug(`Adding split after ${afterIndex}`, afterSplit);
      console.debug(`Adding split before `, beforeSplit);

      let afterDist = afterSplit && parseInt(afterSplit.distance) || 0;
      let beforeDist = beforeSplit && parseInt(beforeSplit.distance) || this.race.distance;
      let dist = (afterDist + beforeDist) * 0.5;
      let coords = this.linestring ? coordsForDistance(this.linestring, dist) : undefined
      
      let name = 'New Split';
      const split = {
        id: this.$store.getters.randomID(),
        raceId: this.race.id,
        name: name,
        distance: Math.round(dist),
        point: {
          type: 'Feature',
          properties: {name},
          geometry: {type: 'Point', coordinates: coords}
        },
      };
  
      let payload = {splits: {}};
      payload.splits[split.id] = split;
      this.$store.commit('commitEntities', payload);
      
      this.selectSplit(split);
      this.$refs.splitOptions?.focusSplitName(true);

      return split;
    },
    
    removeSplit(split) {
      // Only bother confirming if the split actually has non-default attributes
      let needsConfirmation = (!!split.name && split.distance > 0);
      if (needsConfirmation)
        needsConfirmation = !confirm(`Really delete split '${split.name || "Unnamed Split"}'? This cannot be undone.`);
      
      if (needsConfirmation) 
      {
        console.warn("Could not get confirmation; not deleting");
      }
      else
      {
        const finish = () => {
          this.selectSplit(null);
          this.updateWaypointMarkers();
        }
        
        // this.race.id might be ephemeral too. In practice, it should never happen
        // that we have a new race with a persisted split that we need to delete.
        if (split.id.startsWith && split.id.startsWith('NEW')) {
            this.$store.commit('destroyEntity', {entitySchema: schema.split, id: split.id});
            finish();
        }
        else {
          this.$store.dispatch('destroyEntity', {
            entity: split,
            entitySchema: schema.split,
            path: `race_events/${this.race.raceEventId}/races/${this.race.id}/splits/${split.id}`
          }).then(finish);
        }
      }
    },
    
    removeSelectedSplit(){
      if (!this.selectSplit)
        return;
      
      this.removeSplit(this.selectedSplit);
    },
    
    getSunPosition(date) {
      var center = this.map.getCenter();
      var sunPos = SunCalc.getPosition(
        date || Date.now(),
        center.lat,
        center.lng
      );
      var sunAzimuth = 180 + (sunPos.azimuth * 180) / Math.PI;
      var sunAltitude = 90 - (sunPos.altitude * 180) / Math.PI;
      return [sunAzimuth, sunAltitude];
    },

  }
  
}
</script>