<template>
  <div>
    <div class="form-row">
      <label>Weight</label>
      <input v-model="lWaveWeight" type="range" min="-1" max="1" step="0.01">
      <span class="note">{{ waveWeight }}</span>
    </div>
    <div class="form-row">
      <label>Start</label>
      <select v-model="lWavePref">
        <option :value="undefined">
          No Preference
        </option>
        <optgroup label="Ordering">
          <option value="early+">
            Strong Early Start
          </option>
          <option value="early">
            Early Start
          </option>
          <option value="late">
            Late Start
          </option>
          <option value="late+">
            Strong Late Start
          </option>
        </optgroup>
        <optgroup v-if="waveStartTimes" label="Individual Wave">
          <option v-for="t in waveStartTimes" :value="t.time">
            Wave {{ t.id }} - {{ fmttime(t.time) }}
          </option>
        </optgroup>
      </select>
    </div>
    
    <ul class="wave-group">
      <li v-if="!groupPars.length">
        No group members
      </li>
      <li v-for="par in groupPars" v-else>
        {{ par.firstName }} {{ par.lastName }} - {{ par.city }}, {{ par.state }}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: ['participant', 'waveStartTimes', 'participants'],
  data() {
    return {
      lWaveWeight: 0,
      lWavePref: undefined,
    }
  },

  computed: {
    waveWeight() {
      return this.participant.waveWeight ? this.participant.waveWeight.toFixed() : '-'
    },
    
    groupPars() {
      if (!this.participant.waveBuddy) return [];
      return this.participants.filter(p => p != this.participant && p.waveBuddy == this.participant.waveBuddy);
    }
  },

  watch: { 
    lWaveWeight(v) { this.lWaveWeight = parseFloat(v); this.$emit('updateWaveWeight', this.lWaveWeight); },
    lWavePref(v)   { this.lWavePref = v; this.$emit('updateWavePref', this.lWavePref); }
  },
  
  mounted() {
    this.lWaveWeight = this.participant.waveWeight || 0;
    this.lWavePref   = this.participant.wavePref;
  },
  
  methods: {
    fmttime(t) {
      console.debug("fmttime", t);
      try {
        if (typeof t === 'string') t = new Date(t);
      } catch(e) {}
      
      if (t.getSeconds && isFinite(t))
        return this.$root.shortTimestampFmt.format(t);

      return '-'
    }
  }
}

</script>