
<div class="autocomplete" :class="rowClass" ref="parentRow">
  <label v-if="label" :for="label + '-input'">{{ label }}</label>
  <div class="autocomplete-wrap">
    <input ref="input" v-model="internalValue" type="text" @keydown="onKey" @focus="inputFocus(true)" @blur="inputFocus(false, $event)" @change.stop="onChange">
    <button class="fa accept mini" v-if="showAutocompleteList" @mousedown="didClickAccept"></button>
    <ul v-if="showAutocompleteList" class="naked predictions menu" :class="{'has-label': !!label}">
      <li v-for="(n, i) in autocompleteList" :key="i" :class="{selected: selectedPrediction===i}" @mousedown="chooseAutocomplete(n)">
        {{ n }}
      </li>
    </ul>
  </div>
  <slot />
</div>
