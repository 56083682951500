
<div class="race-event-details">
  <h1 v-if="raceEvent">
    Event Details
  </h1>
  <h1 v-else>
    New Event
  </h1>
  <form onsubmit="return false" class="race-event-options">
    <input ref="fileInput" style="display:none" type="file" @change="handleNewAvatarFile">
    
    <div class="avatar inset-box">
      <div class="race-event-avatar avatar-choosable">
        <img :src="raceEventImage" :alt="attributes.name || 'Race Event Image'">
        <button @click="askForAvatarFile">Choose</button>
      </div>
      <fieldset class="race-event-attrs">
        <div class="form-row">
          <label for="name">Name</label>
          <input v-model="attributes.name" @input="nameDidChange" type="text" name="name" autofocus>
        </div>

        <div class="form-row">
          <label for="name">Slug</label>
          <input v-model="attributes.slug" type="text" name="slug">
        </div>
      </fieldset>
    </div>
    
    <ul class="tabs">
      <li :class="{selected:selectedTab=='races'}" @click="selectTab('races')">
        Races
      </li>
      <li :class="{selected:selectedTab=='shirts'}" @click="selectTab('shirts')">
        Shirts
      </li>
      <li :class="{selected:selectedTab=='options'}" @click="selectTab('options')">
        Options
      </li>
    </ul>
    
    <fieldset v-show="selectedTab=='races'" class="inset-box races varlist-wrapper">
      <section class="scrollable">
        <table>
          <thead>
            <tr>
              <!-- t.integer "distance"
                  t.boolean "is_loop", default: false, null: false
                  t.boolean "is_timed", default: false, null: false
                  t.boolean "gun_time", default: true, null: false
                  t.datetime "start_time"
                  t.datetime "cutoff"
                  t.datetime "created_at", null: false
                  t.datetime "updated_at", null: false
                end -->
              <th>Name</th>
              <th>Distance</th>
              <th>Looped?</th>
              <th>Format</th> <!--is_timed? ? fixed time : distance -->
              <th>Start Time</th>
              <th>Cutoff</th>
              <th />
            </tr>
          </thead>
          <tbody class="alternate-rows">
            <tr v-if="!races.length">
              <td class="no-content" colspan="100">
                No Races
              </td>
            </tr>
            <tr v-for="race in races" v-else>
              <td class="race-name">
                <span class="dot" :style="raceDotStyle(race)">&nbsp;</span>
                {{ race.name }}
              </td>
              <td>{{ race.distance | mToDistance({units: true }) }}</td>
              <td :class="{looped: race.isLoop, pp: !race.isLoop}">
                {{ race.isLoop ? 'Looped' : 'Point to Point' }}
              </td>
              <td>{{ race.isTimed ? 'Fixed Time' : 'Fixed Distance' }}</td>
              <td>
                <span class="date">{{ race.startTime | formatDate('MMM Do \'YY', 'Unset') }}</span>
                <span class="time">{{ race.startTime | formatDate("h:mm:ss a", ' ') }}</span>
              </td>
              <td>
                <span class="date">{{ race.cutoff | formatDate('MMM Do \'YY', 'No Cutoff') }}</span>
                <span class="time">{{ race.cutoff | formatDate("h:mm:ss a", ' ') }}</span>
              </td>
              <td class="control"> 
                <button @click="editRace(race)" class="mini">
                  Edit
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <footer class="control">
        <button class="bar add" @click="editRace(null)">
          Add Race
        </button>
      </footer>
    </fieldset>
    
    <fieldset v-show="selectedTab=='shirts'" class="inset-box">
      <h2>Event Shirts</h2>
      <div class="form-row">
        <label>Shirt Cutoff</label>
        <datetime-picker v-model="attributes.shirtCutoff" ref="shirtdate"/>
        <button class="inline mini" @click="clearShirtCutoff">Clear</button>
      </div>
      <div class="note">Entering a shirt cutoff will prevent anyone registered after the cutoff date from receiving a shirt. This may result in more participants going without a shirt and retaining a larger shirt inventory after the event.</div>
      <div class="form-row">
        <label>Unisex</label>
        <toggle-switch v-model="attributes.unisex" />
      </div>
      
      <template v-if="attributes.unisex">
        <h4>Sizes</h4>
        <div class="form-row">
          <label>XS</label><input v-model="attributes.shirtCounts['xs']" type="number" step="1" class="mini" min="0">
          <label>S</label><input v-model="attributes.shirtCounts['s']" type="number" step="1" class="mini" min="0">
          <label>M</label><input v-model="attributes.shirtCounts['m']" type="number" step="1" class="mini" min="0">
          <label>L</label><input v-model="attributes.shirtCounts['l']" type="number" step="1" class="mini" min="0">
          <label>XL</label><input v-model="attributes.shirtCounts['xl']" type="number" step="1" class="mini" min="0">
          <label>2XL</label><input v-model="attributes.shirtCounts['xxl']" type="number" step="1" class="mini" min="0">
        </div>
      </template>
      <template v-else>
        <h4>Women's</h4>
        <div class="form-row">
          <label>XS</label><input v-model="attributes.shirtCounts['wxs']" type="number" step="1" class="mini" min="0">
          <label>S</label><input v-model="attributes.shirtCounts['ws']" type="number" step="1" class="mini" min="0">
          <label>M</label><input v-model="attributes.shirtCounts['wm']" type="number" step="1" class="mini" min="0">
          <label>L</label><input v-model="attributes.shirtCounts['wl']" type="number" step="1" class="mini" min="0">
          <label>XL</label><input v-model="attributes.shirtCounts['wxl']" type="number" step="1" class="mini" min="0">
          <label>2XL</label><input v-model="attributes.shirtCounts['wxxl']" type="number" step="1" class="mini" min="0">
        </div>
        <h4>Men's</h4>
        <div class="form-row">
          <label>XS</label><input v-model="attributes.shirtCounts['mxs']" type="number" step="1" class="mini" min="0">
          <label>S</label><input v-model="attributes.shirtCounts['ms']" type="number" step="1" class="mini" min="0">
          <label>M</label><input v-model="attributes.shirtCounts['mm']" type="number" step="1" class="mini" min="0">
          <label>L</label><input v-model="attributes.shirtCounts['ml']" type="number" step="1" class="mini" min="0">
          <label>XL</label><input v-model="attributes.shirtCounts['mxl']" type="number" step="1" class="mini" min="0">
          <label>2XL</label><input v-model="attributes.shirtCounts['mxxl']" type="number" step="1" class="mini" min="0">
        </div>
      </template>
      <h4>Youth</h4>
      <div class="form-row">
        <label>XS</label><input v-model="attributes.shirtCounts['yxs']" type="number" step="1" class="mini" min="0">
        <label>S</label><input v-model="attributes.shirtCounts['ys']" type="number" step="1" class="mini" min="0">
        <label>M</label><input v-model="attributes.shirtCounts['ym']" type="number" step="1" class="mini" min="0">
        <label>L</label><input v-model="attributes.shirtCounts['yl']" type="number" step="1" class="mini" min="0">
        <label>XL</label><input v-model="attributes.shirtCounts['yxl']" type="number" step="1" class="mini" min="0">
      </div>
    </fieldset>

    <fieldset v-show="selectedTab=='options'" class="inset-box">
      <div class="form-row">
        <label for="timezone_offset">Timezone</label>
        <select v-model="attributes.timezoneOffset" id="timezone_offset">
          <option value="-7">Arizona / MST</option>
          <option value="-6">Mountain (MDT Summer)</option>
          <option value="-5">Central (CDT Summer)</option>
          <option value="-4">Eastern (EDT Summer)</option>
        </select>
      </div>
      <div class="form-row">
        <label for="is_public">Show Public</label>
        <toggle-switch v-model="attributes.isPublic" form-id="is_public"></toggle-switch>
      </div>
      
    </fieldset>
    
    <fieldset v-show="selectedTab=='options'" class="inset-box">
      
      <div class="form-row">
        <label for="pos-ingress-type">Ingress Adapter</label>
        <select v-model="attributes.posIngressType" id="pos-ingress-type">
          <option :value="null">None</option>
          <option value="trackleadersxml">TrackLeaders XML</option>
          <option value="raceresult">RaceResult</option>
          <option value="opensplittime">OpenSplitTime</option>
          <option value="ultralive">UltraLive.net</option>
        </select>
      </div>

      <component 
        v-if="attributes.posIngressType" 
        :is="posIngressOptionsComponent" 
        :options="attributes.posIngressOpts"
        :race-event="raceEvent"
      />
      
      <div class="form-row">
        <label for="theme">Livestream Theme</label>
        <input type="text" v-model="attributes.posIngressOpts.theme" />
      </div>

    </fieldset>
  </form>
</div>
