
<aside id="sidebar"> 
  <header class="sidebar-buttons">
    <ul>
      <li :class="{note:true,current:selectedTab=='notifications'}">
        <button :class="{round:true,selected:selectedTab=='notifications'}" title="Notifications" @click="showSidebar('notifications')">
          Notifications
        </button>
      </li><!-- https://fontawesome.com/icons/exclamation-circle?style=solid -->
      <li :class="{recent:true,current:selectedTab=='recents'}">
        <button :class="{round:true,selected:selectedTab=='recents'}" title="Recent Participants" @click="showSidebar('recents')">
          Recent Participants
        </button>
      </li> <!-- https://fontawesome.com/icons/history?style=solid -->
      <li :class="{lastseen:true,current:selectedTab=='lastseen'}">
        <button :class="{round:true,selected:selectedTab=='lastseen'}" title="Recent Crossings" @click="showSidebar('lastseen')">
          Recent Crossings
        </button>
      </li> <!-- https://fontawesome.com/icons/history?style=solid -->
    </ul>
  </header>

  <component :is="selectedTab" class="sidebar-content"/>
</aside>
