
<section class="lastseen">
  <header>
    <h1>Crossings</h1>
  </header>
  <header>
    <p>
      {{ selectedRace && selectedRace.name }}
    </p>
    <div class="select-wrapper">
      <select v-model="selectedSplit">
        <optgroup
          v-for="g in this.timingPointGroups"
          :label="g.race.name"
        >
          <option
            v-for="s in g.splits"
            :value="s"
          >
            {{ s.name }}
          </option>
        </optgroup>
      </select>
    </div>
  </header>

  <transition-group
    name="sidebar-list"
    tag="ul"
    class="lastseen-crossings"
  >
    <crossing-row-min
      v-for="c in crossings"
      :key="c.id"
      :timestamp-formatter="timestampFormatter"
      :crossing="c"
    />
  </transition-group>

  <loading-spinner v-if="loading" />
</section>
