<template>
  <aside id="sidebar"> 
    <header class="sidebar-buttons">
      <ul>
        <li :class="{note:true,current:selectedTab=='notifications'}">
          <button :class="{round:true,selected:selectedTab=='notifications'}" title="Notifications" @click="showSidebar('notifications')">
            Notifications
          </button>
        </li><!-- https://fontawesome.com/icons/exclamation-circle?style=solid -->
        <li :class="{recent:true,current:selectedTab=='recents'}">
          <button :class="{round:true,selected:selectedTab=='recents'}" title="Recent Participants" @click="showSidebar('recents')">
            Recent Participants
          </button>
        </li> <!-- https://fontawesome.com/icons/history?style=solid -->
        <li :class="{lastseen:true,current:selectedTab=='lastseen'}">
          <button :class="{round:true,selected:selectedTab=='lastseen'}" title="Recent Crossings" @click="showSidebar('lastseen')">
            Recent Crossings
          </button>
        </li> <!-- https://fontawesome.com/icons/history?style=solid -->
      </ul>
    </header>

    <component :is="selectedTab" class="sidebar-content"/>
  </aside>
</template>

<style>
.sidebar-list-enter-active,
.sidebar-list-leave-active { transition: all 0.4s ease; }
.sidebar-list-enter,
.sidebar-list-leave-to { transform: translate(100%, 0); opacity: 0;}
.sidebar-list-enter-to { transform: translate(0, 0);  }
.sidebar-list-move { transition: transform 0.16s; }
</style>

<style lang="scss" scoped>
#sidebar {
  max-width: 344px;
  z-index: 9;
  padding-top: calc(44px + 0.65em + 0.65em);
  height: 100vh;
  background: var(--popover-abgcolor);
  backdrop-filter: blur(10px);
  box-shadow: 0 0 5px rgba(0,0,0,0.2);

  // sidebar-buttons are on top of the main header
  .sidebar-buttons {
    margin: 1em 0 0; padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    width: var(--sidebar-exp-width);
    --button-round-size: 44px;
    
    ul {
      --button-round-size: 44px;
      list-style: none;
      margin: 0; padding: 0;
      display: flex;
      justify-content: flex-end;
      
      li {
        &:first-child { margin-left: 0.65em}
        &.mobile-only { display: none; }
        &.current button { background: var(--light-bgcolor);}
      
        button {
          margin: 0 0.5em 0 0;
          position: relative;
          text-overflow: hidden;
          text-indent: -9999px;

          &:before {
            text-indent: 0px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
          }
        }
        
        &.header-actions button::before { content: '\f7d9'; } //https://fontawesome.com/icons/tools?style=solid
        &.sidebar-toggle button:before { content: '\f191'; }
        &.note button:before { content: '\f06a'; }
        &.recent button:before { content: '\f1da'; }
        &.lastseen button:before { content: '\f022'; }
        
      }
    }
  }
  
  .sidebar-content {
//    position: absolute;
//    top: calc(44px + 0.65em + 0.65em);
//    left: 0; right: 0; bottom: 0;
    padding: 0.25em 1em;
  }
  
  ul {
    overflow-x: hidden;
    li {
      text-align: left;
      margin: 8px 0;
    }
  }
}

.note-enter-active,
.note-leave-active { transition: all 0.4s ease; }
.note-enter,
.note-leave-to { transform: translate(100%, 0); opacity: 0;}
.note-enter-to { transform: translate(0, 0);  }

</style>

<script>
import Notifications from "@/views/sidebar/notifications.vue"
import Recents from "@/views/sidebar/recents.vue"
import Lastseen from "@/views/sidebar/lastseen.vue"

export default {
  name: 'Sidebar', components: { Notifications, Recents, Lastseen},
  
  props: {
    tab: {
      type: String,
      default: 'notifications',
    }
  },
  
  data() {
    return {
      
    }
  },
  
  computed: {
    selectedTab() { return this.$store.state.settings.sidebarTab || 'notifications'; },
    
  },

  mounted() {

  },
  
  methods: {
    showSidebar(which) {
      if (!which){
        // toggle.
        this.$store.commit('setting', {name: 'expandSidebar', value: !this.expandSidebar});
      }
      else {
        this.$store.commit('setting', {name: 'expandSidebar', value: !!which});
        this.$store.commit('setting', {name: 'sidebarTab', value: which});
      }
    },

  }
}

</script>