<template>
  <article class="wave-assignment">
    <loading-spinner v-if="loading" />
    <header class="sub">
      <h2>Wave Planner</h2>
    </header>
    <section class="content-wrap">
      <form v-on:submit.prevent> 
        <fieldset>
          <div class="form-row">
            <label>Race</label>
            <select v-model="selectedRace">
              <option disabled>Choose a Race</option>
              <option v-for="race in races" :value="race">{{ race.name }}</option>
            </select>
          </div>
        </fieldset>          
        <fieldset>
          <div class="form-row">
            <label for="elite-waves">Create Waves</label>
            <input type="checkbox" v-model="autoCreateWaves"/>
          </div>
          
          <div class="form-row">
            <label for="separation">Spacing</label>
            <input type="number" step="1" v-model="maxPerWave" class="inline mini" />
            <span> / </span>
            <input type="number" step="1" v-model="waveSpacingMinutes" class="inline mini" /><span class="unit">min</span>
          </div>
            
          <div class="form-row">
            <label for="sort-attr">Sort By</label>
            <select v-model="sortField" title="Choose a Sort Method">
              <option disabled>Choose a Sort Method</option>
              <option v-for="name, field in sortFields" :value="field">{{ name }}</option>
            </select>

            <div v-if="sortField=='ultraSignupRanking'">
              <loading-spinner v-if="loadingRankings" class="tiny-loading" @click="loadingRankings = false"/>
              <span class="status okay" v-else-if="raceEvent && raceEvent.usRankings">✓</span>
              <span class="status err" v-else>⛔️</span>
            </div>
          </div>
          <div class="form-row" v-if="sortField=='ultraSignupRanking'">
            <label for="us-did">UltraSignup dID</label>
            <input type="number" v-model="usdid" :disabled="!this.selectedRace"/>
            <button @click="fetchRankings" class="mini" :disabled="!usdid">Fetch</button>
          </div>

          <div class="form-row">
            <label for="group-attribute">Group By Attribute</label>
            <div class="select-wrapper">
              <select v-model="groupByAttribute" placeholder="None">
                <option :value="undefined">None</option>
                <optgroup label="──────────"></optgroup>
                <option v-for="k in availableKeys" :value="k">{{k}}</option>
              </select>
            </div>
          </div>
          
          <div class="form-row">
            <label for="gender-weight">Time Pref Weight</label>
            <input type="range" min="0" max="1" step="0.001" v-model="wavePrefWeight" class="inline"/>
            <span class="note freeze" @click="wavePrefWeight=0">{{ parseFloat(wavePrefWeight).toFixed(2) }}</span>
          </div>
          
          <div class="form-row">
            <label for="gender-weight">Male/Female Weight</label>
            <input type="range" min="0.25" max="0.75" step="0.001" v-model="genderWeight" class="inline"/>
            <span class="note freeze" @click="genderWeight=0.5">{{ ((0.5 + parseFloat(genderWeight)) - 1.0).toFixed(2) }}</span>
          </div>
          
          <div class="form-row">
            <label for="first-wave-cap">First Wave Capacity</label>
            <input type="checkbox" v-model="firstWaveCapControl" />
            <input type="number" v-model="firstWaveCapacity" class="mini" :disabled="!this.firstWaveCapControl" :placeholder="this.maxPerWave"/>
          </div>
          
          
          <div class="form-row">
            <label for="weight-wave-first">Only Weight Leading</label>
            <input type="checkbox" v-model="genderWeightOnlyFirst"/>
            <label for="weight-wave-count">Count</label>
            <input type="number" v-model="genderWeightCount" :disabled="!genderWeightOnlyFirst" class="mini" />
          </div>
          <div class="form-row">
            <label for="elite-waves">Elite Waves</label>
            <input type="checkbox" v-model="eliteWaves"/>
          </div>
          <div class="form-row" v-if="eliteWaves">
            <table>
              <thead><tr><th></th><th>Men</th><th>Women</th></tr></thead>
              <tbody>
                <tr>
                  <th>Athletes</th>
                  <td><input type="number" class="mini" v-model="eliteSize.M" step="1"/></td>
                  <td><input type="number" class="mini" v-model="eliteSize.F" step="1"/></td>
                </tr>
                <tr>
                  <th>Waves</th>
                  <td><input type="number" class="mini" v-model="mensWaveCount" step="1"/></td>
                  <td><input type="number" class="mini" v-model="womensWaveCount" step="1"/></td>
                </tr>
              </tbody>
            </table>
          </div>
        </fieldset>
      </form>
      
      <div class="listings">
        <ul class="tabs">
          <li :class="{selected: show=='participants'}" @click="show='participants'">Participants</li>
          <li :class="{selected: show=='waves'}" @click="show='waves'">Waves</li>
        </ul>

        <div v-if="show=='participants'" class="flex-scroller-wrapper inset-box">
          <div class="flex-scroller par-table">
            <table>
              <thead>
                <tr>
                  <th>Last</th>
                  <th>First</th>
                  <th>Gen</th>
                  <th>Rank</th>
                  <th>Adjusted</th>
                  <th>Wave</th>
                  <th v-if="groupByAttribute">{{groupByAttribute}}</th>
                  <th v-else>{{sortField}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="p in displaySortedParticipants" :class="rowClassForPar(p)" @contextmenu="showWeightMenu(p, $event)">
                  <td class="last-name">{{ p.lastName }}</td>
                  <td class="first-name">{{ p.firstName }}</td>
                  <td :class="['gender', 'gen-'+p.gender]">{{p.gender}}</td>
                  <td class="ranking">{{ (rankingForPar(p, true) * 100).toFixed(1) }}</td>
                  <td class="adj ranking" :class="{manual: !!p.waveWeight }">{{ (adjustedRankingForPar(p) * 100).toFixed(1) }}</td>
                  <td class="wave">{{ waveForPar(p) }}</td>
                  <td v-if="groupByAttribute">{{ p[groupByAttribute] }}</td>
                  <td v-else>{{ p[sortField] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        
        <div v-if="show=='waves'" class="flex-scroller-wrapper inset-box">
          <div class="flex-scroller">
            <table>
              <thead>
                <tr>
                  <th>Number</th>
                  <th>Time</th>
                  <th>Count</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="w in waves">
                  <td class="w-no">{{ w.id }}</td>
                  <td class="w-tm">{{ fmttime(w.time) }}</td>
                  <!-- <td class="w-tm">
                    <input type="datetime-local" v-bind-date="w.time" />
                  </td> -->
                  <!-- <td class="w-tm">{{ w.time }}</td> -->
                  <td class="w-ct">{{ w.participants ? w.participants.length : '-' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        
        <footer>
          <label>Average Ranking</label>
          <span class="note">{{ averageRanking }}</span>
        </footer>
        
      </div>
    </section>
  </article>
</template>

<style lang="scss" scoped>

.freeze { user-select: none; }

.spinner {
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
}

.content-wrap {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.table-scroll {
  min-width: 420px;
  
}

label:first-child {
  min-width: 150px !important;
}

.form-row table {
  margin-left: 150px;
}

.listings {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 550px;
  
  .inset-box {
    padding: 0.5em 0;
  }
}

table {
  th {
    font-size: 0.9em;
    text-align:left;
    position: sticky;
    top: 0;
    background: var(--app-bgcolor);
    z-index: 100;
  }
  tr {
    cursor: pointer;
    &:hover { background: var(--row-hover-bgcolor);}
    td:first-child {
      padding-left: 2em;
    }
    
    td { white-space: nowrap ;}
  }
  .selected {
    background: var(--accent-color);
  }
  
  .buddy, .early, .late {
    td:first-child:before {
      display: inline-block;
      position: absolute;
      z-index: 10;
      width: 2em;
      margin-left: -1.5em;
      margin-right: -0.2em;
    }
  }
  /*.buddy td:first-child:before { content: '❤️'; }*/
  .buddy { background: rgba(200,50,0,0.3);}
  .early td:first-child:before { content: '☀️'; }
  .late  td:first-child:before { content: '🌙'; }
  
  .gen-F { color: #ee0076; }
  .gen-M { color: #1d606c; }
  .ranking.manual { font-weight: bold; text-decoration: underline;}
}

//input:not(:first-of-type) {
//  width: 3em !important;
//}
.tiny-loading {
  height: 1em;
  width: 1em;
  transform: scale(.5) translateY(-1.5em);
  vertical-align: middle;
}
</style>


<script>

import schema from "@/entity.js"
import WavePrefs from "@/views/wave-prefs.vue"
import Popover from "@/components/popover.vue"

export default {
  
  data() {
    return {
      loading: false,
      show: 'participants',
      
      dateFormatter: new Intl.DateTimeFormat('en-US', {
        weekday: 'short',
        // month: 'short',
        // day: 'numeric',
        hour: 'numeric', 
        minute: '2-digit', 
        second: '2-digit', 
        hour12: true,
      }),
      
      selectedRace: null,
      selectedParticipant: undefined,
      sortField: 'ultraSignupRanking',
      sortFields: {
        'ultraSignupRanking': 'UltraSignup Ranking',
        'bib': 'Bib',
        'random': 'Random'
      },
      
      assignments: {
        // participantId: proposedNumber
      },

      preAssignments: {
        // participantId: proposedNumber; for display only
      },
      
      cachedAverageRankings: {
        // participantId: average ranking, fuzzed to prevent M/F sorting
      },
      
      // Cache these because doing a double loop [].find lookup
      // is slow.
      originalRankings: {
        // participantId: { ranking, count } 
      },
      
      loadingRankings: false,
      
      assignedCount: 0,
      leftoverBibs: [],
      
      autoCreateWaves: true,
      waveSpacingMinutes: 10, // minutes
      maxPerWave: 10,
      groupByAttribute: undefined,
      wavePrefWeight: 0, // how strongly wave preferences matter
      genderWeight: 0.5,
      genderWeightOnlyFirst: false,
      genderWeightCount: 3,
      eliteWaves: false,
      eliteSize: {M: 10, F: 10},
      mensWaveCount: 0,
      womensWaveCount: 0,
      firstWaveCapControl: false,
      firstWaveCapacity: 0,
    }
  },
  
  watch: {
    participants() {
      // Assign rankings that we have for this race
      this.cacheOriginalRankings();
    },
    
    firstWaveCapControl() {
      if (undefined === this.firstWaveCapacity)
        this.firstWaveCapacity = this.maxPerWave;
    },
    
    selectedRace() {
      if (!this.selectedRace || !this.selectedRace.waves || !this.selectedRace.waves.attrs)
        return;
      
      this.loadSettingsFromRace();
    }
  },
  
  computed: {
    valid() { 
      return !!this.selectedRace && !!this.ranges
    },

    raceEvent() { return this.$store.state.activeRaceEvent; },

    races() { 
      if (!this.raceEvent) return [];
      return this.$store.getters.races(this.raceEvent.races).sort((a,b) => a.distance - b.distance);
    },
    
    usdid: {
      get() { return this.selectedRace && this.selectedRace.usDid },
      set(v) { if (this.selectedRace) this.selectedRace.usDid = v; }
    },
    
    availableKeys() {
      const raceIds = this.raceEvent.races;
      let p = Object.values(this.$store.state.participants).find(x => raceIds.includes(x.raceId));
      return Object.keys(p).sort();
    },

    averageRanking() {
      if (!this.originalRankings || this.loadingRankings) return 0;
      let count = 0;
      const total = Object.values(this.originalRankings).reduce((a, c) => {
        if (c && c.ranking) { 
          a += c.ranking
          count++;
        }
        return a;
      }, 0);

      console.log("averageRanking", count && total / count || 'count==0');
      
      return count && total / count || 0.5;
    },
    
    participants() {
      if (!this.selectedRace)
        return [];
      
      const participants = this.$store.getters.entity('participants', this.selectedRace.participants);
      return participants;
    },
    
    // Sorted by ranking
    sortedParticipants() {
      const sorted = [...this.participants];
      return this.sortPool(sorted);
    },
    
    displaySortedParticipants(){
      let p = [...this.sortedParticipants];
      p.sort((a,b) => {
        a = this.waveForPar(a) || Infinity;
        b = this.waveForPar(b) || Infinity;
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      });
      
      return p;
    },
    
    // An array of waves full of participant IDs
    waves() {
      let waves;
      let currentWave;
      let createNewWave;
      
      let firstWaveCapControl = this.firstWaveCapControl;
      let firstWaveCapacity = this.firstWaveCapacity;
      
      if (this.autoCreateWaves) {
        waves = [];
        currentWave = null;
        createNewWave = () => {
          const startTime = this.selectedRace.startTime || new Date();
          const w = {
            id: waves.length+1, 
            participants: [], 
            time: new Date(startTime.getTime() + (this.waveSpacingMinutes * 60 * 1000 * waves.length)),
            capacity: waves.length<1 && firstWaveCapControl && firstWaveCapacity ? firstWaveCapacity : this.maxPerWave,
          }
          waves.push(w);
          return w;
        }
      }
      else {
        // Fill waves as they already exist.
        const existingWaves = this.selectedRace.waves && this.selectedRace.waves.waves || [];
        waves = [...existingWaves];
        waves.forEach(w => w.participants = []);
        currentWave = null;
        createNewWave = () => {
          console.log("createNewWave", currentWave);
          if (null == currentWave) {
            currentWave = waves[0];
          }
          else {
            const i = waves.indexOf(currentWave);
            currentWave = i < 0 ? null : waves[i + 1];
          }
          
          if (null == currentWave) throw('ENoMoreWaves');
          
          return currentWave;
        }
      }
      
      // Reset the cached wave calcs
      this.assignments = {}
      
      // If we're grouping by an attribute, we'll have lots of pools.
      // Otherwise, we'll have a list of only one pool that includes everyone.
      let pools = {};
      if (this.groupByAttribute) {
        // grouping; a bunch of pools that get certain people.
        for (var i = this.participants.length - 1; i >= 0; i--) {
          const p = this.participants[i];
          const key = p[this.groupByAttribute] || 'everyone';
          if (!pools[key]) pools[key] = [];
          pools[key].push(p);
        }
        
        Object.values(pools).forEach(p=>this.sortPool(p))
      }
      else {
        // Not grouping; only one pool of everyone.
        pools.everyone = [...this.sortedParticipants];
      }
      
      try {
        Object.values(pools).forEach(pool => {
          // 1. Fill the mens' elite waves first
          //
          let mensEliteWaves = this.eliteWaves && parseInt(this.mensWaveCount) || 0;
          console.log("Mens' elite waves: ", mensEliteWaves);
          for (let i = mensEliteWaves - 1; i >= 0; i--) {
            currentWave = createNewWave();
            this.fillWaveWithTopGender(currentWave, pool, 'M');
          }
      
          // 2. Fill the womens' elite waves second
          let womensEliteWaves = this.eliteWaves && parseInt(this.womensWaveCount) || 0;
          console.log("Womens' elite waves: ", womensEliteWaves);
          for (let i = womensEliteWaves - 1; i >= 0; i--) {
            currentWave = createNewWave();
            this.fillWaveWithTopGender(currentWave, pool, 'F');
          }
          if (womensEliteWaves !=0) {
            // Don't let open participants get in this wave.
            currentWave = createNewWave();
          }

      
          console.log("Open Waves:", pool.length);
          let c = 0;
          while (pool.length > 0) {
            if (c++ > 100) {
              console.error("broke at 100 iterations");
              debugger;
              break;
            }

            console.log("-> Open participants left:", pool.length);
            console.log(`-> current wave has ${currentWave && currentWave.participants.length}`)
            if (!currentWave || currentWave.participants.length >= currentWave.capacity)
              currentWave = createNewWave();
        
            if (!this.fillWaveFromPool(currentWave, pool)) {
              console.warn("fillWaveFromPool returned false; breaking");
              break;
            }
          }
        
          // End the current wave; the next pool requires a new wave.
          currentWave = null;
        });
      }
      catch(e) {
        if ('ENoMoreWaves' != e) throw(e);
        console.debug(e);
      }

      return waves;
    },
    
  },
  
  
  mounted() {
    const reid = this.raceEvent?.id;
    if (!reid) return;
    this.$store.dispatch('loadDetailAttrs', {
      url: `/race_events/${reid}/participants/detail`,
      entity: [schema.participant],
      attributes: [
        "wave_id",
        "wave_start_time",
        "wave_buddy",
        "wave_weight",
        "wave_pref",
        "wave_request"
      ]
    })
    .catch((e) => { console.error('attr load error: ', e); this.loading = false })
    .then(() => this.loading = false);
  },
  
  methods: {
    loadSettingsFromRace() {
      const a = this.selectedRace.waves.attrs;
      console.debug(`Loading settings for ${this.selectedRace.name}`, a);

      this.autoCreateWaves = a.autoCreateWaves === undefined ? true : a.autoCreateWaves;
      this.waveSpacingMinutes = a.spacingMin || 5;
      this.sortField = a.sortBy;
      this.maxPerWave = a.perWave || 15;
      this.groupByAttribute = a.groupAttribute;
      this.wavePrefWeight = a.wavePrefWeight || 0.2;
      this.genderWeight = a.genderWeight;
      this.genderWeightOnlyFirst = a.genderWeightLimit;
      this.genderWeightCount = a.genderWeightCount;
      this.eliteWaves = a.eliteMCount > 0 || a.eliteFCount > 0;
      if (typeof a.eliteSize === 'object') {
        this.eliteSize = {
          M: a.eliteSize.m || a.eliteSize.M || 10,
          F: a.eliteSize.f || a.eliteSize.F || 10,
        }
      }
      else {
        this.eliteSize = {
          M: a.eliteSizeM || a.eliteSize || 10,
          F: a.eliteSizeF || a.eliteSize || 10,
        }
      }
      this.mensWaveCount = a.eliteMCount;
      this.womensWaveCount = a.eliteFCount;

      this.firstWaveCapControl = a.firstWaveCapacity !== 0;
      this.firstWaveCapacity = a.firstWaveCapacity;
    },
    
    fetchRankings(e) {
      e && e.preventDefault();
      
      this.loadingRankings = true;
      
      this.$axios.post(`/race_events/${this.raceEvent.id}/rankings`, {
        did: this.usdid
      })
      .then(r => {
        this.raceEvent.usRankings = r.data.usRankings;
        this.loadingRankings = false;
        this.cacheOriginalRankings();
      })
    },
    
    // Create a cached map of participantId -> usRanking
    // Called when participants change or rankings are updated
    cacheOriginalRankings() {
      if (this.raceEvent.usRankings) {
        
        this.originalRankings = {};
        
        this.participants.forEach(p=> {
          const r = this.raceEvent.usRankings.find(r => 
            r.firstName.toLowerCase() == p.firstName.toLowerCase() && 
            r.lastName.toLowerCase() == p.lastName.toLowerCase()
          )

          // p.ultraSignupRanking = r && r.ranking || 0// maybe null
          if (r) {
            this.originalRankings[p.id] =  {
              ranking: r.ranking,
              count: r.count
            };
          }
        });
      }
    },
    
    sortPool(pool) {
      // generate dependency on genderWeight
      const x = this.genderWeight;
      console.log(`sortedParticipants with sortField ${this.sortField}; genderWeight ${x}`);
      
      return pool.sort((a,b) => {
        
        switch (this.sortField) {
          case 'bib':
            a = a.bib === undefined ? Infinity : parseInt(a.bib);
            b = b.bib === undefined ? Infinity : parseInt(b.bib);
            console.log(`bib: `, a, b, a - b);
            return a - b;
            break;            
          case 'ultraSignupRanking': /*fall through */
          case 'random':
            a = this.adjustedRankingForPar(a);
            b = this.adjustedRankingForPar(b);
            break;
          default:            
            a = a[this.sortField];
            b = b[this.sortField];
            break;
        }
                
        if (a > b) return -1;
        if (a < b) return 1;
        return 0;
      });
    },
    
    doAssign(e) {
      e && e.preventDefault();
      console.log("Doing Wave Assignment");
      
      const attrs = {
        autoCreateWaves: this.autoCreateWaves,
        spacingMin: this.waveSpacingMinutes,
        sortBy: this.sortField,
        perWave: this.maxPerWave,
        groupAttribute: this.groupByAttribute,
        wavePrefWeight: this.wavePrefWeight,
        genderWeight: this.genderWeight,
        genderWeightLimit: this.genderWeightOnlyFirst,
        genderWeightCount: this.genderWeightCount,
        eliteSizeM: this.eliteSize.M,
        eliteSizeF: this.eliteSize.F,
        eliteMCount: this.eliteWaves ? this.mensWaveCount : 0,
        eliteFCount: this.eliteWaves ? this.womensWaveCount : 0,
        usDid: this.usDid,
        firstWaveCapacity: this.firstWaveCapControl && this.firstWaveCapacity || 0,
      };
            
      const waves = this.waves.map(w=>({
        id: w.id,
        time: w.time,
        participants: w.participants.map(p=>p.id)
      }));
            
      this.$axios.post(`/race_events/${this.$store.state.activeRaceEvent.id}/races/${this.selectedRace.id}/assign_waves`, {
        attrs,
        waves,
      }).then(r => {
        console.debug("response: ", r);
        
        // Set the current race, locally.
        if (!this.selectedRace.waves)
          this.selectedRace.waves = {waves: [], attrs: {}};

        Object.keys(attrs).forEach(k=> this.selectedRace.waves.attrs[k] = attrs[k]);
        this.selectedRace.waves.waves = waves;
        
        // Set the participants' values vlocally
        this.waves.forEach(w => {
          w.participants.forEach(p=> { 
            p.waveId = w.id;
            p.waveTime = w.time;
          });
        });
      });
      
    },
    
    doClearWaves() {
      this.$axios.post(`/race_events/${this.$store.state.activeRaceEvent.id}/races/${this.selectedRace.id}/assign_waves`, {
        waves: [
          { 
            id: 0,
            time: null,
            participants: this.participants.map(p=>p.id)
          }
        ],
      }).then(r => {
        console.debug("clear response: ", r);
        
        // Set the current race, locally.
        if (!this.selectedRace.waves)
          this.selectedRace.waves = {waves: [], attrs: {}};

        this.selectedRace.waves.waves = [];
        
        // Set the participants' values vlocally
        this.waves.forEach(w => {
          w.participants.forEach(p=> { 
            p.waveId = undefined;
            p.waveTime = undefined;
          });
        });
      });
    },
    
    rowClassForPar(p) {
      let early = false, late = false, strong = false;
      if (p.wavePref && p.wavePref.startsWith) {
        early = p.wavePref.startsWith('early');
        late = p.wavePref.startsWith('late');
        strong = p.wavePref.endsWith('+');
      }
      return {
        'lap': true, 
        selected: p == this.selectedParticipant, 
        buddy: p.waveBuddy,
        early,
        strong,
        late,
      }
    },
    
    fmttime(t) {
      console.debug("fmttime", t);
      try {
        if (typeof t === 'string') t = new Date(t);
      } catch(e) {}
      
      if (t.getSeconds && isFinite(t))
        return this.dateFormatter.format(t);

      return '-'
    },
    
    saveParticipantAttrs(id, attrs) {
      attrs.id = id;
      
      return this.$store.dispatch('saveEntity', {
        path: `/race_events/${this.$store.state.activeRaceEvent.id}/participants/`,
        entity: schema.participant,
        object: attrs
      })
    },
    
    showWeightMenu(par, e) {
      e && e.preventDefault();

      this.$nextTick(()=> this.selectedParticipant = par);
      
      Popover.showModalWithParent(this, event, {
        childComponent: WavePrefs,
        childBindings: {
          participant: par,
          waveStartTimes: this.waves,
          participants: this.participants,
        },
      }).then(popover => {
        popover.contentComponent.$on('updateWaveWeight', v => {
          // popover.contentComponent.value = v;
          console.log(`value ${par.id}:`, v);
          par.waveWeight = v;
          this.saveParticipantAttrs(par.id, {waveWeight: v});
        });
        
        popover.contentComponent.$on('updateWavePref', v => {
          console.log(`value ${par.id}:`, v);
          par.wavePref = v;
          this.saveParticipantAttrs(par.id, {wavePref: v});
        });
        
        popover.$on('close', () => {
          this.selectedParticipant = null;
        });
      });
    },
    
    // ================================
    // = Wave assignment algo follows =
    // ================================
    
    rankingForPar(par, skipAdjustment=false) {
      switch (this.sortField) {
        case 'ultraSignupRanking': {
          const r = this.originalRankings[par.id];
          if (skipAdjustment) return r && r.ranking || 0;
          return r && r.count >=3 && r.ranking || this.averageRankingForPar(par);
        }

        default:
          return Math.random();
      }
    },
    
    averageRankingForPar(par) {
      if (!this.cachedAverageRankings[par.id]) {
        const r = this.originalRankings[par.id];
        let ranking;

        // Adjust uncertain ultrasignup rankings
        if (!r || 0 == r.count) {
          ranking = this.averageRanking + (Math.random() * 0.01 - 0.005);
        }
        else
        if (r.count < 3) {
          // Weighted average
          ranking = ((r.count / 3) * r.ranking) + (((3-r.count) / 3) * this.averageRanking);
        }
        else {
          // Should never get here (we don't need their average ranking, 
          // and don't know their actual ranking here).
          console.warn("averageRankingForPar called for par with count > 3", r.count);
          ranking = this.averageRanking;
        }

        this.cachedAverageRankings[par.id] = ranking;
      }

      return this.cachedAverageRankings[par.id];
    },
    
    adjustedRankingForPar(par) {
      let ranking;
      let partyStartPreferences = [par.wavePref];
      if (par.waveBuddy) {
        // take the slowest buddy to determine wave
        const party = this.participants.filter(p=>p.waveBuddy == par.waveBuddy);
        const t = party.reduce((a,c)=> { 
          if (c.wavePref) partyStartPreferences.push(c.wavePref);
          let r = this.rankingForPar(c); 
          return r<a?r:a
        }, 1);
        ranking = t;
      }
      else 
      {
        ranking = this.rankingForPar(par);
      }
      
      let weight;
      if (par.waveWeight !== null && par.waveWeight !== undefined) {
        // Ungendered specific weighting
        weight = par.waveWeight;
      }
      else {
        weight = (this.genderWeight || 0.5) - 0.5; // center it.
        if (par.gender == 'M') weight *= -1;
      }

      if (isNaN(ranking) || ranking===undefined) debugger;
      if (isNaN(weight)  || weight===undefined) debugger;
      
      let partyCount=0;
      let startPrefWeight = partyStartPreferences.reduce((a,c) => {
        switch (par.wavePref) {
          case 'early+': partyCount++; return a + 1;
          case 'early':  partyCount++; return a + 0.4;
          case 'late':   partyCount++; return a + -0.6;
          case 'late+':  partyCount++; return a + -1.25;
          default: return a;
        }
      }, 0);

      if (partyCount > 0)
        startPrefWeight /= partyCount;

      console.debug(`${par.firstName} ${par.lastName}: ${(this.rankingForPar(par)||0).toFixed(2)} using ${(ranking||0).toFixed(2)}`)

      if (partyCount > 1)
        console.debug(`party of ${partyCount}: ${partyStartPreferences.join(', ')}=> ${startPrefWeight}`);

      return ranking + weight + startPrefWeight * this.wavePrefWeight;
    },
    
    fillWaveWithTopGender(currentWave, pool, gender) {
      // Get the top men's competitor first.
      // const countInWave = this.maxPerWave > pool.length ? pool.length : this.maxPerWave;
      const eliteSize = this.eliteSize[gender.toUpperCase()];
      const countInWave = eliteSize > pool.length ? pool.length : eliteSize;
      console.log(`-> grabbing ${countInWave} ${gender} wave`);
      for (let i=0; i < countInWave; i++) {
        let par;

        for (let j=0; j<pool.length; j++) { // reiterates all women. 
          if (pool[j].gender != gender) continue;
          par = pool.splice(j,1)[0]; break; // remove from pool and assign to par
        }

        if (!par) {
          // We have no more of this gender to fill this elite wave. 
          // Having a subelite dude running in the women's elite wave would be
          // weird, so we'll just bail now and have a smaller (e.g.) women's elite,
          // and the rest might be dudes.
          return;
        }
        
        console.log(`--> ${par.firstName} ${par.lastName}`)
        
        // create the wave
        currentWave.participants.push(par);
        
        // cache the participant's wave
        this.assignments[par.id] = currentWave.id;
      }
    },
    
    fillWaveFromPool(currentWave, pool) {
      let max = currentWave.capacity - currentWave.participants.length;
      let countToFill = max < pool.length ? max : pool.length;
      
      console.log(`fillWaveFromPool: process next ${countToFill} (max ${max}}; pool: ${pool.length})`);
      
      if (countToFill == 0) {
        debugger;
        return false;
      }
      
      // Check the next countToFill participants in the pool to see if they have a buddy code.
      // If they do, we will check if there's enough space for them in this wave and prioritize them
      // Otherwise, we will skip them and they'll end up in the next wave.
      //
      let buddyWaveKeysToSkip = [];
      
      for (let i=0; i<countToFill; i++) {
        const p = pool[i];
        if (!p) {
          debugger;
          break;
        }
        
        if (p.waveBuddy) {
          // We need all their friends
          console.log("=> Found waveBuddy key", p.waveBuddy);
          const buddyPool = pool.filter(x=>x.waveBuddy == p.waveBuddy);
          console.log(`==> found party of ${buddyPool.length}; have ${countToFill} spaces`);
          if (buddyPool.length >= currentWave.capacity) {
            // We will never be able to accomodate this entire party.
            buddyPool.splice(0, countToFill);
          }
          
          // Can we fit them in this wave?
          if (buddyPool.length <= countToFill) {
            for (let j=0; j<buddyPool.length; j++) {
              const buddy = buddyPool[j];
              console.log(`==> Adding buddy ${buddy.firstName} ${buddy.lastName} to ${currentWave.id} ${buddy.waveBuddy}`)
              currentWave.participants.push(buddy);
              this.assignments[buddy.id] = currentWave.id;
            }

            // Remove the buddies from the pool.
            console.log("==> Remove ", buddyPool.length);
            for (let j=0; j<buddyPool.length; j++) {
              const buddy = buddyPool[j];
              const idx = pool.indexOf(buddy);
              console.log(`===> remove at ${idx} ${buddy.firstName} ${buddy.lastName}`)
              pool.splice(idx, 1);
            }

            // Continue filling this wave until it is full
            // prevent the continuation of this loop.
            return this.fillWaveFromPool(currentWave, pool);
          }
          else {
            // Ignore these, because we know they can't fit.
            buddyWaveKeysToSkip.push(p.waveBuddy);
          }
        }
      }
      
      // Buddies have been handled here.
      let i=0;
      while (countToFill-- > 0) {
        if (!pool[i]) { debugger; }
        if (buddyWaveKeysToSkip.includes(pool[i].buddyWave)) {
          console.log(`=> Skipping par ${pool[i].id} with buddy key ${pool[i].buddyWave}`)
          i++;
        }
        else {
          // First in line and not in a currenlty prohibited group
          const par = pool.shift();
          currentWave.participants.push(par);
          this.assignments[par.id] = currentWave.id;
        }
      }

      return true;
    },
    
    waveForPar(par) {
      // Generate a dependency on this.waves
      const lastWave = this.waves[this.waves.length-1];
      if (!par || !par.id) 
        return lastWave;

      return this.assignments[par.id];
    },
    

    
    
  }
}

</script>