
<div class="popover-group" @click="groupClicked">
  <slot v-if="!showFromParent" name="target-button" :slot-props="this">
    <button @click.stop="toggleMenu" :class="buttonClassName">
      <span v-if="icon" class="icon">{{ icon }}</span>
      <img v-if="img" class="icon" :src="img" :alt="title">
      <span class="title-wrap">
        <span class="title">{{ title }}</span>
        <span class="detail" v-if="detailText">{{ detailText }}</span>
      </span>
    </button>
  </slot>
    

  <!-- There is a bug in Vue somewhere wherein this transition greatly confuses the ModalWindow component -->
  <!-- the workaround rightnow is to use a transition-group here. -->
  <transition name="popover" mode="out-in" @enter="beforeEnter" @before-leave="popoverDidClose">
    <div v-if="showMenu" key="popover-menu" ref="menu" :class="elementClassName">
      <!--class: popover-menu-wrap-->
      <div class="backdrop" />
      <div class="popover-menu" @mouseleave="mouseleave" :class="className">
        <div class="arrow-border" />
        <template v-if="!showAlternate">
          <slot />
        </template>
        <template v-else>
          <slot name="alternate" />
        </template>
        <div class="popover-control">
          <button v-if="doneLabel" @click.stop="hideMenu">
            {{ doneLabel }}
          </button>
        </div>
      </div>
    </div>
  </transition>
  
  <div v-if="showMenu" ref="modalCover" class="modal-cover popover" @click="showMenu=false"/>
</div>
