import schema from "@/entity.js"

import ReassignCrossings from "@/views/reassign-crossings.vue"
import InsertLaps from "@/views/insert-laps.vue"
import CrossingDetails from "@/views/crossing-details.vue"
import CrossingMatchFinish from "@/views/crossing-match-finish.vue"


export default {
  
  created() {
    // Global Actions:
    if (!this.$root._globalActionsAttached) {
      this.$root.$on('showCrossingDetails', this.showCrossingDetails);
      this.$root.$on('createStartCrossing', this.createStartCrossing);
      this.$root.$on('showReassignCrossings', this.showReassignCrossings);
      this.$root.$on('showInsertLapsBefore', this.showInsertLapsBefore);
      this.$root.$on('addCrossingMatchingFinish', this.addCrossingMatchingFinish); // participant
      this.$root.$on('setParticipantStartTime', this.setParticipantStartTime); // participant
      this.$root.$on('addLaggedFinish', this.showAddLaggedFinish);
      this.$root._globalActionsAttached = true
    }
  },
  
  methods: {
		
		authorizedDownload(path, mime='text/csv') {
		  this.$axios.get(path, {
		  	headers: { 'Accept': '*/*' }
		  })
		  .catch((err) => {
		    console.error(`Unable to download ${path}`, err);
		  })
		  .then((response) => {
				const url = response.config.url;
				const mime = response.headers["content-type"];
				
				// Extract filename
				const match = /filename="(.+?)"/.exec(response.headers['content-disposition']);
				const fn = match[1];

				console.debug(`Response: url:${url}; mime: ${mime}; fn: ${fn}`);

				var blob = new Blob([response.data], {type: `${mime}`});
				var link = document.createElement('a');
				
				link.href = URL.createObjectURL(blob);
				link.download = fn;
				document.body.appendChild(link);
				link.click();
			})
		},
		
    setParticipantStartTime({participant, crossing}) {
      this.$store.dispatch('saveEntity', {
        path: `race_events/${this.$route.params.raceEventId}/participants`,
        entity: schema.participant,
        object: {
          id: participant.id,
          waveStartTime: {arbitrary: true, timestamp: crossing.timestamp}
        }
      });
    },
    
    showCrossingDetails(crossing) {
      console.log("Request crossing details (or create):", crossing);
      
      ModalWindow.showModalWithParent(this, {
        childComponent: CrossingDetails,
        childBindings: {
          crossing
        },
        buttons: [
          {label: 'Delete',     class: 'danger',  click: (e, modal) => { modal.$children[0].deleteCrossing(); }},
          {label: 'Cancel',     class: 'cancel',  click: (e, modal) => { modal.cancel(); }},
          {label: crossing.id ? 'Save' : 'Add',   class: 'default', click: (e, modal) => { 
              // Edit or create the crossing, then close the modal.
              //
              const view = modal.contentComponent;

              console.log("save crossing", view.attributes.timestamp.toISOString());

              const crossing = view.attributes;
              const participant = this.$store.state.participants[crossing.participantId];
              const race = this.$store.state.races[participant.raceId];
              const raceEventId = race.raceEventId;
                            
              this.$store.dispatch('saveEntity', {
                path: `race_events/${raceEventId}/crossings`,
                entity: schema.crossing,
                object: view.attributes,
              })
              .then(() => {
                console.debug("created crossing");
                modal.cancel();
              
                // Recalculate splits.
                this.$cable.perform({
                  channel: 'ControlChannel', action: 'recalculate_splits', data: {ids: [participant.id] }
                });
              });
            }
          },
        ],
        closeOnEscape: true
      }).then((modalWindow) => {
        // modal exists here.
      });
    },
    
    createStartCrossing(participant) {
      // Determine start split from race
      const r = this.$store.state.races[participant.raceId];

      if (!r)
        return console.error("Cannot determine start split from undefined race");
      
      let startSplit = null;
      let eventRoute = null;
      let eventSourceId = null;
      
      const splits = this.$store.getters.entity('splits', r.splits).sort((a,b)=> a.distance-b.distance );
      if (splits.length < 1 || splits[0].distance > 0) {
        // Misconfigured race (@TODO: show error)
        console.warn("No splits or no zero-distance split for race", r, splits);
      }
      else {
        startSplit = splits[0];
        if (startSplit && startSplit.eventRoutes && startSplit.eventRoutes.length) {
          eventRoute = this.$store.state.eventRoutes[startSplit.eventRoutes[0]];
          eventSourceId = eventRoute.eventSourceId;
        }
      }
      
      this.$root.$emit('showCrossingDetails', {
        //"id":35086,
        eventSourceId: eventSourceId, //maybe
        participantId:participant.id,
        //"splitId":26,
        tag: participant.bib,
        readerId:"manual",
        timestamp: participant.waveStartTime || r.startTime,
        validCrossing:true,
        //"genderPlace":null,
        //"overallPlace":null
      });
    },
    
    showAddLaggedFinish(crossings) {
      // Determine start split from race
      const c = crossings[0];
      const participant = this.$store.state.participants[c.participantId];
      const r = this.$store.state.races[participant.raceId];

      if (!r)
        return console.error("Cannot determine start split from undefined race");
      
      let finishSplit = null;
      let eventRoute = null;
      let eventSourceId = null;
      
      const splits = this.$store.getters.entity('splits', r.splits).sort((a,b)=> b.distance-a.distance );
      if (splits.length < 1 || splits[0].distance <= 0) {
        // Misconfigured race (@TODO: show error)
        console.warn("No splits or no zero-distance split for race", r, splits);
      }
      else {
        finishSplit = splits[0];
        if (finishSplit && finishSplit.eventRoutes && finishSplit.eventRoutes.length) {
          eventRoute = this.$store.state.eventRoutes[finishSplit.eventRoutes[0]];
          eventSourceId = eventRoute.eventSourceId;
        }
      }
            
      this.$root.$emit('showCrossingDetails', {
        //"id":35086,
        eventSourceId: eventSourceId, //maybe
        participantId:participant.id,
        //"splitId":26,
        tag: participant.bib,
        readerId:"manual",
        timestamp: new Date(c.timestamp.getTime() + 1000), //@TODO configurable
        validCrossing:true,
        //"genderPlace":null,
        //"overallPlace":null
      });
    },
    
    showReassignCrossings(crossings) {
      console.log("Request reassign for crossings:", crossings);
      
      ModalWindow.showModalWithParent(this, {
        childComponent: ReassignCrossings,
        childBindings: {
          crossings
        },
        buttons: [
          {label: 'Cancel',     class: 'cancel',  click: (e, modal) => { modal.cancel(); }},
          {label: 'Reassign',   class: 'default', click: (e, modal) => { 
              // Do the reassign, then close the modal.
              //
              const reassignView = modal.contentComponent;
              console.debug("Reassign requested with view: ", reassignView);
              this.$store.dispatch('reassignCrossings', {
                crossings: crossings,
                receiver: reassignView.selectedParticipant
              }).then(() => {
                console.debug("reassign complete");
                modal.cancel();
              })
            }
          },
          {label: 'Prev', class:'left', click: (e,modal) => modal.contentComponent.prevPage() },
          {label: 'Next', class:'left', click: (e,modal) => modal.contentComponent.nextPage() },
        ],
        closeOnEscape: true
      }).then((modalWindow) => {
        // modal exists here.
      });
    },
    
    showInsertLapsBefore(participant, crossing) {
      console.log("Request insert laps:", participant, crossing);
      
      ModalWindow.showModalWithParent(this, {
        childComponent: InsertLaps,
        childBindings: {
          participant,
          beforeCrossing: crossing // the crossing we are inserting new ones before
        },
        buttons: [
          {label: 'Cancel', class: 'cancel',  click: (e, modal) => modal.cancel() },
          {label: 'Insert', class: 'default', click: (e, modal) => {  
              // Insert the crossings, then close the modal.
              //
              const insertView = modal.contentComponent;
              insertView.doInsertCrossings().then(()=> {
                console.debug("created crossings");
                modal.cancel();
              })
            }
          },
        ],
        closeOnEscape: true
      }).then((modalWindow) => {
        // modal exists here.
      });
    },
    
    addCrossingMatchingFinish(participant) {
      console.log("addCrossingMatchingFinish", participant);
      ModalWindow.showModalWithParent(this, {
        childComponent: CrossingMatchFinish,
        childBindings: {
          participant
        },
        buttons: [
          {label: 'Cancel',         class: 'cancel',  click: (e, modal) => { modal.cancel(); }},
          {label: 'Match Finish',   class: 'default', click: (e, modal) => { 
              // this.$nextTick(() => {
              //   modal.cancel();
              // })
              // Do the reassign, then close the modal.
              //
              const crossing = modal.contentComponent.createdCrossing;
              const race = this.$store.state.races[participant.raceId];
              const raceEventId = race.raceEventId;
              
              this.$store.dispatch('saveEntity', {
                path: `race_events/${raceEventId}/crossings`,
                entity: schema.crossing,
                object: crossing,
              })
              .then(() => {
                console.debug("created matched crossing");
                modal.cancel();
              });

            }
          },
          {label: 'Prev', class:'left', click: (e,modal) => modal.contentComponent.prevPage() },
          {label: 'Next', class:'left', click: (e,modal) => modal.contentComponent.nextPage() },
        ],
        closeOnEscape: true
      }).then((modalWindow) => {
        // modal exists here.
      });
    }
  }
}