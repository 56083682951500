
<section class="crossing-details">
  <h1>Edit Crossing</h1>
  <form class="content" onsubmit="return false">
    <div class="form-row">
      <label for="event_source">Event Source</label>
      <select v-model="attributes.eventSourceId" name="event_source">
        <option disabled :value="undefined">
          Choose an Event Source
        </option>
        <option v-for="es in eventSources" :value="es.id">
          {{ es.name }}
        </option>
      </select>
    </div>

    <div v-if="participant" class="form-row">
      <label for="split">Split</label>
      <select v-model="chosenSplitId" name="split">
        <option disabled :value="undefined">
          Automatic {{ currentSplit ? '('+currentSplit.name+')' : '' }}
        </option>
        <option v-for="s in possibleSplits" :value="s.id">
          {{ s.name }}
        </option>
      </select>
    </div>

    <div class="form-row">
      <label for="locked">Locked</label>
      <input v-model="attributes.splitLocked" type="checkbox">
      <span class="note inline">Locked crossings will maintain their split association even through split 
        recalculation. Use this when an earlier crossing was missed and this crossing is being wrongly 
        interpreted as an earlier crossing.</span>
    </div>
     

    <div class="form-row">
      <label for="timestamp">Timestamp</label>
      <!-- <input type="text" v-model="timestampInput" :placeholder="placeHolder"> -->
      <!-- <input type="datetime-local" v-model.lazy="isoTimestamp" :class="{invalid: isInvalidTimestamp}" step="1"> -->
      <!-- <input type="datetime-local" v-bind-date="attributes.timestamp" :class="{invalid: isInvalidTimestamp}" step="1"> -->
      <!-- <span class="note">{{ this.localTimestamp }}</span> -->
      <!-- <datetime v-model="attributes.timestamp" type="datetime"></datetime> -->
      <!-- <input type="datetime-local"> -->
      <!-- <input type="datetime-local" v-bind-date="attributes.timestamp" :class="{invalid: isInvalidTimestamp}" step="1"> -->
      <datetime-picker v-model="attributes.timestamp" :class="{invalid: isInvalidTimestamp}"/>
    </div>
  </form>
</section>
