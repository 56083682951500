
<div class="race-details">
  <header class="sub">
    <back-button @click="cancel">Cancel</back-button>
    <h1 class="flexible-space">{{raceEvent.name}} {{race.name}}</h1>
    <button v-if="race.id && selectedTab == 'options'" class="danger" @click="doDeleteRace">Delete Race</button>
    <button class="cancel" @click="doSaveRace">Save</button>
  </header>
  
  <ul class="tabs">
    <li @click.prevent="selectedTab='options'" :class="{selected:selectedTab=='options'}">Options</li>
    <li @click.prevent="selectedTab='map'" :class="{selected:selectedTab=='map'}">Map</li>
    <li @click.prevent="selectedTab='splits'" :class="{selected:selectedTab=='splits'}">Splits</li>
    <li @click.prevent="selectedTab='waves'" :class="{selected:selectedTab=='waves'}">Waves</li>
    <li @click.prevent="selectedTab='shirts'" :class="{selected:selectedTab=='shirts'}">Shirts</li>
  </ul>

  <form class="content" onsubmit="return false">
    <fieldset class="options" v-show="selectedTab=='options'">
      <h2>Options</h2>
    
      <div class="form-row">
        <label for="name">Name</label>
        <input type="text" name="name" v-model="race.name" />

        <label for="name">Distance</label>
        <input type="number" name="name" v-model="raceDistance" step="0.001" class="mini" />
        <span class="unit">km</span>

      </div>

      <div class="form-row">
        <label for="max-pace">Fastest Pace</label>
        <div class="input-flex">
          <span class="input-flex-sizer"><input type="number" id="max-pace-min" v-model="maxPaceMinutes" placeholder="00"/></span>
          <span class="separator">:</span>
          <span class="input-flex-sizer"><input type="number" id="max-pace-sec" v-model="maxPaceSeconds" placeholder="00"/></span>
          <span class="unit">min/km</span>
        </div>
      </div>
      <div class="form-row">
        <label for="max-pace">&nbsp;</label>
        <div class="note" v-if="this.race && this.race.maxPace">
          <span class="ms">{{ this.race.maxPace.toFixed(2) }} m/s</span> | 
          <span class="mph"> {{ (this.race.maxPace * 2.236936).toFixed(2) }} mph</span>
        </div>
      </div>
      
    
      <div class="form-row">
        <label for="is_loop">Looped?</label>
        <input id="is_loop" type="checkbox" v-model="race.isLoop" />
        <label for="score_by_teams">Has Teams?</label>
        <input id="score_by_teams" type="checkbox" v-model="race.scoreByTeam" />
      </div>

      <div class="form-row-section prefixes">
        <div class="form-row prefixes">
          <label for="prefixes">Prefixes</label>
          <ul class="box naked">
            <li v-for="(prefix, i) in race.prefixes">
              <autocomplete-row
                v-model="race.prefixes[i]"
                :choices="commonPrefixes"
                row-class="prefix-row"
              >
                <button class="mini bar del" @click="removePrefix(i)">Remove</button>
              </autocomplete-row>
            </li>
          </ul>
        </div>
        <div class="control">
          <button class="mini add" @click="addPrefix">Add</button>
        </div>
      </div>

      <div class="form-row">
        <label for="is_timed">Fixed</label>
        <select name="is_timed" v-model="race.isTimed" class="squish">
          <option :value="false" default>Distance</option>
          <option :value="true">Time</option>
        </select>
      </div>

      <div class="form-row">
        <label for="gun_time">Initial Starts</label>
        <select name="gun_time" v-model="race.gunTime" class="squish">
          <option :value="true" default>Gun Time</option>
          <option :value="false">Chip Time</option>
        </select>
      </div>
      
      <div class="form-row">
        <label for="start_time">Start Time</label>
        <datetime-picker v-model="race.startTime" />
        <span class="note">{{ ddf.format(race.startTime) }} - {{ ddf.format(race.cutoff) }}</span>
      </div>

      <div class="form-row">
        <label for="start_time">Cutoff Time</label>
        <datetime-picker v-model="race.cutoff" />
        <span class="note">{{ $durationString((race.cutoff || (race.startTime + 86400000)) - race.startTime) }}</span>
      </div>

      <div class="form-row">
        <label for="lps_duration">LPS Round</label>
        <input type="number" id="lps_duration" v-model="race.lpsDuration" />
        <span class="unit">min</span>
      </div>

      <div class="form-row">
        <label for="color">Color</label>
        <input type="color" v-model="race.color">
      </div>
    </fieldset>
    
    <fieldset class="gpx" v-show="selectedTab=='map'">
      <div class="form-row gpx-row">
        <label for="race_track">GPX</label>
        <input id="race_track" type="file" @change="uploadTrack" />
        <loading-spinner v-if="gpxUploading" />
        <span class="note" @click="gpxUploading = !gpxUploading">{{ gpxMessage }}</span>
      </div>
      
      <race-details-map :race="race" ref="map" />
    </fieldset>
    
          
    <fieldset class="splits varlist-wrapper active-elms" v-show="selectedTab=='splits'">
      <header>
        <h2>Splits</h2>
      </header>
      <ul class="varlist">
        <li id="roaming-split" @click="selectSplit(-1, $event)" :class="{selected:-1==selectedSplit}">
          <span class="split-name">Roaming</span>
        </li>
        <li v-for="split in splits" @click="selectSplit(split, $event)" :class="{selected:split==selectedSplit}">
          <span class="split-name">{{split && split.name || '---'}}</span>
        </li>
      </ul>
      <footer class="control">
        <button class="add bar" @click="addSplit" title="Add Split">Add</button>
        <button class="del bar" @click="removeSelectedSplit" :disabled="!selectedSplit" title="Remove Split">Delete</button>
      </footer>
    </fieldset>

    <fieldset class="split-options" v-show="selectedTab=='splits'">
      <h2 v-if="-1 !== selectedSplit">Split Options</h2>
      <split-options ref="splitOptions" 
        :target-split="selectedSplit"
        :roaming-event-routes="roamingEventRoutes"
        @addRoamingEventRoute="addRoamingEventRoute"
        @removeRoamingEventRoute="removeRoamingEventRoute"
        @eventRouteChanged="eventRouteChanged"
        @requestApplyEventRoutesToSimilar="requestApplyEventRoutesToSimilar"
      >
      </split-options>
    </fieldset>
    
    <fieldset class="waves varlist-wrapper active-elms " v-show="selectedTab=='waves'">
      <header>
        <h2>Waves</h2>
      </header>

      <ul class="flex-scroller varlist">
        <li v-for="wave in waves" @click="selectWave(wave, $event)" :class="{selected:wave==selectedWave}">
          <span class="split-name">{{wave.id}} - {{ wave.time ? $root.shortTimestampFmt.format(new Date(wave.time)) : '-' }}</span>
        </li>
      </ul>

      <footer class="control">
        <button class="add bar" @click="addWave" title="Add Split">Add</button>
        <button class="del bar" @click="removeSelectedWave" :disabled="!selectedWave" title="Remove Wave">Delete</button>
        <span class="flexible-space"></span>
        <button class="bar" @click="reorderWaveIDs" title="Change split IDs to be ordered by wave time. Updates all participants' wave IDs.">Reorder</button>
      </footer>
    </fieldset>
    
    <fieldset class="wave-options" v-show="selectedTab=='waves'">
      <h2>Wave Options</h2>
      
      <div class="inset-box">
        <div class="form-row">
          <label>Time</label>
          <datetime-picker v-model="selectedWaveTime" />
        </div>
      </div>
    </fieldset>
    
    <fieldset class="shirts" v-if="selectedTab=='shirts'">
      <h2>Race-specific Shirts</h2>
      <div class="form-row">
        <label>Use counts from:</label>
        <select name="count_selection" v-model="race.shirtCounts.strategy">
          <option :value="undefined" default>Overall Event</option>
          <option value="this">This race</option>
          <option value="all">All participants get a shirt</option>
          <option v-for="r in otherRaces" :value="r.id">Group with {{r.name}}</option>
        </select>
      
        <div class="form-row">
          <label>Unisex</label>
          <toggle-switch v-model="race.shirtCounts.unisex" />
        </div>

        <div class="form-row">
          <label>Size Down Women</label>
          <toggle-switch v-model="race.shirtCounts.wsizedown" :disabled="!race.shirtCounts?.unisex"/>
        </div>
      
      </div>
    
      <template v-if="race.shirtCounts.unisex">
        <h4>Unisex</h4>
        <div class="form-row">
          <label>XS</label><input  type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['xs']" />
          <label>S</label><input   type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['s']" />
          <label>M</label><input   type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['m']" />
          <label>L</label><input   type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['l']" />
          <label>XL</label><input  type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['xl']" />
          <label>2XL</label><input type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['xxl']" />
        </div>
      </template>
      <template v-else>
        <h4>Women's</h4>
        <div class="form-row">
          <label>XS</label><input  type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['wxs']" />
          <label>S</label><input   type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['ws']" />
          <label>M</label><input   type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['wm']" />
          <label>L</label><input   type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['wl']" />
          <label>XL</label><input  type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['wxl']" />
          <label>2XL</label><input type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['wxxl']" />
        </div>
        <h4>Men's</h4>
        <div class="form-row">
          <label>XS</label><input  type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['mxs']" />
          <label>S</label><input   type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['ms']" />
          <label>M</label><input   type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['mm']" />
          <label>L</label><input   type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['ml']" />
          <label>XL</label><input  type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['mxl']" />
          <label>2XL</label><input type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['mxxl']" />
        </div>
      </template>
      <h4>Youth</h4>
      <div class="form-row">
        <label>XS</label><input  type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['yxs']" />
        <label>S</label><input   type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['ys']" />
        <label>M</label><input   type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['ym']" />
        <label>L</label><input   type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['yl']" />
        <label>XL</label><input  type="number" step="1" class="mini" min="0" :disabled="shirtCountDisabled" v-model="race.shirtCounts['yxl']" />
      </div>
    </fieldset>
  </form>
</div>
