import dayjs from 'dayjs'
// var relativeTime = require('dayjs/plugin/relativeTime')
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime);

export const ISO8601RegEx = /^\d{4}(-\d\d(-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?)?)?$/i
// We can relax a little on these?
// export const ISO8601RegEx = /^[\d]{4}\-/

export function dateFromISOString(str) {
  // Is it a string?
  if (typeof str !== "string") return null;

  // Does it look like a date?
  const m = str.match(ISO8601RegEx);
  if (!m) return null;

  // Does it round trip correctly?
  let d;
  try {
    d = new Date(str);
    if (d.toISOString() != str) return null;
  }
  catch (e) {
    return null;
  }

  // It's a date.
  return d;
}

export function reviveJSONDates(obj) {
  Object.keys(obj).forEach((k) => {
    
    const v = obj[k];
    
    // See if it's a number
    // Don't try to parseInt something that's not a string, like
    // arrays or objects. !!! Note: parseInt([9]) == 9
    //
    // Why do we need to parseInt at all? Doesnt JSON.parse handle that
    // natively?
    /*
    if (typeof v === "string") {
      const i = parseInt(v);
      if (String(i) == v)
        return obj[k] = i;
    }
    */
    
    // See if it's a date
    const d = dateFromISOString(v);

    // Replace the key's value with a date.
    if (d)
      obj[k] = d;
  })
  

  return obj;
}

export function timeAgoInWords(date) {
  return dayjs(date).fromNow()
}

export default { ISO8601RegEx, dateFromISOString, reviveJSONDates};