<!-- <template>
  <ul class="menu">
    <li v-for="menuItem in menus" @click="click(menuItem, $event)" :class="{disabled: menuItem.disabled}">{{ menuItem.title }}</li>
  </ul>
</template> -->

<style lang="scss">
.menu {
  
  list-style: none;
  user-select: none;
  margin: 0 -10px; padding: 0;
  color: var(--text-color);
  
  li { 
    text-align: left; 
    padding: 4px 10px;
    white-space: nowrap; 
    min-width: 200px; 
    cursor: default; 
    
    &:hover:not(.disabled)    { cursor: pointer; color: #fff; background: var(--accent-color); }
    &.selected,
    &:active:not(.disabled)   { color: #ddd; background: var(--dark-accent-color); }
    &.disabled { 
      color: var(--text-dim3-color); 
      cursor: default;
      &:hover,
      &:active { background: inherit; font-weight: normal;}
    }
    
    &.menu-separator {
      user-select: none;
      background: transparent !important;
      cursor: default; 
      
      hr {
        border: none;
        border-bottom: 1px solid var(--text-dim3-color); 
      }
    }
  }

  .danger       { color: var(--button-danger-bgcolor) }
  .danger:hover {  background: var(--button-danger-bgcolor) !important; }
  .icon { font-family: 'Font Awesome 5 Free'; width: 1.25em; display: inline-block;}
}



</style>

<script>
  export default {
    props: {
      menus: {
        type: Array,
        required: true
      }
    },
    
    methods: {
      clickMenuItem(menuItem, event) {
        if (menuItem.disabled) {
          return;
        }
        
        if (menuItem.checkboxVNode) {
          menuItem.checkboxVNode.elm.click();
          return;
        }
        
        const cb = menuItem.action;
        if (!cb) return;

        cb(event);
        
        // Handle popovers
        if (this.$isInPopover)
          this.$parent.hide();
      }
    },
    
    render(h) {
      const clickHandler = this.clickMenuItem;
      const children = this.menus.map(m => {
        
        let itemElms = [];
        
        if (m.type == 'separator') {
          return h('li', {class: 'menu-separator'}, [h('hr')]);
        }
        else {
          let classes = []
          if (m.disabled) classes.push('disabled');
          if (m.class) classes.push(m.class);
          
          if (m.checked) {
            m.checkboxVNode = h('input', { 
              attrs: { 
                type: 'checkbox', 
                checked: m.checked.get()
              },
              on: { input: (e) => m.checked.set(e.target.checked) },
            });

            itemElms.push(m.checkboxVNode);
          }
          
          let title;
          if (!m.title) {
            console.warn("Menu lacks title", m);
            m.title = '';
          }
          if (m.icon) {
            title = [
              h('span', { class:'icon'  }, m.icon),
              h('span', { class:'title' }, m.title.trim())
            ]
          }
          else {
            title = m.title;
          }
          itemElms.push(title);

          return h('li', {
            class: classes,
            on: {
              'click': clickHandler.bind(this, m)
            }
          }, itemElms );
        }
      });
      
      return h('ul', {class: 'menu'}, children);
    }
  }
</script>