import schema from "@/entity.js"


export default {
  raceEvent() { return this.$store.state.activeRaceEvent; },
  races() { 
    if (!this.raceEvent) return [];
    return this.$store.getters.races(this.raceEvent.races).sort((a,b) => a.distance - b.distance);
  },
  
  racesByTime() {
    if (!this.raceEvent) return [];
    return this.$store.getters.races(this.raceEvent.races).sort((a,b) => (a.startTime || Number.POSITIVE_INFINITY) - (b.startTime || Number.POSITIVE_INFINITY));
  },

  participantIds() {
    // Determine which races to show. We do it this way to generate a dependency on
    // the raceEvent so that if the raceEvent changes the participants list will
    // also change.
    
    // ShowNone is set by default in the data() member. It will be true
    // until races actually load, so that we don't show errors in their place.
    if (this.filteredRaces.shownone === true)
      return [];
    
    let races = this.races;
    
    // Add dependency on participants. Without this, participants
    // will load, but we'll only be concerned with state.races, which will
    // not change.
    const participantIds = this.$store.state.participants; 
    
    // {
    //   14: true,  <.
    //   17: true,  <- show these
    //   18: false, <- don't show this one
    // }
    
    const filteredRaces = Object.keys(this.filteredRaces).filter(k => this.filteredRaces[k]).map(x=>parseInt(x))
    // => [14, 17] or [] for all.
    //
    // If we have any race preference, get only those races.
    // Otherwise, get all races for this event.
    //
    if (filteredRaces.length) {
      races = races.filter(r => filteredRaces.includes(r.id))
    }
    
    // We're requesting participants for everything in `races`.
    // If any of those races don't have participants, we need to
    // request the store to load them.
    let racesPendingLoad = [],
      racesThatHaveLoaded = [];
    
    races.forEach(r => r.participants === undefined ? racesPendingLoad.push(r) : racesThatHaveLoaded.push(r));
      
    if (racesPendingLoad.length) {
      console.warn("Participants: races pending load: ", racesPendingLoad);
      this.loading = true;
      this.$store.dispatch('loadEntities', {
        url: `/race_events/${this.raceEvent.id}/participants`,
        entity: [schema.participant]
      }).then(r => {
        console.log("loaded race", r);
        this.loading = false
      });
      console.log("Dispatched loadEntities");
    }
    
    return racesThatHaveLoaded.map(r => r.participants).flat();
  },
  
  // We want to cache the participants, I think.
  unfilteredParticipants() {
    return this.$store.getters.participants(this.participantIds);
  },

  filteredParticipants() {
    if (!this.races) {
      console.warn("races unset");
      debugger;
    }
    
    let p = this.unfilteredParticipants;
    
    // Prepare values outside the loop
    //
    // Genders, an object with zero or more keys {m: true, f: false}
    const selectedGenders = Object.keys(this.filteredGenders).filter(g=> this.filteredGenders[g]);
    console.log("Selected Genders:", selectedGenders);
    //
    // filteredAges is an object with zero or more keys {0: true, 5: true, 3: false}
    // ageFilterOptions is an array of objects describing the group: {name: 'Over 30', range: [30, 39]}
    // cache an array of the options that are selected [{id: 3, range: "Under 20", range[0, 19]}]
    const selectedAges = this.ageFilterOptions.filter(opt => this.filteredAges[opt.id]);
    
    // filteredStatus works like filteredAges, but with only an id.
    const selectedStatuses = Object.keys(this.filteredStatuses).filter(k=>this.filteredStatuses[k]).map(x=>parseInt(x));
    
    console.debug("Selected Statuses: ", selectedStatuses);
    
    // 
    // Search query from the big search box
    const q = this.searchQuery.trim().toLowerCase();
    

    // By default, include.
    // If a query exists and matches, pass to the next query (AND condition; all much match).
    // If a query exists and doesn't match, fail. 
    // If no queries exist, show all.
    //
    // This is essentially unrolled transducers, and could be refactored for clarity using transducers.
    //
    p = p.filter((par) => {
      if (this.bibQuery.length       && String(this.bibQuery).indexOf(String(par.bib)) != 0) return false;
      if (this.lastNameQuery.length  && !par.lastName.toLowerCase().includes(this.lastNameQuery.toLowerCase())) return false;
      if (this.firstNameQuery.length && !par.firstName.toLowerCase().includes(this.firstNameQuery.toLowerCase())) return false;
      if (selectedGenders.length     && !selectedGenders.includes(par.gender.toUpperCase())) return false;
      if (selectedAges.length        && !selectedAges.find(g => g.range[0] <= par.age && par.age <= g.range[1] )) return false;
      
      // Status is a special case. If "expected" is selected, we need to filter out
      // everone without a finishTime (ft)
      //
      let ss = Object.values(selectedStatuses);
      if (ss.length) {
        let ssic = ss.indexOf(15);
        if (ssic >= 0) {
          // only allow non-finished
          ss.splice(ssic,1);
          if (par.ft || par.status !=0) return false;
        }
        let ssif = ss.indexOf(16);
        if (ssif >= 0) {
          // only allow finished
          ss.splice(ssif,1);
          if (!par.ft) return false;
        }
        if (ss.length && !ss.find(s => par.status===s)) return false;
      }  
      
      // must match one of these OR conditions
      if (this.searchQuery && this.searchQuery.length) {
        let match = false;
        if (par.firstName.toLowerCase().includes(q)) match = true;
        if (par.lastName.toLowerCase().includes(q)) match = true;
        if (String(par.bib).indexOf(q) === 0) match = true;
        if (par.teamName && par.teamName.toLowerCase().includes(q)) match = true;

        if (!match) return false;
      }

      return true;
    })

    return p;
  },
  
  // Returns the shown participants array. This is cached internally.
  // column-filters toggle objects to show {key: true, otherKey: false} to determine what they
  // are filtering by. A true value means it is selected. A false value means it's unselected,
  // so UX is the only concern (we treat them as a don't care)
  //
  // Object.values(this.filteredSomethings).find(x=>x) returns truthy if something is filtered.
  //
  participants() {
    let p = this.filteredParticipants;
    
    if (!this.sortKey || !this.sortDir)
      // return p;
      this.sortKey = 'status';
            
    return p.sort((a,b) => {
      if (this.sortTransform) {
        a = this.sortTransform(a[this.sortKey]);
        b = this.sortTransform(b[this.sortKey]);
      }
      else {
        a = a[this.sortKey];
        b = b[this.sortKey];
      }
      if (a > b) return this.sortDir; 
      if (a < b) return -1 * this.sortDir;
      return 0;
    });
  },
  
  autosaveKey() {
    return 'raceEvent' + (this.raceEvent && this.raceEvent.id || 'unset');
  },
  
  sortByBib: {
    set(v) { this.sortKey = 'bib'; this.sortDir = v; this.sortTransform = parseInt},
    get()  { return this.sortKey == 'bib' ? this.sortDir : 0 }
  },
  sortByFirstName: {
    set(v) { this.sortKey = 'firstName'; this.sortDir = v; this.sortTransform = undefined},
    get()  { return this.sortKey == 'firstName' ? this.sortDir : 0 }
  },
  sortByLastName: {
    set(v) { this.sortKey = 'lastName'; this.sortDir = v; this.sortTransform = undefined},
    get()  { return this.sortKey == 'lastName' ? this.sortDir : 0 }
  },
  sortByRaceName: {
    set(v) { this.sortKey = 'raceId'; this.sortDir = v; this.sortTransform = (id) => this.$store.state.races[id].distance },
    get()  { return this.sortKey == 'race' ? this.sortDir : 0 }
  },
  sortByGender: { 
    set(v) { this.sortKey = 'gender'; this.sortDir = v; this.sortTransform = undefined },
    get()  { return this.sortKey == 'gender' ? this.sortDir : 0 }
  },
  sortByAge: {
    set(v) { this.sortKey = 'age'; this.sortDir = v; this.sortTransform = parseInt },
    get()  { return this.sortKey == 'age' ? this.sortDir : 0 }
  },
  
  sortByOverallPlace: {
    set(v) { this.sortKey = 'overallPlace'; this.sortDir = v; this.sortTransform = this.placementSortTranform; },
    get()  { return this.sortKey == 'overallPlace' ? this.sortDir : 0 }
  },

  sortByGenderPlace: {
    set(v) { this.sortKey = 'genderPlace'; this.sortDir = v; this.sortTransform = this.placementSortTranform; },
    get()  { return this.sortKey == 'genderPlace' ? this.sortDir : 0 }
  },
  
  sortByStatus: {
    set(v) { this.sortKey = 'status'; this.sortDir=v; this.sortTransform = this.statusSortTransform; },
    get()  { return this.sortKey == 'status' ? this.sortDir : 0 }
  },

  ageFilterOptions() {
    return [
      {id: 0, name: '<20', range: [0,19]},
      {id: 1, name: '20-29', range: [20,29]},
      {id: 2, name: '30-39', range: [30,39]},
      {id: 3, name: '40-49', range: [40,49]},
      {id: 4, name: '50-59', range: [50,59]},
      {id: 5, name: '60-69', range: [60,69]},
      {id: 6, name: '70+', range: [70,1000]},
    ]
  },
  
  statusFilterOptions() {
    return [
      {id: 0, name: 'OK'},
      {id: 1, name: 'DNS'},
      {id: 2, name: 'DNF'},
      {id: 3, name: 'DQ'},
      {id: 15, name: 'Current'},
      {id: 16, name: 'Finished'},
    ]
  },
  
  
  placementSortTranform() { 
    return value => value || Number.POSITIVE_INFINITY;
  },
  
  statusSortTransform() {
    return value => {
      switch (value) {
        case 0: return 1;  // Normal records to top
        default: return 2; // "Other"
        case 1: return 5;  // DNS to middle
        case 3: return 9;  // DQ
        case 2: return 10; // Dnfs to bottom
      }
    }
  }
}