class BibRangeParser {
  constructor(ranges) {
    this.ranges = ranges;
    this.error = undefined;
    this.parsedNumbers = [];
  }
  
  parseRanges() {
    if (!this.ranges)  {
      this.parsedNumbers = [];
      return;
    }
    
    this.error = null;
    let numbers = [];
    
    this.ranges.split(',').every(range => {
      // Remove whitespace on edges
      range = range.trim();
      
      // If this is a range e.g 1-4, find the ends.
      let splitI = range.indexOf('-');
      if (splitI < 0) {
        // This is a single, try to parseInt
        let value = parseInt(range);
        if (isNaN(value)) { 
          this.error = `Couldn't understand number '${range}''`
          return false;
        }
        // This is a number.
        numbers.push(value);
        return true;
      }
      
      if (splitI == 0) {
        // This looks like '-4'
        this.error = `Couldn't understand range '${range}''`;
        return false;
      }
      
      // range looks like something-something
      let ends = range.split('-');
      if (ends.length < 2 || ends.length > 2) {
        this.error = `Couldn't understand range '${range}'. Expected [number]-[number]`;
        return false;
      }
      
      // Get the first
      let startEnd = parseInt(ends[0]);
      if (isNaN(startEnd)) { 
        this.error = `Couldn't understand the first number in '${range}''`
        return false;
      }
      
      // Get the last
      let finishEnd = parseInt(ends[1]);
      if (isNaN(finishEnd)) { 
        this.error = `Couldn't understand the last number in '${range}''`
        return false;
      }
      
      // Add the ranges.
      for (let i=startEnd; i<=finishEnd; i++) {
        numbers.push(i);
      }
      return true;
    });
    
    this.parsedNumbers = numbers;
    return this.parsedNumbers;
  }
  
  
}

export default BibRangeParser;