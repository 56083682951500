<template>
  <div>
    <div class="form-row inline">
      <label for="pos-ingress-opts-slug">Event Slug</label>
      <input v-model="internalOptions.slug" type="text" id="pos-ingress-opts-slug" placeholder="ws100">
      <button class="mini" @click="fetchAuthorization">Update Details</button>
      <popover-static class-name="ext-details" button-class-name="mini" title="Show Details" prefer="up">
        <p v-if="!internalOptions.splits || internalOptions.splits.length<1">
          No Splits
        </p>
        <div v-else>
          <h3>UltraLive ID {{internalOptions.extRaceId }}</h3>
          <ul class="naked">
            <li v-for="split in internalOptions.splits">{{ split.name }} - {{ split.distance }}</li>
          </ul>
        </div>
      </popover-static>
      <loading-spinner v-if="loading"/>
    </div>
    
    <div class="form-row no-label">
      <p class="note" :class="responseClass">{{ responseMessage }}</p>
    </div>
    
    <div class="form-row">
      <label for="pos-ingress-opts-pollfreq">Poll Frequency</label>
      <input v-model="internalOptions.pollfreq" type="number" id="pos-ingress-opts-pollfreq"  step="1"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .spinner { --spinner-size: 32px; display: inline-block;}
  .ext-details {
    background: red;
    ul {
      overflow-y: scroll;
      li { text-align: left; }
    }
  }
</style>


<script>

import entities from '@/entity.js'
import PopoverStatic from '@/components/popover-static.vue'

export default {
  components: {PopoverStatic},
  props: {
    raceEvent: {
      type: Object,
      required: false,
    },
    
    options: {
      type: Object,
      default: () => ({})
    },
  },
  
  data() {
    return {
      loading: false,
      responseMessage: null,
      responseClass: null,
      internalOptions: {
        slug: undefined,
        pollfreq: 300,
      },
    }
  },
  
  mounted() {
    Object.assign(this.internalOptions, this.options || {});
  },
  
  watch: {
    internalOptions: {
      deep: true,
      handler() {
        this.commitInternalOptions();
      }
    }
  },
  
  computed: {
  },
  
  methods: {
    commitInternalOptions() {
      console.debug("emit internaloptions");
      this.$emit('update:options', this.internalOptions);
      Object.assign(this.options, this.internalOptions);
    },
    
    fetchAuthorization(e) {
      this.loading = true;
      this.$axios.post('race_events/pio_authorization', {
        type: 'ultralive',
        credentials: { slug: this.internalOptions.slug }
      })
      .catch(r=> {
        console.error("err fetching", r);
        this.loading = false;
      })
      .then(r=> {
        const {auth, credentials} = r.data;
        console.debug("Got race details: ", auth);
        if (auth.error) {
          this.responseMessage = auth.error;
          this.responseClass = 'error';
          return;
        }
        
        const rid = auth.id; // External race event id for ultralive API (string)
        auth.aidStations.forEach(s=> s.rid = rid);
        this.internalOptions.extRaceId = rid;
        this.internalOptions.splits = auth.aidStations;
        
        this.commitInternalOptions();
        this.loading = false;
      });
    }
  }
}
</script>