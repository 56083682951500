<template>
  <article class="reassigner flex-vertical">
    <header class="sub">
      <h1>{{ title }}</h1>
      <i class="flexible-space" />
      <input v-model="searchQuery" type="search" autofocus>
    </header>
    
    <div class="flex-scroller-wrapper">
      <div class="flex-scroller">
        <table class="lookup">
          <thead>
            <tr>
              <th class="bib">
                Bib
              </th>
              <th class="last-name">
                Last
              </th>
              <th class="first-name">
                First
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="par in participants" :class="rowClass(par)" @click="selectPar(par)">
              <td>{{ par.bib }}</td>
              <td>{{ par.lastName }}</td>
              <td>{{ par.firstName }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </article>
</template>

<style lang="scss" scoped>
  .reassigner { 
    min-width: 60vh;
    height: 40vh;
  }
  
  h2    { font-size: 16px; }
  
  table { 
    border-collapse: collapse; 
    border-spacing: 0; 
    width: 100%; 
    margin-bottom: 12px; 
    border-radius: var(--br);
  
    tr { 
      margin: 0 5px;
      line-height: 44px; 
      text-align: left;
      line-height: 32px; 
      cursor: default; 
      user-select: none;
      
      &.selected { background: var(--row-selected-color) !important; color: #fff!important; }
      &.disabled { background: auto !important; color: var(--text-dim2-color); }
      
      td { 
        padding: 4 8px; 
      }
    }
  }
  
  .bib { min-width: 50px; }
  .last-name  { min-width: 165px; }
  .first-name { min-width: 125px; }
  
</style>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: "Choose a Participant"
      },
      perPage: {
        type: Number,
        default: 10
      },
      // Support v-model
      value: {
        type: Object,
        required: false,
        default: undefined
      },
      disableSelector: {
        type: Function,
        default: null
      }
    },

    data() {
      return {
        newParticipant: undefined,
        searchQuery: '',
        page: 0,
        selectedParticipant: undefined
      }
    },
    
    computed: {
      allParticipants() { return Object.values(this.$store.state.participants) },
      filteredParticipants() {
        
        const q = this.searchQuery.trim().toLowerCase();
        
        return this.allParticipants.filter(par => {

          // @TODO: Stolen from participants.vue; might be good to create a mixin.
          // must match one of these OR conditions
          if (this.searchQuery && this.searchQuery.length) {
            let match = false;
            if (par.firstName && par.firstName.toLowerCase().includes(q)) match = true;
            if (par.lastName && par.lastName.toLowerCase().includes(q)) match = true;
            if (String(par.bib).indexOf(q) === 0) match = true;
            if (par.teamName && par.teamName.toLowerCase().includes(q)) match = true;

            if (!match) return false;
          }

          return true;
        })
      },
      
      participants() {
        const startIndex = this.perPage * this.page;
        return this.filteredParticipants.slice(startIndex, startIndex + this.perPage);
      },
      
      pageCount() {
        return this.filteredParticipants.length / this.perPage;
      }, 
      
      isOnLastPage() {
        return (this.page+1) * this.perPage > this.filteredParticipants.length;
      },
      
      isOnFirstPage() {
        return this.page <= 0
      }
    },
    
    watch: {
      searchQuery() {
        this.page = 0
      },
      
      selectedParticipant() {
        this.$emit('input', this.selectedParticipant);
      }
    },
    
    mounted() {
      this.setSelectionFromExternalValue();
    },
    
    methods: {
      selectPar(par) {
        if (this.disableSelector && this.disableSelector(par)) 
          return;

        this.selectedParticipant=par;
      },
      
      setSelectionFromExternalValue() {
        this.selectedParticipant = this.value;
      },
      
      rowClass(par) {
        let c = [];
        c.push('lap');
        if (par == this.selectedParticipant) c.push('selected');
        if (!par.ft) c.push('no-ft');
        if (this.disableSelector && this.disableSelector(par)) c.push('disabled');
        c.push(this.statusToClass(par));
        return c;
      },
      
      statusToClass(par) {
        switch (par.status) {
          case 1:  return 'status-DNS';
          case 2:  return 'status-DNF';
          case 3:  return 'status-DQ';
          case 4:  return 'status-unofficial';
          default: return 'status-cur'
        }
      },
      
      nextPage() {
        if (!this.isOnLastPage) {
          this.page++;
        }
      },

      prevPage() {
        if (!this.isOnFirstPage) {
          this.page--;
        }
      }
    }
  }
</script>