class ParticipantRanker {
  constructor(race, participants) {
    this._options = {
      
    };
    this._race = race;
    this._participants = [...participants];
    this._averageRanking = -1;
    this._rankings = [];
    this._isDirty = true;
  }
  
  set participants(pars) {
    let dirty = false;
    if (pars.length != this._participants.length) dirty = true;

    // check participants, adding what's needed
    for (let i = pars.length - 1; i >= 0; i--) {
      if (this._participants.indexOf(pars[i]) < 0) {
        dirty = true;
        break;
      } 
    }
    
    if (dirty) {
      this._isDirty = true;
      this._participants = [...pars];
    }
  }
  
  get participants() {
    return this._participants;
  }
  
  set options(newOptions){
    if (!newOptions) return;
    
    let didChange = false;
    Object.keys(newOptions).forEach(key => {
      if (this._options[key] != newOptions[key]) {
        didChange = true;
        this._options[key] = newOptions[key]
      }
    });

    if (didChange) {
      this.isDirty = true;
    }
  }
  
  get rankings() {
    if (this._isDirty) this.calculateRankings();
    this._rankings
    return this._rankings;
  }
  
  get averageRanking() {
    return this._averageRanking;
  }
  
  adjustedRankingForPar(par) {
    let ranking;
    let partyStartPreferences = [par.wavePref];
    if (par.waveBuddy) {
      // take the slowest buddy to determine wave
      const party = this.participants.filter(p=>p.waveBuddy == par.waveBuddy);
      const t = party.reduce((a,c)=> { 
        if (c.wavePref) partyStartPreferences.push(c.wavePref);
        let r = this.rankingForPar(c); 
        return r<a?r:a
      }, 1);
      ranking = t;
    }
    else 
    {
      ranking = this.rankingForPar(par);
    }
    
    let weight;
    if (par.waveWeight !== null && par.waveWeight !== undefined) {
      // Ungendered specific weighting
      weight = par.waveWeight;
    }
    else {
      weight = (this.genderWeight || 0.5) - 0.5; // center it.
      if (par.gender == 'M') weight *= -1;
    }

    if (isNaN(ranking) || ranking===undefined) debugger;
    if (isNaN(weight)  || weight===undefined) debugger;
    
    let partyCount=0;
    let startPrefWeight = partyStartPreferences.reduce((a,c) => {
      switch (par.wavePref) {
        case 'early+': partyCount++; return a + 1;
        case 'early':  partyCount++; return a + 0.4;
        case 'late':   partyCount++; return a + -0.6;
        case 'late+':  partyCount++; return a + -1.25;
        default: return a;
      }
    }, 0);

    if (partyCount > 0)
      startPrefWeight /= partyCount;

    console.debug(`${par.firstName} ${par.lastName}: ${(this.rankingForPar(par)||0).toFixed(2)} using ${(ranking||0).toFixed(2)}`)

    if (partyCount > 1)
      console.debug(`party of ${partyCount}: ${partyStartPreferences.join(', ')}=> ${startPrefWeight}`);

    return ranking + weight + startPrefWeight * this.wavePrefWeight;
  }
  
  calculateRankings() {
    console.log("Ranker: Calculating Rankings");
    let avg = 0;
    if (this._participants.length) {
      this._rankings = this._participants.map(p => {
        avg += p.rank;
        return {
          par: p, 
          ranking: p.rank, 
          adjRanking: .87
        }
      })

      avg /= this._participants.length;
    }

    this._averageRanking = avg;
    this._isDirty = false;
  }
  
  calculateSort() {
    
  }
}

export default ParticipantRanker;