
<tr :class="rowClass" :title="participant.notes">
  <td ref="op" class="place op">
    {{ overallPlaceDisplay }}
  </td>
  <td ref="gp" class="place gp">
    {{ participant.genderPlace || genderPlace || componentGenderPlace }}
  </td>
  <td ref="bib" class="bib" @click="$emit('click', $event)">
    <span :style="bibStyle">{{ participant.bib }}</span>
  </td>
  <td ref="ln" class="last-name" @click="$emit('click', $event)">
    {{ participant.lastName }}
  </td>
  <td ref="fn" class="first-name" @click="$emit('click', $event)">
    {{ participant.firstName }}
  </td>
  <td ref="gender" class="gender">
    {{ participant.gender }}
  </td>
  <td ref="age" class="age">
    {{ age }}
  </td>
  <td ref="race" class="race-name">
    {{ race.name }}
  </td>
  <td ref="wave" class="wave">
    {{ participant.waveId }}
  </td>
  <td ref="lastSplit" class="last-split">
    {{ lastSplitDisplay }}
  </td>
  <td ref="lastTime" class="race-time" :title="participant.ft">
    {{ timeDisplay }}
  </td>
  <td v-if="options.showTeamColumns" ref="teamName" class="team-name" :title="participant.teamName">
    {{ participant.teamName }}
  </td>
  <td ref="status" class="status">
    {{ statusToString(participant.status) }}
  </td>
</tr>
