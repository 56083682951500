<template>
  <div class="split-options">

    <div v-if="splitIsRoaming" class="form-rows">
      <h1>Roaming Event Sources</h1>
      <p class="note">
        Roaming event sources are not tied to a particular split. They may depend on GPS location,
        or not need a location at all, such as a spotter line.
      </p>
    </div>

    <div v-else class="form-rows">
      <div class="form-row">
        <label for="split-name">Name</label>
        <input ref="firstInput" v-model="split.name" type="text" :disabled="splitIsNull">
      </div>

      <div class="form-row">
        <label for="split-distance">Distance</label>
        <input v-model.lazy="split.distance" type="number" :disabled="splitIsNull" 
        >
        <span class="units">meters</span>
      </div>

<!--          @mousedown="onDistanceChange" 
          @mouseup="onDistanceChange"
          @keyup="onDistanceChange"
 -->

      <div class="form-row">
        <label for="split-cutoff">Cutoff</label>
        <!-- <input type="datetime-local" v-model="split.cutoff" :disabled="splitIsNull" placeholder="No Cutoff"> -->
        <datetime-picker v-model="split.cutoff" :disabled="splitIsNull"/>
      </div>
      
      <div v-if="split.point && split.point.geometry && split.point.geometry.coordinates" class="form-row loc">
        <label>Location:</label>
        <span>{{ locDisplay }}</span>
      </div>

      <div class="form-row pub">
        <label>Public:</label>
        <toggle-switch v-model="split.isPublic" form-id="is_public" />
        <label>Transition Point:</label>
        <toggle-switch v-model="split.isTransitionPoint" form-id="is_transition_point" />
      </div>
      <!-- <div class="form-row">
        <span class="date">{{ split.cutoff | formatDate('MMMM Do YY', 'No Cutoff')}}</span>
      </div>
      <div class="form-row">
        <span class="time">{{ split.cutoff | formatDate("h:mm:ss a", ' ')}}</span>
      </div> -->
    </div>
    
    <div class="event-routes varlist-wrapper">
      <header>
        <label for="event-source">Event Routing</label>
      </header>
      <ul class="no-highlight">
        <li v-for="route in eventRoutes"> 
          <span>Source</span>
          <select v-model="route.eventSourceId" name="event-source" @change="eventRouteChanged(route, 'eventSourceId')">
            <option default disabled :value="undefined">
              Unset
            </option>
            <option v-for="es in eventSources" :value="es.id">
              {{ es.name }}
            </option>
          </select>
          <!-- <input type="text" v-model="route.readerId" placeholder="All Readers"> -->
          <button class="del bar" :disabled="splitIsNull" title="Remove Route" @click="removeEventRoute(route)">
            Delete
          </button>
          <div class="debug">
            {{ route }}
          </div>
        </li>
      </ul>
      <footer class="control">
        <button class="add bar" :disabled="splitIsNull" title="Add Event Route" @click="addEventRoute">
          Add
        </button>
        <i class="flexible-space" />
        <button class="bar" :disabled="splitIsNull || splitIsRoaming" title="Apply event sources to all" @click="applyEventRoutingToSimilar">
          Apply to All
        </button>
        
      </footer>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .split-options {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    padding-bottom:0;
    
    h1 { padding-left: .5em; margin-top: -0.5rem; margin-bottom: 0ex;}
    
    .form-rows {
      padding-top: 1em;
      padding-bottom: 1em;
    }
    
    .loc span {
      font-size: 0.9em;
    }
    
    .units { margin-left: 1rem ;}
  }

  .event-routes {
    min-height: 125px;
    flex: 1;
    justify-self: flex-end;
    
    li {
      white-space: nowrap;
      display: flex;
      align-items: baseline;
      
      &>* { margin: 0; }
      span  { font-size: 0.85em; margin-right: 0.25em;}
      select { flex: 1 }
      input { padding:.25em; border-radius: 4px; border-width: 1px;}
      .del { }
      .debug { display: none; color: var(--text-dim3-color); font-size: 0.75em;}
    }
    
    footer {
      border-radius: 0 0 8px 8px;
    }
  }
</style>

<script>

import schema from '@/entity.js'

export default {
  props: {
    targetSplit: {
      required: false,
      default: undefined
    },
    
    roamingEventRoutes: {
      required: false,
      default: () => []
    },
  },
  
  data() {
    return {
      // We do it this way because this component is intended to be visible
      // while no split is selected, which means our split options won't
      // have anything to bind to.
      split: {},
      splitIsNull: true,
    }
  },
  
  computed: {
    eventSources() {
      return this.$store.state.eventSources;
    },
    
    eventRoutes() {
      if (this.splitIsNull) 
        return [];
      
      if (this.splitIsRoaming)
        return this.roamingEventRoutes;

      return this.$store.getters.entity('eventRoutes', this.split.eventRoutes);
    },
    
    splitIsRoaming() {
      // Actually not a split at all, but rather an indication that we want
      // to include event routes with this race that are not tied to a split.
      return -1 === this.targetSplit;
    },
        
    locDisplay() {
      const coords = this.split.point?.geometry?.coordinates;
      if (!coords) return "(unset)";
      return `${coords[1].toFixed(3)}, ${coords[0].toFixed(3)}`
    }
  },
  
  watch: {
    targetSplit(newVal) {
      // Make attributes point at split.
      // This works around null.name throwing.
      if (newVal) {
        this.split = newVal;
        this.splitIsNull = false;
        
        this.focusSplitName();
        
      }
      else {
        this.split = {};
        this.splitIsNull = true;
      }
    }
  },
  
  methods: {
    onDistanceChange(e) {
      // Unfinished
      e && e.preventDefault(); // Don't cancel mouseups without
      const rect = e.srcElement.getBoundingClientRect();
      const right = rect.width - e.offsetX;
      if (right >= 10 && right < 26) {
        // clicked a button
        if (e.offsetY <= (rect.height/2)) {
          //increment
          console.debug('increment');
        }
        else {
          //
          console.debug('decrement');
        }
      }
    },
    
    addEventRoute() {
      if (this.splitIsNull) 
        return console.debug("SplitOptions: add: split is null");
      
      if (this.splitIsRoaming)
      {
        return this.addEventRouteRoaming();
      }
      else
      {
        return this.addEventRouteSplit();
      }
    },
    
    addEventRouteRoaming() {
      console.debug("add roaming");
      this.$emit('addRoamingEventRoute');
    },
    
    // Call addEventRoute instead for proper checks.
    //
    addEventRouteSplit() {
      if (!this.split.eventRoutes)
        this.$set(this.split, 'eventRoutes', []);
      
      const newId = this.$store.getters.randomID();
      let route = { id: newId, splitId: this.split.id, eventSourceId: undefined };
      
      // Insert into store. This will create a normalized entry under
      // e.g. the key NEW-13451346. If we don't save, we need to delete
      // these on cleanup.
      console.debug("addEventRoute");
      
      let payload = {eventRoutes: {}};
      payload.eventRoutes[newId] = route;
      this.$store.commit('commitEntities', payload);
    },
    
    removeEventRoute(route, event) {
      if (this.splitIsRoaming)
      {
        return this.removeEventRouteRoaming(route, event);
      }
      else
      {
        return this.removeEventRouteSplit(route, event);
      }
    },
    
    removeEventRouteRoaming(esid) {
      console.debug("remove roaming", esid);
      this.$emit('removeRoamingEventRoute', esid);
    },
    
    eventRouteChanged(eventRoute, changedAttr) {
      this.$emit('eventRouteChanged', eventRoute, changedAttr);
    },
    
    removeEventRouteSplit(route, event) {
      // Is it a new event route, only on the client?
      let i;
      if (route.id.includes && route.id.includes('NEW')) {
        // Just delete and move on.
        this.$store.commit('destroyEntity', {
          entitySchema: schema.eventRoute,
          id: route.id
        });

        return;
      }
      
      // Otherwise, is it an existing route?
      if (route.id != undefined) {
        // We need to delete from the store and server.
        const path = `/race_events/${this.$store.state.activeRaceEvent.id}/races/${this.split.raceId}/event_routes/${route.id}`;
        
        this.$store.dispatch('destroyEntity', {
          entity: route, 
          entitySchema: schema.eventRoute,
          path
        }).then(route => {
          // Route is now deleted and this is the only reference.
        });
      }
    },
    
    applyEventRoutingToSimilar(e) {
      e && e.preventDefault();
      e.stopPropagation();
      
      console.log("Apply event routes like", this.split);
      this.$emit('requestApplyEventRoutesToSimilar', this.split);
    },
    
    focusSplitName(andSelect) {
      // Focus the first input
      this.$nextTick(()=> {
        // try {
          const input = this.$refs.firstInput
          input && input.focus();
          
          console.log(`Focus ${andSelect ? 'and select' : ''} input`, input);

          if (andSelect)
            input && input.select();
          
        // } catch(e) {}}
      });
    },
    
    willSave() {
      // The parent is about to save the race, split, etc.
    }
  }
  
}

</script>