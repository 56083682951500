
<div>
  <div class="form-row">
    <label for="pos-ingress-opts-url">GPS Position URL</label>
    <input v-model="options.url" type="text" id="pos-ingress-opts-url">
  </div>

  <div class="form-row">
    <label for="pos-ingress-opts-pollfreq">Poll Frequency</label>
    <input v-model="options.pollfreq" type="number" id="pos-ingress-opts-pollfreq"  step="1"/>
  </div>
</div>
