
<div class="race-details-map" ref="mapElm">
  <fieldset class="splits varlist-wrapper active-elms">
    <header>
      <h2>Splits</h2>
    </header>
    <ul class="varlist">
      <li v-for="split in splits" @click="selectSplit(split, $event)" :class="{selected:split==selectedSplit}">
        <span class="split-name">{{split && split.name || '---'}}</span>
      </li>
    </ul>
    <footer class="control">
      <button class="add bar" @click="addSplit" title="Add Split">Add</button>
      <button class="del bar" @click="removeSelectedSplit" :disabled="!selectedSplit" title="Remove Split">Delete</button>
    </footer>
  </fieldset>
  
  <div class="inset-box no-data" v-if="!coords || !coords.length">No GPX Track Found</div>
  
  <!-- <ul class="waypoint-list">
    <li v-for="p in suggestedWaypoints" @click="clickedSuggestedWaypoint(p)">
      {{p.properties.name}} ({{p.geometry.coordinates[3] | mToDistance({units: true}) }})
    </li>
  </ul> -->
  
  <button v-if="showSplitOptions" class="clear" @click="closeSplitOptions"></button>
  <split-options v-if="showSplitOptions" :target-split="selectedSplit" ref="splitOptions"></split-options>
</div>
