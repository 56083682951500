
<div class="pio-ost">
  <template v-if="editableCredentials">
    <div class="form-row inline">
      <label for="pos-ingress-opts-username">Email</label>
      <input v-model="username" type="text" id="pos-ingress-opts-username">

      <label for="pos-ingress-opts-password">Password</label>
      <input v-model="password" type="password" id="pos-ingress-opts-password">
    
      <button class="mini" @click="fetchToken">Login</button>
    </div>
    <div class="form-row no-label">
      <p class="note" :class="responseClass">{{ responseMessage }}</p>
    </div>
  </template>

  <template v-else>
    <div class="form-row no-label">
      <p class="login">Logged in as {{ username }} <button class="mini" @click="resetCredentials">Change</button></p>
      <p class="control">
        <button @click="importParticipants">Import Participants</button>
      </p>
    </div>
  </template>
  
  <div class="form-row">
    <label for="pos-ingress-opts-slug">Event Slug</label>
    <input v-model="internalOptions.slug" type="text" id="pos-ingress-opts-slug">
  </div>
  <div class="form-row">
    <label for="pos-ingress-opts-pollfreq">Poll Frequency</label>
    <input v-model="internalOptions.pollfreq" type="number" id="pos-ingress-opts-pollfreq"  step="1"/>
  </div>
</div>
