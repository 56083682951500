<template>
  <div class="chart">
    <h3 v-if="showTitle">Participants by Gender</h3>
    <loading-spinner v-if="loading" />
    <canvas ref="canvas" />
  </div>
</template>

<style lang="scss">
.chart {
  position: relative;
  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}  
</style>

<script>

 // Mostly just going through our database and getting as many points as 
 // possible (age ranges, states, countries, gender, anything else 
 // I'm not thinking of... repeat runners?)

   import schema from "@/entity.js"
   import participantsFiltering from "@/lib/participants-filtering.js"
   import Chart from 'chart.js'
   

export default {
  
  props: {
    showTitle: {
      type: Boolean,
      required: false,
      default: true
    },
    filteredRaces: {
      type: Object,
      default() { return {} }
    },
    filteredGenders: {
      type: Object,
      default() { return {} }
    }
    
  },
  
  data() {
    return {
      loading: false,
      searchQuery: '',
      
      bibQuery: '',
      lastNameQuery: '',
      firstNameQuery: '',

      // filteredRaces: {'shownone': true}, // show nothing until races get loaded.
      filteredAges: {},
      filteredStatuses: {},
      selectedRace: undefined,
      
      sortKey: 'id',
      sortDir: 1,
      sortTransform: null,
    }
  },
  
  /* Desparately ripped off from participants.vue */
  
  computed: {
    
    participantsAreLoading() {
      const x = this.$store.state.loadingEntities.includes("participants");
      console.debug("char-gender: participantsAreLoading? ", x, this.$store.state.loading);
      return x;
    },
    
    ...participantsFiltering,
    
    dataCount() {
      let ranges = [
        {id: 0, name: 'Male',   value:'M', count: 0},
        {id: 1, name: 'Female', value:'F', count: 0},
      ];
            
      this.participants.forEach(p => {
        if (!p.gender) {
          console.warn("ignoring null data for p", p);
          return [];
        }
        
        let found = false;
        for (let i=0; i<ranges.length; i++) {
          const entry = ranges[i];

          if (p.gender.toUpperCase() == entry.value) {
            // Fits in this range.
            found = true;
            entry.count++;
            break;
          }
        }

        if (!found) {
         console.warn("could not find data in ranges", p.gender, ranges);
         return [];
        }
        
      });
      
      return ranges;
    },
    
    colors() {
      return [
        'rgb(16, 182, 186)',
        'rgb(222, 26, 99)',
      ]
    },
    
    chartData() {
      console.log("calc chartData gender");
      return {
        datasets: [{
          data: this.dataCount.map(r => r.count),
          backgroundColor: this.colors
        }],
      
        labels: this.dataCount.map(r => r.name).sort((a,b) => b-a),
      }
    },
    
    chartOptions() {
      return {
        type: 'pie',
        options: {
          rotation: 0.5 * Math.PI,
          cutoutPercentage: 50,
          circumference: 2 * Math.PI,
          animation: {
            animateScale: true,
          },
        },
        data: this.chartData
      }
    }
  },
  
  
  watch: {
    genderCount() { this.updateChart(); },
    
    loading(val) {
      console.log("Loading", val);
      this.updateChart()
    },
    
    participantsAreLoading(v) {
      console.log('participants are loading:', v);
      this.updateChart();
    },
    participants() { this.updateChart(); },
    
  },
  
  mounted() {
    this.createChart();
  },
  
  methods: {
    createChart() {
      const c = this.$refs.canvas.getContext('2d');
      this.chart = new Chart(c, this.chartOptions);
    },
    
    updateChart() {
      const d = this.chartData.datasets[0];
      this.chart.data.datasets[0].data = d.data;
      this.chart.data.datasets[0].backgroundColor = this.chartData.datasets[0].backgroundColor;
      this.chart.data.labels = this.chartData.labels
      this.chart.update();
    }
  }
}

</script>