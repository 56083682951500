
<article class="reassigner flex-vertical">
  <header class="sub">
    <h1>{{ title }}</h1>
    <i class="flexible-space" />
    <input v-model="searchQuery" type="search" autofocus>
  </header>
  
  <div class="flex-scroller-wrapper">
    <div class="flex-scroller">
      <table class="lookup">
        <thead>
          <tr>
            <th class="bib">
              Bib
            </th>
            <th class="last-name">
              Last
            </th>
            <th class="first-name">
              First
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="par in participants" :class="rowClass(par)" @click="selectPar(par)">
            <td>{{ par.bib }}</td>
            <td>{{ par.lastName }}</td>
            <td>{{ par.firstName }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</article>
