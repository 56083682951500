
<div class="popover-group" :style="elementInlineStyle" @click="groupClicked">
  <div ref="menu" key="popover-menu" :class="elementClassName">
    <!--class: popover-menu-wrap-->
    <div class="popover-menu" @mouseleave="mouseleave">
      <div class="arrow-border" />
      <div class="popover-content">
        <div class="backdrop" />
        <slot />
      </div>
      <div v-if="doneLabel" class="popover-control">
        <button @click.stop="hide">
          {{ doneLabel }}
        </button>
      </div>
    </div>
  </div>
  
  <div key="modalCover" ref="modalCover" class="modal-cover popover"/>
</div>
