
<div class="home">
  <section class="last-import">
    <header class="sub">
      <h1>Last Import</h1>
    </header>
    <div v-if="raceEvent && raceEvent.lastImportAt">
      {{ dateFormatter.format(raceEvent.lastImportAt) }} ({{ timeAgo(raceEvent.lastImportAt) }})
    </div>
    <div v-else>
      Never Imported
    </div>
  </section>

  <section class="charts">
    <header class="sub">
      <h1>At a Glance</h1>
    </header>

    <div class="flex">
      <section class="gender">
        <header class="sub">
          <h1>Participants by Gender</h1>
        </header>
    
        <chart-gender :filtered-races="filteredRaces" :show-title="false"/>
      </section>

      <section class="finished">
        <header class="sub">
          <h1>Participant Status</h1>
        </header>
    
        <chart-finishers :filtered-races="filteredRaces" :show-title="false"/>
    
      </section>
    </div>
  </section>
  
  <section class="active-participants">
    <header class="sub">
      <h1>Active Participants</h1>
    </header>
    <h3>Last 30 Min - {{ activeParticipants.length }} seen</h3>
    <table ref="table" class="participants participants-table">
      <thead />
      <tbody>
        <participant-row v-for="par in activeParticipants" :key="par.id" :participant="par" @click="alert(par)"/>
      </tbody>
    </table>
  </section>

</div>
