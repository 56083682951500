<template>
  <div class="home">
    <section class="last-import">
      <header class="sub">
        <h1>Last Import</h1>
      </header>
      <div v-if="raceEvent && raceEvent.lastImportAt">
        {{ dateFormatter.format(raceEvent.lastImportAt) }} ({{ timeAgo(raceEvent.lastImportAt) }})
      </div>
      <div v-else>
        Never Imported
      </div>
    </section>

    <section class="charts">
      <header class="sub">
        <h1>At a Glance</h1>
      </header>

      <div class="flex">
        <section class="gender">
          <header class="sub">
            <h1>Participants by Gender</h1>
          </header>
      
          <chart-gender :filtered-races="filteredRaces" :show-title="false"/>
        </section>

        <section class="finished">
          <header class="sub">
            <h1>Participant Status</h1>
          </header>
      
          <chart-finishers :filtered-races="filteredRaces" :show-title="false"/>
      
        </section>
      </div>
    </section>
    
    <section class="active-participants">
      <header class="sub">
        <h1>Active Participants</h1>
      </header>
      <h3>Last 30 Min - {{ activeParticipants.length }} seen</h3>
      <table ref="table" class="participants participants-table">
        <thead />
        <tbody>
          <participant-row v-for="par in activeParticipants" :key="par.id" :participant="par" @click="alert(par)"/>
        </tbody>
      </table>
    </section>

  </div>
</template>

<style lang="scss">
  
  .home > section { 
    border: 1px solid var(--text-dim3-color); 
    margin: 0.5em;
    padding: 0.5em;
    border-radius: var(--br);
  }

  section.charts { 
    padding-bottom: 3em; 
    .flex {
      align-items: flex-start;
    }
    section {
      max-width: 50%;
      flex-grow: 1;
    }
  }
</style>

<script>
  import ParticipantRow from "@/components/participant-row.vue"
  import schema from "@/entity.js"

  import ChartGender from "@/views/stats/chart-gender.vue"
  import ChartFinishers from "@/views/stats/chart-finishers.vue"

  import { timeAgoInWords } from '@/lib/date-tools'
  
  export default {
    name: 'Home', 
    components: { ParticipantRow, ChartGender, ChartFinishers },

    data() { 
      return { 
        flag: true 
      }
    },
    
    watch: {
      raceEvent(oldVal, newVal) {
        console.debug("new raceEvent", oldVal, newVal);
        this.loadRaceEventSummary();
      }
    },
        
    computed: {
      
      raceEvent() { return this.$store.state.activeRaceEvent; },

      races() { 
        if (!this.raceEvent) return [];
        // return this.$store.getters.races(this.raceEvent.races).sort((a,b) => a.distance - b.distance);
        return this.$store.getters.races(this.raceEvent.races);
      },
      
      filteredRaces() {
        let fr = {};
        this.races.forEach(r=> fr[r.id] = true);
        return fr;
      },

      // @TODO: Stolen & hacked up from participants.vue; should make a dependency on something external
      participantIds() {
        // Determine which races to show. We do it this way to generate a dependency on
        // the raceEvent so that if the raceEvent changes the participants list will
        // also change.
        let races = this.races;
        
        // Add dependency on participants. Without this, participants
        // will load, but we'll only be concerned with state.races, which will
        // not change.
        const participantIds = this.$store.state.participants; 
        
        // We're requesting participants for everything in `races`.
        // If any of those races don't have participants, we need to
        // request the store to load them.
        let racesPendingLoad = [],
          racesThatHaveLoaded = [];
        
        races.forEach(r => {
          if (r.participants === undefined) {
            racesPendingLoad.push(r)
            this.loading = true;
            this.$store.dispatch('loadEntities', {
              url: `/race_events/${this.raceEvent.id}/races/${r.id}/participants`,
              entity: [schema.participant]
            }).then(r => {
              this.loading = false
              // Prevent looping. We got back what we got back.
              racesPendingLoad.forEach(r => {
                if (!r.participants) {
                  this.$set(r, 'participants', []); 
                }
              });
            });
          }
          else { 
            racesThatHaveLoaded.push(r)
          }
        });
                  
        return racesThatHaveLoaded.map(r => r.participants).flat();
      },
      
      activeParticipants() {
        const participants = this.$store.getters.entity('participants', this.participantIds);
        const thresholdTime = new Date()  - (30 * 60 * 1000); // now
        // const thresholdTime = new Date(Date.parse('Sat Nov 16 2019 08:26:22 GMT-0700'))
        // const thresholdTime = new Date(Date.parse('Sat Dec 7 2019 13:30:00 GMT-0700')) - (30 * 60);
        // const thresholdTime = new Date(Date.parse('Sat Jan 4 2020 01:30:00 GMT-0700'));
        
        let active = participants.filter((p) => {
          return p.lastSeenAt > thresholdTime;
        });
        
        active.sort((a,b) => b.lastSeenAt - a.lastSeenAt)

        return active;
      },
      
      dateFormatter() { 
        return new Intl.DateTimeFormat('en-US', {
          weekday: 'short', 
          month: 'short', 
          day: 'numeric', 
          hour: 'numeric', 
          minute: '2-digit', 
          second: '2-digit', 
          hour12: true,
        })
      },
    },
    
    methods: {
      loadRaceEventSummary() {
        this.$axios.get(`race_events/${this.raceEvent.id}/summary`)
        .then(r => {
          console.debug("raceEvent summary: r", r);
        
          // r.data.crossingCounts
        });
      },
      
      timeAgo(x) { return timeAgoInWords(x); }
    }
  }
</script>
