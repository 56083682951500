<template>
  <div class="ticker">
    <transition-group tag="ul" name="ticker">
      <li v-for="crossing in crossings" :key="crossing.id" @click="clickCrossing(crossing)">
        <span class="bib-stripe" :style="styleForCrossing(crossing)">&nbsp;</span>
        {{ displayForCrossing(crossing) }}
      </li>
    </transition-group>
  </div>
</template>

<style lang="scss">
  .ticker {
    height: 44px;
    overflow: hidden;
    pointer-events: none;
    display: none;
    
    ul {
      margin: 6px 0; padding: 0;
      line-height: 32px; 
      
      li {
        pointer-events: all;
        display: inline-block; 
        margin-right: 12px;
        height: 32px;
        width: 54px;
        background: rgba(255,255,255,0.8);
        box-shadow: 0 0 5px rgba(0,0,0,0.2);
        border-radius: 4px;
        overflow: hidden;
        font-weight: bold;
        font-size: 20px;
        color: #777;
        text-align: center;
        line-height: 26px;
        
        .bib-stripe {
          display: block;
          content: '';
          height: 7px;
          background: repeating-linear-gradient(45deg, rgba(255,0,0,0.5), rgba(255,0,0,0.5) 5px, rgba(255,0,0,0.0) 5px, rgba(255,0,0,0.0) 10px);
        }
      }
    }
  }
  
  .ticker-enter-active,
  .ticker-leave-active { transition: all 0.74s; }
  
  .ticker-enter-from {
    opacity: 0;
    transform: translateX(100vw);
  }
  
  .ticker-leave-to {
    opacity: 0; 
    width: 1px !important;
    transform: translateX(-100vw);
    margin-right: 0px !important;
  }
  
</style>

<script>
  export default {
        
    data() {
      return {
        crossings: [],
      }
    },

    computed: {
      /*
      debugCrossings() {

        return [];

        // This pulls in all crossings for debug display purposes. It doesn't call advance().
        let xings = [];
        Object.keys(this.$store.state.crossings).some(c => {
          if (xings.length > 10) return true;
          xings.push(this.$store.state.crossings[c]);
          return false;
        });
        return xings;
      }
      */
    },

    mounted() {
      this.$root.$on('crossingCreateFinished', (entities) => {
        
        let crossings = entities.crossings || {};
        Object.values(crossings).forEach((crossing) => {
          // Add to the display list.
          this.crossings.push(crossing);
        
          // Clear this crossing, using the transition defined above, after a few seconds
          setTimeout(this.advance.bind(this, crossing), 7000);
        });
      });
    },
    
    destroyed() {
      this.$root.$off('crossing');
    },
    
    methods: {
      
      advance(payload) {
        this.crossings.splice(this.crossings.indexOf(payload), 1);
      },
      
      displayForCrossing(crossing) {
        if (crossing && crossing.tag)
          return crossing.tag.replace(/^0*/, '');
        else
          return '-'
      },
      
      clickCrossing(crossing) {
        console.log("Clicked ticker crossing", crossing);
        const participant = this.$store.getters.participantByBib(crossing.tag)
        if (participant) {
          this.$router.push({name: 'participant', params: { raceEventId: this.$store.state.activeRaceEvent.id, id: participant.id }})
        }
      },
      
      styleForCrossing(crossing) {
        const participant = this.$store.getters.participantByBib(crossing.tag);
        const race = participant && this.$store.state.races[participant.raceId];
        if (race && race.color)
          return `background: ${race.color}`
        else
          return 'repeating-linear-gradient(45deg, rgba(255,0,0,0.5), rgba(255,0,0,0.5) 5px, rgba(255,0,0,0.0) 5px, rgba(255,0,0,0.0) 10px);'
      }
    }
  }
</script>

