<template>
  <li
    class="participant-result"
    :class="`gen-${subject.gender}`"
    @click="handleClick"
    :title="`${subject.firstName} ${subject.lastName}`"
  >
    <span class="bib"><span :style="bibStyle">{{ subject.bib }}</span></span>
    <span class="name">
      <span class="first-name">{{ subject.firstName }}</span>
      <span class="last-name">{{ subject.lastName }}</span>
    </span>
    <span class="gender">{{ subject.gender }}</span>
    <span class="age">{{ age }}</span>
    <span
      v-if="subject.waveId"
      class="wave"
    >W-{{ subject.waveId }}</span>
    <span class="race-name">{{ race.name }}</span>
    <span class="flexible-space" />
    <span class="status">{{ statusString }}</span>
  </li>
</template>

<style lang="scss">
.participant-result {
//  display: table-row;
  display: flex;
  flex-direction: row;
  line-height: 44px;
  cursor: pointer;
  user-select: none;
  
  &:hover {
    background: var(--row-hover-bgcolor);
  }

  &>span {
    vertical-align: middle;
    vertical-align: -webkit-baseline-middle;
    white-space: nowrap; text-overflow: ellipsis; overflow: hidden
  }
  
  .flexible-space {
    flex-grow: 1;
    flex-basis: 1px;
  }

  span:not(last-child) {
    margin-right: 8px;
  }
  
  .bib {
    width: 50px;
    text-align: center;

    span {
      color: var(--neg-color); 
      background: var(--neg-bgcolor); 
      padding: 2px;
      border-radius: 4px; 
      font-weight: bold; 
      min-width: 2em;
      display: inline-block;
      margin: 0 auto;
      line-height: 1.25em;
    }
  }
  
  .wave { width: 5ex; color: var(--text-dim1-color); }
  
  .name { min-width: 25ex;}
  .first-name { min-width: 10ex; overflow: hidden; text-overflow: ellipsis; white-space:nowrap; }
  .last-name  { min-width: 18ex; overflow: hidden; text-overflow: ellipsis; white-space:nowrap; }

  &.gen-F .gender { width: 1.5ex; color: #ee0076; text-align: right}
  &.gen-M .gender { width: 1.5ex; color: #1d606c; text-align: right}
  
  .status {
    text-align:right;
    display: none;
  }
  
}
</style>

<script>
export default {
  props: {
    subject: {
      type: Object,
      required: true
    }
  },
  
  computed: {
    race() { return this.$store.getters.entity('races', this.subject.raceId); },
    age() {
      const par = this.subject;
      if (par.age) return par.age;
      if (par.dob) {
        // calc age from birthday
        const diff = new Date().setHours(0) - new Date(par.dob);
        return new Date(diff).getUTCFullYear() - 1970;
      }
      return undefined;
    },
    
    bibStyle() {
      return `border-top: 8px solid ${this.race.color}; padding-top: 0px`;
    },
    
    statusString() {
      switch (parseInt(this.subject.status))
      {
        case 0:
          if (!this.subject.st) 
            return 'Expected';
          if (this.subject.ft)
            return 'Finished';

          return 'Current';
        case 1: return 'DNS';
        case 2: return 'DNF';
        case 3: return 'DQ';
        case 4: return 'Unofficial';
        default:
          return this.subject.status;
      }
    }
  },
  
  methods: {
    handleClick() {
      this.$emit('resultClick', {type: 'participant', subject: this.subject});
    }
  }
}
</script>