export const tabDirective = {
  bind(el, binding, vNode) {
    // console.log('%ctab-directive: bind called', 'color:magenta', el, binding);
    const vm = vNode.context;

    // We're a tab.
    // Determine what we'll call when we're clicked. This allows us to have different sets of tabs.
    //
    let methName;
    if (binding.arg) {
      let tmp = binding.arg[0].toUpperCase() + binding.arg.substr(1);
      methName = `setSelected${tmp}Tab`; // 'tele' -> setSelectedTeleTab
      binding.def.selectedKey = `selected${tmp}Tab`; // 'tele' -> setSelectedTeleTab
    }
    else {
      methName = 'setSelectedTab';
      binding.def.selectedKey = `selectedTab`;
    }
    
    if (!vm[methName]) {
      console.error(`Cannot connect tab to vue component; vm.${methName} is not defined.`, vm.$options.name || vm)
      return;
    }
    
    binding.listener = (e) => {
      vm[methName](el.id, e);
    };
    
    el.addEventListener('click', binding.listener);
    console.log('%ctab-directive: bind called', 'color:magenta', el, binding);
  },

  inserted(el, binding, vNode, oldVNode) {
  },

  update(el, binding, vNode, oldVNode) {
    // console.debug(`%ctab-directive: updated (checking ${binding.selectedKey})`, 'color:magenta', binding.def, vNode.context[binding.def.selectedKey], el.id);
    let selected = vNode.context[binding.def.selectedKey] == el.id;
    selected ?
      el.classList.add('selected') :
      el.classList.remove('selected');
  },

  unbind(el, binding) {
    if (binding.listener) {
      // console.debug('%ctab-directive: detect-change: unbind', 'color:magenta', el);
      el.removeEventListener('click', binding.listener);
    }
  }
}


export function installTabDirective(vue) {  
  // Shorthand to allow <input v-tab:tabgroup > to call vm.setSelectedTabgroupTab('bib') on click
  vue.directive('tab', tabDirective);
}