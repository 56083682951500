<template>
  <div class="popover-group" @click="groupClicked">
    <slot v-if="!showFromParent" name="target-button" :slot-props="this">
      <button @click.stop="toggleMenu" :class="buttonClassName">
        <span v-if="icon" class="icon">{{ icon }}</span>
        <img v-if="img" class="icon" :src="img" :alt="title">
        <span class="title-wrap">
          <span class="title">{{ title }}</span>
          <span class="detail" v-if="detailText">{{ detailText }}</span>
        </span>
      </button>
    </slot>
      
  
    <!-- There is a bug in Vue somewhere wherein this transition greatly confuses the ModalWindow component -->
    <!-- the workaround rightnow is to use a transition-group here. -->
    <transition name="popover" mode="out-in" @enter="beforeEnter" @before-leave="popoverDidClose">
      <div v-if="showMenu" key="popover-menu" ref="menu" :class="elementClassName">
        <!--class: popover-menu-wrap-->
        <div class="backdrop" />
        <div class="popover-menu" @mouseleave="mouseleave" :class="className">
          <div class="arrow-border" />
          <template v-if="!showAlternate">
            <slot />
          </template>
          <template v-else>
            <slot name="alternate" />
          </template>
          <div class="popover-control">
            <button v-if="doneLabel" @click.stop="hideMenu">
              {{ doneLabel }}
            </button>
          </div>
        </div>
      </div>
    </transition>
    
    <div v-if="showMenu" ref="modalCover" class="modal-cover popover" @click="showMenu=false"/>
  </div>
</template>

<style src="@/assets/popover.scss" lang="scss"/>

<style scoped>
.popover-group button         { display: flex !important; align-items: stretch; }
.popover-group button img     { width: 32px; display: inline-block; }
.popover-group button .title-wrap  { display:flex; flex-direction: column; flex-grow: 1; align-items: stretch; justify-content: space-evenly;}
.popover-group button .title  { line-height: 1 !important; font-size: 1.1em;}
.popover-group button .detail { line-height: 1 !important; font-size: 0.9em;}
</style>

<script>
  
  import popoverSupport from './popover-support'
  
  export default {
    
    props: {
      title: {
        type: String,
      },
      
      className: {
        type: String,
      },
      
      buttonClassName: {
        type: String,
      },
      
      detailText: {
        type: String,
        default: undefined
      },
      
      icon: {
        type: String,
        default: undefined,
      },
      img: {
        type: String,
        default: undefined,
      },
      
      // No target button, always points its arrow at the parent
      //
      showFromParent: {
        type: Boolean,
        default: false
      },
      
      // Start showing initially
      startOpen: {
        type: Boolean,
        default: false
      },
      
      doneLabel: {
        type: String,
        default: undefined
      },
      
      prefer: {
        type: String,
        default: 'dn',
      },
            
      closeOnMouseLeave: {
        type: Boolean,
        default: false
      },
      
    },
    
    data() { 
      return {
        showMenu: false,
        direction: 'dn',
        showAlternate: false,
      }
    },
    
    computed: {
      elementClassName() {
        return 'popover-menu-wrap ' + this.direction
      },
    },
    
    mounted() {
      // console.debug("PopoverStatic: mounted")
      // if (this.startOpen) {
      //   this.show()
      // }
    },
    
  
    methods: {
      ...popoverSupport,
      
      groupClicked(e) {
        // Will receive modal cover clicks
        if (e.target.classList.contains('modal-cover'))
          return;

        if (this.showMenu)
          return;
        
        
        this.toggleMenu(e);
      },
      
      toggleMenu(e){
        e && e.preventDefault();

        // Dispatch to hide or close
        if (this.showMenu) {
          this.hide(e);
        }
        else{
          this.show(e);
        }
      },
      
      getTargetElm(e) {
        // Find lowest appropriate targetElm
        //
        if (this.showFromParent) {
          this.targetButton = this.$parent.$el
        }
        else
        if (e.nodeName) {
          this.targetButton = e;
        }
        else {
          this.targetButton = e.currentTarget || e.target;
        }
        
      },
      
      show(e) {
        this.showAlternate = e.altKey;
        
        this.getTargetElm(e);
              
        // Hide other popovers
        this.$root.$emit('popover', 'close', this);
        
        // Make targetButton relative
        // I'm not sure this is appropriate anymore. The popover-group should be shown
        // regardless of shown/hidden state, so the target button will already be positioned
        // relative to the popover-group.
        // 
        /*
        if (!['relative', 'fixed', 'absolute'].includes(this.targetButton.style.position)) {
          // make relative
          this.targetButton.setAttribute('data-qrelative', 'relative');
          this.targetButton.style.position = this.targetButton.style.position;
          this.targetButton.style.zIndex = 200;
        }
        */
                
        this.showMenu = true
        
        window.addEventListener('scroll', this.reposition);
        window.addEventListener('resize', this.reposition);
        
        this.$nextTick(() => {
          // Make modalCover a descendent of the body so that it covers everything.
          const modalCover = this.$refs.modalCover;
          document.body.appendChild(modalCover);
          
          // Inform the children.
          let hit = [];
          this.$children.forEach((c) => {
            c.$isInPopover = true
            if (c.$options.onPopoverShow) {
              hit.push(c);
              c.$options.onPopoverShow.apply(c)
            }
          });

          // For some reason $children isn't populated. Wonderful.
          this.$slots.default.forEach((c) => {
            let vm = c.context;
            vm.$isInPopover = true;
            if (vm.$options.onPopoverShow && !hit.includes(vm))
              vm.$options.onPopoverShow.apply(vm)
          })
          

        })
      },
      
      hide() {
        // hiding
        window.removeEventListener('scroll', this.reposition);
        window.removeEventListener('resize', this.reposition);
        
        var oldPositionVal;
        if ((oldPositionVal = this.targetButton.getAttribute('data-qrelative'))) {
          // reset position
          this.targetButton.style.position = oldPositionVal;
        }

        this.showMenu = false
      },

      mouseleave(e){
        if (this.closeOnMouseLeave && e.target == e.currentTarget) {
          this.hide(e);
        }
      },
      
      
      
      beforeEnter(menu) {
        this.$nextTick(() => {
          document.body.appendChild(menu);
          this.position(menu);
          
          this.$emit('popover-open');
        })
      },
    
      popoverDidClose(arg) {
        window.removeEventListener('scroll', this.reposition);
        window.removeEventListener('resize', this.reposition);
        this.$emit('close', this);
      },
      
      reposition() {
        this.position(this.$refs.menu);
      }
      
    }
  }
</script>