<template>
  <div class="autocomplete" :class="rowClass" ref="parentRow">
    <label v-if="label" :for="label + '-input'">{{ label }}</label>
    <div class="autocomplete-wrap">
      <input ref="input" v-model="internalValue" type="text" @keydown="onKey" @focus="inputFocus(true)" @blur="inputFocus(false, $event)" @change.stop="onChange">
      <button class="fa accept mini" v-if="showAutocompleteList" @mousedown="didClickAccept"></button>
      <ul v-if="showAutocompleteList" class="naked predictions menu" :class="{'has-label': !!label}">
        <li v-for="(n, i) in autocompleteList" :key="i" :class="{selected: selectedPrediction===i}" @mousedown="chooseAutocomplete(n)">
          {{ n }}
        </li>
      </ul>
    </div>
    <slot />
  </div>
</template>

<style lang="scss" scoped>
  .autocomplete-wrap {
    position: relative;
    
    input {
      margin-right: 24px;
    }

    button.accept {
      padding: 0;
      width: 1.5em;
      display: inline-block;
      margin-left: -24px;
    }

    .predictions {
      position: absolute;
      z-index: 100;
      top: 100%;
      left: 0;
      width: max-content;
      min-width: 5ch;
      max-width: 242px;
      box-shadow: 0 4px 25px rgba(0,0,0,0.5);
      overflow-y: auto;

      border-radius: var(--br);
      background: var(--app-bgcolor);
      background: var(--input-bgcolor);
      
      &.has-label {
        left: calc(80px + 0.5em);
      }
      
      li {
        // Uses classes from menu-contents
      }
    }
  }
</style>

<script>
  export default {
    name: 'AutocompleteRow',
  
    props: {
      value: {
      
      },
      label: {
        type: String,
        required: false,
        default: undefined,
      },
      choices: {
        type: Array,
        default: () => []
      },
      
      rowClass: {
        type: String,
        required: false,
        default: 'form-row'
      }
    },
  
    data() {
      return {
        internalValue: undefined,
        showAutocompleteList: false,
        selectedPrediction: null
      }
    },
    
    computed: {
      
      rankedChoices() {
        return this.choices.map(x => [x, this.stringSimilarity(this.internalValue, x)]);
      },
      
      autocompleteList() {
        let list = [...this.rankedChoices];
        list.sort((a, b) => {
          const x = a[1];
          const y = b[1];
          if (x < y) return 1;
          if (y < x) return -1;
          return 0;
        });
        
        if (list.length > 10)
          list = list.slice(0, list.length - 10);

        return list.map(x => x[0]);
      }
    },
    
    watch: {
      value(newValue) {
        this.internalValue = this.value;
      }
    },
    
    mounted() {
      this.internalValue = this.value;
    },
  
    methods: {
      // called to blur the element
      blur() {
        this.$refs.input.blur();
      },
      
      // called after everything's been handled regarding bluring
      didBlur() {
        console.debug("autocomplete: emit(blur)");
        this.showAutocompleteList = false;
        this.$emit('blur', event);
      },
      
      didClickAccept() {
        console.log("Accept arbitrary contents");
        this.chooseArbitraryContents();
        this.inputFocus(false);
      },
      
      // inputFocus(didFocus, event) {
 //        event && event.stopPropagation();
 //
 //        if (didFocus) {
 //          this.showAutocompleteList = didFocus;
 //        }
 //        else {
 //          if (event && event.relatedTarget) {
 //            // Probably clicked.
 //            let parentRow = this.$refs.parentRow;
 //            let tmp = event.relatedTarget;
 //            let foundParent = false;
 //
 //            while (tmp) {
 //              if (tmp == parentRow) { foundParent=true; break; }
 //              tmp = tmp.parentElement;
 //            }
 //
 //            console.log(`autocomplete blur: from ${foundParent ? 'inside container' : 'outside container'}`, event.relatedTarget)
 //            if (foundParent) {
 //              // Clicked on something inside our container
 //            }
 //          }
 //          else {
 //            console.log("autocomplete unrelated blur", event);
 //            console.debug("autocomplete event", event);
 //            console.debug("autocomplete relatedTarg", event.relatedTarget);
 //          }
 //
 //          setTimeout(this.didBlur, 1);
 //        }
 //      },
 
         inputFocus(didFocus, event) {
           event && event.stopPropagation();
   
           if (didFocus) {
             this.showAutocompleteList = didFocus;
             return;
           }
           
           // Handle blur event
           this.didBlur();
           // setTimeout(this.didBlur, 10);
         },
    
      onKey(e) {
        switch (e.which) {
          case 38: // up
            console.log("autocomplete input up");
            e.preventDefault();
            if (this.selectedPrediction === undefined || this.selectedPrediction < 1) return;
            this.selectedPrediction--;
            console.log("selected ", this.selectedPrediction);
            break;
            
          case 40: // down
            console.log("autocomplete input down");
            e.preventDefault();
            if (this.selectedPrediction === undefined) {
              this.selectedPrediction = 0;
              return;
            }
            
            if (this.selectedPrediction >= this.autocompleteList.length-1) {
              return;
            }
            
            this.selectedPrediction++;
            console.log("selected ", this.selectedPrediction);
            break;
            
          case 13: // enter
            e.preventDefault();
            if (e.altKey) {
              // Force to whatever is typed.
              this.chooseArbitraryContents();
            }
            else {
              // Choose the first thing
              this.chooseAutocomplete(this.autocompleteList[this.selectedPrediction]);
            }
            break;

          default:
            this.selectedPrediction = 0;
            this.showAutocompleteList = true;
        }
      },
      
      onChange(e) {
        //suppress
        e.stopPropagation();
      },
      
      chooseArbitraryContents() {
        console.debug(`autocomplete: accepting arbitrary contents ${this.internalValue}`);
        this.chooseAutocomplete(this.internalValue);
      },
      
      chooseAutocomplete(value) {
        console.debug(`autocomplete: choose -- $emit(input, ${value})`);
        this.internalValue = value;
        this.$emit('input', value);
        this.$el.dispatchEvent(new Event('change', {target: this.$refs.input}));
        this.showAutocompleteList = false;
      },
      
      stringSimilarity(str1, str2, gramSize = 2) {
        let getNGrams = (s, len) => {
          s = ' '.repeat(len - 1) + s.toLowerCase() + ' '.repeat(len - 1);
          let v = new Array(s.length - len + 1);
          for (let i = 0; i < v.length; i++) {
            v[i] = s.slice(i, i + len);
          }
          return v;
        }

        if (!str1.length || !str2.length) { return 0.0; }

        //Order the strings by length so the order they're passed in doesn't matter 
        //and so the smaller string's ngrams are always the ones in the set
        let s1 = str1.length < str2.length ? str1 : str2;
        let s2 = str1.length < str2.length ? str2 : str1;

        let pairs1 = getNGrams(s1, gramSize);
        let pairs2 = getNGrams(s2, gramSize);
        let set = new Set(pairs1);

        let total = pairs2.length;
        let hits = 0;
        for (let item of pairs2) {
          if (set.delete(item)) {
            hits++;
          }
        }
        return hits / total;
      }
    }
  
  
  }
</script>