const synth = window.speechSynthesis;
let voices = [];
let announcements = [];

export function getVoices() {
  if (!voices.length) {
    voices = synth.getVoices().sort(function (a, b) {
      const aname = a.lang.toUpperCase();
      const bname = b.lang.toUpperCase();

      if (aname < bname) {
        return -1;
      } else if (aname == bname) {
        return 0;
      } else {
        return +1;
      }
    });
  }
  
  return voices;  
}


if (speechSynthesis.onvoiceschanged !== undefined) {
  speechSynthesis.onvoiceschanged = () => {
    voices = [];
    getVoices();
  };
}

export async function speak(phrase, voice=null) {
  return new Promise((resolve, reject) => {
    if (synth.speaking) {
      console.error("speechSynthesis.speaking");
      return reject("speechSynthesis.speaking");
    }
  
    if (!phrase || phrase === "") {
      return resolve();
    }

    const utterThis = new SpeechSynthesisUtterance(phrase);

    utterThis.onend = function (event) {
      console.log("SpeechSynthesisUtterance.onend");
      resolve(event);
    };

    utterThis.onerror = function (event) {
      console.error("SpeechSynthesisUtterance.onerror");
      reject(event)
    };

    const voice = getVoices().find(v => v.name.toLowerCase() == 'samantha') || getVoices()[0];
    utterThis.voice = voice;
    utterThis.pitch = 1;
    utterThis.rate = 0.75;
    synth.speak(utterThis);
  })
}
