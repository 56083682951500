<template>
  <progress class="bar" :max="max" :value="value"/>
</template>

<style lang="scss" scoped>
  progress.bar {
    vertical-align: middle;
  }
</style>

<script>
export default {
  props: {
    max:    { required: false, default: undefined},
    value:  { required: false, default: 0} // indeterminate
  },
  
}
</script>