
<article class="crossing-match-finish">
  <participant-select ref="participantSelect" v-model="selectedParticipant" :disable-selector="par => !par.ft"/>
  
  <hr>
  
  <div class="form-row">
    <label>Selected</label>
    <span>
      {{ selectedParticipant ? `${selectedParticipant.firstName} ${selectedParticipant.lastName}` : 'No Selection' }}
    </span>
    <span class="flexible-space" />
    <span v-if="derivedTimestamp" class="result">{{ dateFormatter.format(selectedParticipant.ft) }}</span>
  </div>

  <div class="form-row">
    <label>Adjust</label>
    <input v-model="adjustmentSeconds" type="range" min="-30" max="30" step="1">
    <span class="indicator">{{ adjustmentSeconds }} sec</span>
    <span class="flexible-space" />
    <span v-if="derivedTimestamp" class="result">{{ dateFormatter.format(derivedTimestamp) }}</span>
    <span v-else class="result no-records">-</span>
  </div>
</article>
