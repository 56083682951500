
<li class="warn">
  <h2>{{ title }}</h2>
  <p class="detail">
    {{ detail }}
  </p>
  <button
    class="clear"
    @click="clear"
  >
    x
  </button>
</li>
