
<article class="livetracking">
  <header class="sub">
    <h1 class="flexible-space">
      Live Tracking
    </h1>
    <loading-spinner v-if="livetrackingLoading" />
    <indicator v-else :fill="livetrackColor"/>
  </header>
  <form @submit.prevent>
    <div class="form-row">
      <label for="server">Server</label>
      <input v-model="livetrackingServer" class="lt-s wide" placeholder="timing.aravaiparunning.com">
    </div>
    
    <div class="form-row">
      <label for="username">User:</label>
      <input v-model="livetrackingUser" class="lt-u" type="text" name="username">

      <label for="username">Pass:</label>
      <input v-model="livetrackingPass" class="lt-p" type="password" name="password">
    </div>
  </form>
</article>
