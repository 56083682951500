
<li
  class="race-event-result"
  :class="`gen-${subject.gender}`"
  @click="handleClick"
  :title="`${subject.name}\n${subject.startTime}`"
>
  <figure class="im"><img :src="raceEventImageUrl" @error="raceEventImageError"/></figure>
  <span class="name">
    <span class="first-name">{{ subject.name }}</span>
  </span>
</li>
