<template>
  <tr :class="rowClass" :title="participant.notes">
    <td ref="op" class="place op">
      {{ overallPlaceDisplay }}
    </td>
    <td ref="gp" class="place gp">
      {{ participant.genderPlace || genderPlace || componentGenderPlace }}
    </td>
    <td ref="bib" class="bib" @click="$emit('click', $event)">
      <span :style="bibStyle">{{ participant.bib }}</span>
    </td>
    <td ref="ln" class="last-name" @click="$emit('click', $event)">
      {{ participant.lastName }}
    </td>
    <td ref="fn" class="first-name" @click="$emit('click', $event)">
      {{ participant.firstName }}
    </td>
    <td ref="gender" class="gender">
      {{ participant.gender }}
    </td>
    <td ref="age" class="age">
      {{ age }}
    </td>
    <td ref="race" class="race-name">
      {{ race.name }}
    </td>
    <td ref="wave" class="wave">
      {{ participant.waveId }}
    </td>
    <td ref="lastSplit" class="last-split">
      {{ lastSplitDisplay }}
    </td>
    <td ref="lastTime" class="race-time" :title="participant.ft">
      {{ timeDisplay }}
    </td>
    <td v-if="options.showTeamColumns" ref="teamName" class="team-name" :title="participant.teamName">
      {{ participant.teamName }}
    </td>
    <td ref="status" class="status">
      {{ statusToString(participant.status) }}
    </td>
  </tr>
</template>

<style lang="scss">
.pdr {
  margin: 0;
  line-height: 32px; 
  color: var(--text-dim1-color);
  
//  &:hover { background: var(--row-hover-bgcolor); }
        
  &.gen-F .gender { color: #ee0076; }
  &.gen-M .gender { color: #1d606c; }
      
  &.dns       { background: var(--row-dns-bgcolor) !important; color: var(--row-dns-color)!important; }
  &.dns:hover { background: var(--row-dns-hover-bgcolor)!important; color: inherit; }
  &.dnf       { background: var(--row-dnf-bgcolor)!important; color: var(--row-dnf-color)!important; }
  &.dnf:hover { background: var(--row-dnf-hover-bgcolor)!important; color: inherit; }
  &.dq        { background: var(--row-dq-bgcolor)!important; color: var(--row-dq-color)!important; }
  
  &.flag {
      .op:after {
        title: 'Check wave against start time';
        content: '!';
        display: inline-block;
        height: 1.1em;
        width: 1.1em;
        border-radius: 50%;
        vertical-align: middle;
        margin-left: 0.5em;
        color: #000;
        background: var(--error-color);
        text-align: center;
        line-height: 1em;
        font-weight: 900;
        text-shadow: 0 1px 1px #fff;
        box-shadow: 0 1px 1px black;
      }
      
      &.early         { .op:after { content: 'E'; } }
      &.late          { .op:after { content: 'L'; } }
      &.lap-mismatch  { .op:after { content: '-'; } }
      
  }
  
  &.has-note td:last-child:after    { 
    font-family: "Font Awesome 5 Free"; font-weight: 900;
    content: '\f249';
    position: absolute;
    margin-left: 10px;
    color: rgb(245, 237, 184);
    transform: rotate(10deg);
  }
  
  &>td {
    padding: 4px 4px;

    &.did-change {
      background: rgba(161, 89, 0, 0.5);
      transition: background-color 0s ease;
    }
    transition: background-color 0.25s ease;

    &:first-child {
      padding-left: 4px; 
    }

    &.bib { width: 50px; 
      cursor: pointer;
      span { 
        color: var(--light-color); 
        background:var(--light-bgcolor); 
        box-shadow: 0 2px 5px rgba(0,0,0,0.2);
        padding: 2px;
        border-radius: 4px; 
        font-weight: bold; 
        text-align: center;
        min-width: 2em;
        display: inline-block;
        line-height: 1.25em;
        
        min-height: 1em;
      }
    }
    
    &.first-name,
    &.last-name   { color: var(--text-color); cursor: pointer; }
    &.last-name   { font-weight: bold; }
    &.race-name   { white-space: nowrap; text-overflow: ellipsis; overflow:hidden;}

    &.wave        { padding-left: 1em;}
    &.gender      { font-weight: normal; }
    &.race-time   { text-align: right; }
  }
}

@media screen and (max-width:1300px) {
  .participants-grid {
    .first-name,
    .last-name,
    .last-name,
    .first-name,
    .race-name,
    .wave,
    .gender,
    .race-time { min-width: inherit !important; max-width: inherit;}
  }
}
</style>

<script>
  import ParticipantStatus from "@/lib/mixins/participant-status.js"
  
  export default {
    mixins: [ ParticipantStatus ],
    props: {
      options: {
        type: Object,
        required: false,
        default: () => ({})
      },
      
      raceEvent: {
        type: Object,
        required: false
      },
      
      participant: {
        type: Object,
        required: true
      },
      
      calculatePlacements: {
        type: Boolean,
        default: false
      },
      
      overallPlace: {
        type: Number,
        required: false,
      },
      
      genderPlace: {
        type: Number,
        required: false
      },
      
    },
    
    computed: {
      race()       { return this.$store.getters.races(this.participant.raceId) },
      raceSplits() { return this.$store.getters.entity('splits', this.race.splits).sort((a,b)=>a.distance-b.distance) },
      crossings()  { return this.$store.getters.entity('crossings', this.participant.crossings) },
      lastSplit()  { 
        const splitId = this.participant.lastSplitId;
        return splitId && this.$store.getters.entity('splits', splitId) || undefined
      },

      rowClass() {
        let klasses = [
          'pdr',
          'race' + this.participant.raceId, 
          'gen-' + this.participant.gender, 
          this.statusToString(this.participant.status).toLowerCase()
        ];
      
        if (this.participant.waveStartTime && this.participant.st) {
          const pst = this.participant.st.getTime() / 1000;
          const wst = this.participant.waveStartTime.getTime() / 1000;
          
          if (pst - wst < (0-(4 * 60 + 10))) {
            klasses.push('flag');
            klasses.push('early');
          }
          
          if (pst - wst > (0+(4 * 60 + 10))) {
            klasses.push('flag');
            klasses.push('late');
          }
        }
        
        /*
        if (this.participant.lapCount != 4) {
          klasses.push('flag');
          klasses.push('lap-mismatch');
        }
        */
        
        if (this.participant.notes)
          klasses.push('has-note');
        
        return klasses;
      },
      
      finishCrossing() { 
        if (!this.crossings || this.crossings.length < 1)
          return null;
          
        return this.crossings[this.crossings.length-1];
      },
      
      componentOverallPlace() {
        if (!this.calculatePlacements) return '-';
        return this.finishCrossing ? this.finishCrossing.overallPlace : '-';
      },
      
      componentGenderPlace() { 
        if (!this.calculatePlacements) return '-';
        return this.finishCrossing ? this.finishCrossing.genderPlace : '-';
      },
      
      age() {
        const par = this.participant;
        if (par.age) return par.age;
        if (par.dob) {
          // calc age from birthday
          const diff = new Date().setHours(0) - new Date(par.dob);
          return new Date(diff).getUTCFullYear() - 1970;
        }
        return undefined;
      },
      
      overallPlaceDisplay() {
        const s = this.participant.status;
        if (s === undefined || s === null || s === 0 || s === 11)
          return this.participant.overallPlace || this.overallPlace ||  this.componentOverallPlace;
        else
          return '-'
      },
      
      timeDisplay() {
        if (this.raceEvent?.posIngressType == 'trackleadersxml') {
          // Get distance field from last known position
          const coords = this.participant.lastLoc?.geometry?.coordinates;
          if (coords) {
            const distance_m = coords[3];
            return this.$options.filters.mToDistance(distance_m, {units: true});
          }
        }
        
        if (this.race.isTimed) {
					// Maybe haven't done a full lap yet.
					const splitId = this.finishCrossing?.splitId;
					if (!splitId) return 0;
					
					// Compare against loop split
          const loopSplit = this.raceSplits[this.raceSplits.length-1];
          // const lastSplit = this.$store.getters.entity('splits', splitId);
          const lastCrossing = this.crossings[this.crossings.length-1];
					let lapCount = lastCrossing.loopId
          
					// If they've just completed a lap, present the zero-indexed lapCount
          if (lastCrossing && lastCrossing.splitId == loopSplit.id)
						lapCount++;

					return lapCount;
        }
        else
        if (this.race.isLoop) {
          const lastSeenAt = this.participant.lastSeenAt;
          if (!lastSeenAt) return '-';
          const st = this.participant.waveStartTime || this.race.startTime;
          return this.timeString(lastSeenAt - st)
        }
        
        const st = this.participant.waveStartTime || this.race.startTime;
        
        if (this.participant.ft) {
          return this.timeString(this.participant.ft - st);
        }
        
        if (this.participant.lastSeenAt) {
          return this.timeString(this.participant.lastSeenAt - st);
        }
        
        return '-';
      },
      
      lastSplitDisplay() {
        if (this.race.isLoop && !this.race.isTimed) {
          let startDist = this.raceSplits[0].distance;
          let lapId = this.participant.lapCount;
          if (this.participant.ft) {
            lapId++;
          }

          const loopSplit = this.raceSplits[this.raceSplits.length-1];
          const dist = lapId * loopSplit.distance + startDist;
          
          if (!lapId) {
            return '0';
          }
          
          return `${lapId} / ${this.$options.filters.mToDistance(dist, {units: true})}`
        }

        return this.lastSplit && this.lastSplit.name || '-';
      },
      
      bibStyle() {
        return `border-top: 8px solid ${this.race.color}; padding-top: 0px`;
      }
    },
    
    watch: {
      'participant.overallPlace': function(newValue) { 
        this.flashUpdate(this.$refs.op); 
      },
      'participant.genderPlace': function(newValue) { 
        this.flashUpdate(this.$refs.gp); 
      },
      'participant.lastSeenAt': function(newValue) { 
        this.flashUpdate(this.$refs.lastTime);
        this.flashUpdate(this.$refs.lastSplit);
      },
      'participant.lastSplitId': function(newValue) { 
        this.flashUpdate(this.$refs.lastTime);
        this.flashUpdate(this.$refs.lastSplit);
      },

    },
    
    methods: {
      
      flashUpdate(elm) {
        if (!elm) return;
        elm.classList.add('did-change');
        setTimeout(()=> {
          elm.classList.remove('did-change');
          elm.classList.add('change-done');
          setTimeout(()=> {
            elm.classList.remove('change-done');
          }, 50);
        }, 50);
      },

      
      timeString(dur, showMs=false) {
        if (dur === undefined)
          return '-';
        
        // sigh. @TODO: see if momentjs is worthwhile. Maybe countdown.js ?
        // dur is in milliseconds.
        let neg = dur < 0;
        if (neg) dur *= -1;
        
        
        const h = Math.floor(dur / 3600000); 
        dur -= h * 3600000;
        
        const min = Math.floor(dur / 60000);
        dur -= min * 60000;
        
        const sec = Math.floor(dur / 1000);
        dur -= sec * 1000;
        
        const ms = dur;
        
        let pad = (num, width) => num < 100 ? ('0000'+num).slice(width*-1) : num;
        let s = `${neg ? '-':''}${h==0?'':h+':'}${pad(min,2)}:${pad(sec,2)}`;
        if (showMs)
          s += '.' + pad(ms,3)
        return s;
      },
            
    }
  }
</script>