import haversine from 'haversine-distance'

const COORD_LON = 0;
const COORD_LAT = 1;
const COORD_Z = 2;
const COORD_M = 3;

export function processLinestring(feature) {
  console.debug("Add Linestring M", feature);
  
  // geometry = feature.geometry;
  let coords = feature.geometry.coordinates;

  let lastPoint = {lon: undefined, lat: undefined};
  let lastZ, zDelta, ascent=0, descent=0;
  let minZ = Infinity, maxZ = -Infinity;
  let dist = 0;
  let c;
  
  for (let i = 0; i < coords.length; i++) {
    c = coords[i];
    
    if (!lastPoint.lat) {
      c[COORD_M] = 0;
    }
    else {
      // calculate haversine distance to this point.
      dist += haversine(lastPoint, {
        lon: c[COORD_LON],
        lat: c[COORD_LAT]
      })
      
      // Acumulate
      c[COORD_M] = dist;
    }
    
    // Also measure the elevation bounds
    //
    if (c[COORD_Z] > maxZ) maxZ = c[COORD_Z];
    if (c[COORD_Z] < minZ) minZ = c[COORD_Z];
    
    // Measure the accumulated distance
    // This filters out small elevation changes.
    //
    if (lastZ === undefined) {
      lastZ = c[COORD_Z];
    }
    else {
      zDelta = c[COORD_Z] - lastZ;
      
      if (Math.abs(zDelta) > 4) {
        if (zDelta > 0) {
          ascent += zDelta;
        }
        else  {
          descent += -zDelta;
        }        

        lastZ = c[COORD_Z]
      }
    }

    lastPoint.lon = c[COORD_LON];
    lastPoint.lat = c[COORD_LAT];
  }
  
  return {
    maxZ, 
    minZ,
    ascent,
    descent,
    distance: dist
  }
}

export function processWaypoints(gj, linestringFeature) {

  // Pseudo GeoJSON representations of the available waypoints.
  // these will be duplicated as needed with appropriate M coordinates
  let waypoints = [];
  let detectedWaypoints = [];
  
  for (let i = gj.features.length - 1; i >= 0; i--) {
    let wp = gj.features[i];
    if (wp.geometry.type !== 'Point') continue;
    
    waypoints.push({
      index: i,
      coordinates: wp.geometry.coordinates
    });
  }

  const waypointProxEnter = (wp, dist, m) => {
    const o = gj.features[wp.index]
    wp.closePass = dist;
    wp.closeM = m;
  }
  
  const waypointProxUpdate = (wp, dist, m)=> {
    if (dist < wp.closePass) {
      wp.closePass = dist;
      wp.closeM = m;
      const o = gj.features[wp.index]
    }
  }
  
  const waypointProxLeave = (wp, dist, m) => {
    const o = gj.features[wp.index]

    console.debug(`Waypoint <= ${wp.index} "${o.properties.name} located to ${wp.closeM} (left at ${m}m)`);
    
    // Back to GeoJSON waypoints
    // const splitWaypoint = Object.assign({}, o);
    const splitWaypoint = JSON.parse(JSON.stringify(o));
    splitWaypoint.geometry.coordinates[COORD_M] = wp.closeM;
    detectedWaypoints.push(splitWaypoint);
    
    delete wp.closePass;
    delete wp.closeM;
  }
  
  // For each point in the linestring, find which waypoints might be an aid station there.
  // We can't iterate waypoints and looks for close coords because a waypoint might be
  // hit multiple times in a track. 
  //
  const coords = linestringFeature.geometry.coordinates;
  let lastM=-Infinity; // Simple hack to let the first coord match a waypoint
  let dist;
  for (let i = 0; i < coords.length; i++) {
    let c = coords[i];
    
    // if (Math.abs(c[COORD_M] - lastM) < 50)
    //   continue;
    
    lastM = c[COORD_M];

    for (let j = 0; j < waypoints.length; j++) {
      let wp = waypoints[j];
      let wpc = wp.coordinates;
      
      dist = haversine( {lon: c[COORD_LON], lat: c[COORD_LAT]},  {lon: wpc[COORD_LON], lat: wpc[COORD_LAT]} )
      
      if (dist < 80) {
        if (!wp.closePass) {
          waypointProxEnter(wp, dist, c[COORD_M]);
        }
        else {
          waypointProxUpdate(wp, dist, c[COORD_M]);
        }
      }
      else {
        if (wp.closePass) {
          waypointProxLeave(wp, dist, c[COORD_M]);
        }
      }
    }
  }
  
  // Check if we haven't left any waypoints in proximity
  for (let j = 0; j < waypoints.length; j++) {
    let wp = waypoints[j];    
    if (wp.closePass) {
      waypointProxLeave(wp, 0, dist);
    }
  }
  
  // Replace all the waypoints with the produced ones.
  waypoints.forEach(wp => gj.features.splice(wp.index, 1));
  detectedWaypoints.forEach(wp => gj.features.push(wp));
  
  return gj
}

// Create and merge splits from waypoints in a GeoJSON FeatureCollection
// Returns a payload that can be sent to a Vuex store
//
// 1. Merge waypoints into splits.
// 2. Splits that are left over are simply added.

export function mergeSplitsWithGeoJSON(splits, gj, handleMatch) {
  
  const getSplitMatchingDistance = (dist) => {
    console.group(`Splits matching ${dist}`);
    const sortedSplits = [...splits].sort((a,b) => {
      return Math.abs(a.distance - dist) - Math.abs(b.distance - dist);
    });
    
    sortedSplits.forEach(s=> console.log(`${s.name} @ ${s.distance} Δ ${Math.abs(s.distance - dist)}`));
    
    let match = sortedSplits[0];
    let delta = match ? Math.abs(match.distance - dist) : -1;
    if (!match || delta > 300) {
      if (!match) console.log("no match");
      else console.log(`delta too large (${delta})`);
      console.groupEnd();
      return null;
    }
    
    console.log(`Matched dist ${dist} to split ${match.name} (delta ${delta})`);
    console.groupEnd();
    return match;
  }
  
  if (gj.type !== 'FeatureCollection')
    return console.error(`Cannot create splits from GeoJSON type ${gj.type}`);
  
  let features = [...gj.features];
  let feature;
  for (let i = 0; i < features.length; i++) {
    feature = features[i];
    
    if (feature.geometry.type !== 'Point')
      continue;
    
    let split = getSplitMatchingDistance(feature.geometry.coordinates[COORD_M]);
    handleMatch(split, feature);
  }
}

export function coordsForDistance(linestringFeature, distance, interpolate=true) {
  let coords = linestringFeature?.geometry?.coordinates;
  return pointForDistance(coords, distance, interpolate);
}
  
export function pointForDistance(coords, distance, interpolate=true) {
  if (!coords)
    return undefined;
  
  var head = 0;
  var tail = coords.length-1;
  // console.log("Searching %d points in ", tail, coords);
  
  while (tail - head > 1) {
    const search = Math.floor((tail - head) / 2) + head;
    // console.log("searching from %d to %d at %d", head, tail, search);
    var c = coords[search];
  
    if (c[COORD_M] < distance) // search the top half
      head = search;
    else
    if (c[COORD_M] > distance) // search the bottom 
      tail = search
    else
      return c;
  }

  // If we got here, we kind of ran out of points
  //
  if (!interpolate) 
    return coords[tail];
    
  const h = coords[head], t = coords[tail];
  
  // Sanity check
  if (!h) console.error("No head for dist", distance);
  if (!t) console.error("No tail for dist", distance);
  
  // Check if we're close enough
  // Not sure how close of a point to accept, especially if the interpolation is fast enough
  //
  if (Math.abs(h[3] - distance) < 2) {return h; }
  if (Math.abs(t[3] - distance) < 2) {return t; }

  // Sanity check the ends we've arrived at
  if (h[3] > distance || t[3] < distance) {
    //console.error(`Range out of bounds looking for point at ${distance.toFixed(2)} between ${h[3].toFixed(2)}-${t[3].toFixed(2)}`);

    // Attempt to be the least crazy?
    if (t[3] < distance) return t; // Probably asked for a point after the end
    return h // Probably asked for a point before the start
  }
  

  // Just interpolate head and tail positions.
  //
  // console.warn("Point not found for %.2f, head %.2f tail %.2f", distance, coords[head][3], coords[tail][3]);

  // How far between the points are we?
  const difference = t[3] - h[3]
  const percentage = (distance - h[3]) / difference
  
  var c = coords[head].slice(); // copy head coordinates
  c[0] += (t[0] - h[0]) * percentage; 
  c[1] += (t[1] - h[1]) * percentage;
  c[2] += (t[2] - h[2]) * percentage;
  c[3] += (t[3] - h[3]) * percentage;
  return c;
}
