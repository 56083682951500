
  <div class="search-wrap">
    <input ref="searchbar" :disabled="disabled" :key="'main-search'" v-model="q" type="search" :class="{main: true, 'results-shown': showResults}" :autosave="autosaveKey" @focus="focus" @blur="blur" @keyup="onkey" spellcheck="off" autocomplete="off" autocorrect="off" autocapitalize="off">
    <button v-if="q" key="search-clear" class="clear" @click="clear">
      Clear
    </button>
    <ul v-if="showResults" key="search-results" class="results">
      <li v-if="results.length < 1" class="no-results">
        No Results
      </li>
      <component :is="result.component" v-for="(result, i) in results" v-else :key="i" 
        :subject="result.subject" 
        :class="{selected: selectedResult==result}" 
        @resultClick="onResultClick"
        />
    </ul>
    <div v-if="showResults" key="search-modal-cover" class="modal-cover" @click="showResults=false">
&nbsp;
    </div>
  </div>
