
<div class="race-event-summary">
  <header class="sub">
    <h1>Event Summary</h1>
  </header>
  <ul>
    <li v-for="race in races" :class="classNameForRace(race)">
      <span class="race-name">{{ race.name }}</span>
      <span class="race-start">{{ startTimeStringForRace(race) }}</span>
    </li>
  </ul>
</div>
