import { schema } from 'normalizr'


const split         = new schema.Entity('splits');
const eventRoute    = new schema.Entity('eventRoutes');
const participant   = new schema.Entity('participants');
const race          = new schema.Entity('races');
const raceEvent     = new schema.Entity('raceEvents');
const crossing      = new schema.Entity('crossings');
const eventSource   = new schema.Entity('eventSources');
const notification  = new schema.Entity('notifications');
const addon         = new schema.Entity('addons');
const variant       = new schema.Entity('variants');
const lineItem      = new schema.Entity('lineItems');


split.define({
  race: race,
  eventRoutes: [eventRoute],
  crossings: [crossing]
});

participant.define({
  race: race,
  raceEvent: raceEvent,
  crossings: [crossing]
});

race.define({
  raceEvent: raceEvent,
  participants: [participant],
  splits: [split],
  eventRoutes: [eventRoute],
});

raceEvent.define({
  races: [race],
  participants: [participant]
});

crossing.define({
  participant: participant,
  split: split,
});

eventRoute.define({
  split: split,
  eventSource: eventSource
});

eventSource.define({
  eventRoutes: [eventRoute]
});

notification.define({})

addon.define({
  raceEvent: raceEvent,
  variants: [variant]
});

variant.define({
  addon: addon,
});

lineItem.define({
  addon: addon,
  variant: variant,
  participant: participant
});

/* *********************************************** */


function singularize(entityName) {
  if (undefined === entityName || null === entityName) 
    return entityName;
  
  if (entityName[entityName.length-1] != 's')
    return entityName;

  return entityName.substr(0, entityName.length-1);
}

function pluralize(entityName) {
  if (undefined === entityName || null === entityName) 
    return entityName;

  if (entityName[entityName.length-1] == 's')
    return entityName;
  
  return entityName + 's';
}

// Find all instances of `entity` in `schema`
// schema will be a hash, found from EntitySchema.schema, that looks like this:
// {
//    split: EntitySchema
//    imaginaryThings: [EntitySchema]
// }
//
// Find this relation entity's relation that reflects our schema.
// i.e. Find this split's eventRoute:EntitySchema or eventRoutes:[EntitySchema] relationship
// so that we can know if we're nulling or splicing out our eventRoute's ID
//
// inSchema will be e.g. splits
//
function reflect(entity, relationEntity) {
  //console.debug("Looking for relkey for %s relation %s", entity._key, relationEntity._key);

  let isToMany = false;
  const relKey = Object.keys(relationEntity.schema).find(backRel => {
    // Iterating through each relation that the relation has, to find
    // the original entity.
    // 
    // Find the schema that matches the schema of the thing we're
    // actually trying to delete
    // e.g. splits.schema.eventRoutes = [EntitySchema] (array indicates plural)
    //
    const backSchema = relationEntity.schema[backRel];
    // console.debug("Checking %s with schema", backRel, backSchema);
    // console.debug("-> isArray:", Array.isArray(backSchema));
    
    if (Array.isArray(backSchema) && backSchema[0] == entity) {
      //console.debug("-> matches ", backSchema[0], entity, backSchema[0] == entity);
      if (backSchema[0] == entity) {
        isToMany = true;
        return true;
      }

      return false;
    }
    else {
      // console.debug("-> matches ", backSchema, entity, backSchema == entity);
      return backSchema == entity;
    }
  });
  
  if (!relKey) {
    console.warn(`-> No reflection for ${entity._key} found`);
    return {};
  }
  
  const result = {
    isToMany,
    relationKey: relKey
  }
  
//  console.debug("-> Found reflection:", result);
  return result;
}

/* *********************************************** */


export default {
  pluralize, singularize, reflect,
  
  raceEvent,
  race,
  split,
  participant,
  eventSource,
  eventRoute,
  crossing,
  notification,
  addon,
  variant,
  lineItem,
}