<template>
  <div class="status">
    <indicator :size=8 :fill="websocketColor" class="status-indicator" />
    <span class="item" :title="websocketConnected ? 'Connected to Momentum Cloud' : 'Disconnected from Momentum Cloud'">{{ websocketConnected ? "Connected" : "Offline" }}</span>
    <indicator :size=8 :fill="configColor" class="status-indicator" />
    <span class="item" :title="Object.values(configStatus.reasons).join('\n')">Config</span>
  </div>
</template>

<style lang="scss" scoped>
  .status {
    height: 100%;
    display: grid;
    grid-template-columns: 1.5ch 1fr;
    align-items: center;
    white-space: nowrap;
    font-size: 13px;
    
    .status-indicator { }
    .item { margin: 0 4px; }
  }
  
  @media screen and (max-width: 437px){
    .status { 
      flex-direction: column; 
      justify-content: center;
      height: 100%;
      .item { display: none; }
      .status-indicator { margin-left: 0 !important; grid-column: 1;}
    }
  }
  
</style>

<script>
  import Indicator from "@/components/indicator.vue"
  export default {
    
    components: {
      Indicator,
    },
    
    
    props: {
      errorColor: {
        type: String,
        default: '#BE0000'
      },
      warnColor: {
        type: String,
        default: 'rgb(255, 266, 60)' //yellow
      },
      goodColor: {
        type: String,
        default: 'rgb(129, 210, 82)'
      },
      noColor: {
        type: String,
        default: '#ccc'
      }
    },
    
    data() {
      return {
        livetrackReasons: {},
      }
    },
      
    computed: {
      
      activeRaceEvent() { 
        return this.$store.state.activeRaceEvent; 
      },

      // All races in this raceEvent
      races() {
        const getEntity = this.$store.getters.entity;
        const raceIds = this.activeRaceEvent && this.activeRaceEvent.races || [];
        return getEntity('races', raceIds);
      },

      // All splits in this raceEvent
      splits()  { 
        return this.races && this.$store.getters.entity('splits', this.races.flatMap(r=>r.splits)) 
      },

      // All event sources in the entire raceEvent
      eventRoutes()     { 
        return !this.splits ? [] : this.$store.getters.entity('eventRoutes', this.splits.flatMap(s=> s ? s.eventRoutes : []))
      },
      
      eventSources()    { 
        if (!this.eventRoutes)
          return [];
        let s = new Set();
        this.eventRoutes.forEach(r=> r && s.add(r.eventSourceId));
        return !this.eventRoutes ? [] : this.$store.getters.entity('eventSources', [...s]) 
      },

      connectedEventSources() {
        return this.eventSources.filter(es=> es && es.status=="connected")
      },

      disconnectedEventSources() {
        return this.eventSources.filter(es=> es && es.status!="connected")
      },

      eventSourcesColor() {
        if (!this.activeRaceEvent) 
          return this.noColor;

        const connectedCount = this.connectedEventSources.length;
        if (connectedCount == 0) return this.errorColor;
        if (connectedCount == this.eventSources.length) return this.goodColor;

        return this.warnColor;
      },
      
      websocketConnected() {
        return this.$store.state.eventChannelConnected
      },
      
      websocketColor() {
        if (this.websocketConnected) {
          return this.goodColor;
        }

        return this.errorColor;
      },
      
      configStatus() {
        let count = 0;
        let fail = false;
        let reasons = {};
        const getEntity = this.$store.getters.entity;
        this.races.forEach(race => {
          // All start times defined
          if (!race.startTime) {
            let key = `startTime-${race.id}`;
            reasons[key] = `No start time for ${race.name}`
          }
          
          // All races have start splits
          const splits = getEntity('splits', race.splits);
          const start = splits.find(s => s.distance < 1);
          if (!start)  {
            reasons[`startSplit-${race.id}`] = `No zero-distance (start ) split for ${race.name}`
          }
          count++;
          
          // All races have finish splits within 10% of the finish
          const finish = splits.find(s => Math.abs(race.distance - s.distance) <  (race.distance * 0.1));
          if (!finish)  {
            reasons[`finishSplit-${race.id}`] = `No finish split for ${race.name}`
            fail = true;
          }
          else {
            // Check for a finish split
            if (finish.eventRoutes?.length < 1) {
              reasons[`finishSplit-${race.id}-er`] = `No finish event route for ${race.name}`
              fail = true;
            }
          }
        
          count++;
        });
        
        return {
          fail,
          count,
          reasons
        }
      },
      
      configColor() {
        const badnessLevel = Object.keys(this.configStatus.reasons).length;
        if (this.configStatus.fail)
          return this.errorColor;
        
        if (badnessLevel == 0) 
          return this.goodColor;
        else if (badnessLevel < this.configStatus.count)
          return this.warnColor;
        else
          return this.errorColor;
      },
      
      livetrackColor() {
        const lastHB = this.$store.state.livetrackLastHB;
        const delta = (new Date()) - lastHB ;
        
        if (delta > 15000)
          return this.errorColor;
        else
          return this.goodColor;
      },
      
    }
  }
</script>