
<article class="wave-assignment">
  <loading-spinner v-if="loading" />
  <header class="sub">
    <h2>Wave Planner</h2>
  </header>
  <section class="content-wrap">
    <form v-on:submit.prevent> 
      <fieldset>
        <div class="form-row">
          <label>Race</label>
          <select v-model="selectedRace">
            <option disabled>Choose a Race</option>
            <option v-for="race in races" :value="race">{{ race.name }}</option>
          </select>
        </div>
      </fieldset>          
      <fieldset>
        <div class="form-row">
          <label for="elite-waves">Create Waves</label>
          <input type="checkbox" v-model="autoCreateWaves"/>
        </div>
        
        <div class="form-row">
          <label for="separation">Spacing</label>
          <input type="number" step="1" v-model="maxPerWave" class="inline mini" />
          <span> / </span>
          <input type="number" step="1" v-model="waveSpacingMinutes" class="inline mini" /><span class="unit">min</span>
        </div>
          
        <div class="form-row">
          <label for="sort-attr">Sort By</label>
          <select v-model="sortField" title="Choose a Sort Method">
            <option disabled>Choose a Sort Method</option>
            <option v-for="name, field in sortFields" :value="field">{{ name }}</option>
          </select>

          <div v-if="sortField=='ultraSignupRanking'">
            <loading-spinner v-if="loadingRankings" class="tiny-loading" @click="loadingRankings = false"/>
            <span class="status okay" v-else-if="raceEvent && raceEvent.usRankings">✓</span>
            <span class="status err" v-else>⛔️</span>
          </div>
        </div>
        <div class="form-row" v-if="sortField=='ultraSignupRanking'">
          <label for="us-did">UltraSignup dID</label>
          <input type="number" v-model="usdid" :disabled="!this.selectedRace"/>
          <button @click="fetchRankings" class="mini" :disabled="!usdid">Fetch</button>
        </div>

        <div class="form-row">
          <label for="group-attribute">Group By Attribute</label>
          <div class="select-wrapper">
            <select v-model="groupByAttribute" placeholder="None">
              <option :value="undefined">None</option>
              <optgroup label="──────────"></optgroup>
              <option v-for="k in availableKeys" :value="k">{{k}}</option>
            </select>
          </div>
        </div>
        
        <div class="form-row">
          <label for="gender-weight">Time Pref Weight</label>
          <input type="range" min="0" max="1" step="0.001" v-model="wavePrefWeight" class="inline"/>
          <span class="note freeze" @click="wavePrefWeight=0">{{ parseFloat(wavePrefWeight).toFixed(2) }}</span>
        </div>
        
        <div class="form-row">
          <label for="gender-weight">Male/Female Weight</label>
          <input type="range" min="0.25" max="0.75" step="0.001" v-model="genderWeight" class="inline"/>
          <span class="note freeze" @click="genderWeight=0.5">{{ ((0.5 + parseFloat(genderWeight)) - 1.0).toFixed(2) }}</span>
        </div>
        
        <div class="form-row">
          <label for="first-wave-cap">First Wave Capacity</label>
          <input type="checkbox" v-model="firstWaveCapControl" />
          <input type="number" v-model="firstWaveCapacity" class="mini" :disabled="!this.firstWaveCapControl" :placeholder="this.maxPerWave"/>
        </div>
        
        
        <div class="form-row">
          <label for="weight-wave-first">Only Weight Leading</label>
          <input type="checkbox" v-model="genderWeightOnlyFirst"/>
          <label for="weight-wave-count">Count</label>
          <input type="number" v-model="genderWeightCount" :disabled="!genderWeightOnlyFirst" class="mini" />
        </div>
        <div class="form-row">
          <label for="elite-waves">Elite Waves</label>
          <input type="checkbox" v-model="eliteWaves"/>
        </div>
        <div class="form-row" v-if="eliteWaves">
          <table>
            <thead><tr><th></th><th>Men</th><th>Women</th></tr></thead>
            <tbody>
              <tr>
                <th>Athletes</th>
                <td><input type="number" class="mini" v-model="eliteSize.M" step="1"/></td>
                <td><input type="number" class="mini" v-model="eliteSize.F" step="1"/></td>
              </tr>
              <tr>
                <th>Waves</th>
                <td><input type="number" class="mini" v-model="mensWaveCount" step="1"/></td>
                <td><input type="number" class="mini" v-model="womensWaveCount" step="1"/></td>
              </tr>
            </tbody>
          </table>
        </div>
      </fieldset>
    </form>
    
    <div class="listings">
      <ul class="tabs">
        <li :class="{selected: show=='participants'}" @click="show='participants'">Participants</li>
        <li :class="{selected: show=='waves'}" @click="show='waves'">Waves</li>
      </ul>

      <div v-if="show=='participants'" class="flex-scroller-wrapper inset-box">
        <div class="flex-scroller par-table">
          <table>
            <thead>
              <tr>
                <th>Last</th>
                <th>First</th>
                <th>Gen</th>
                <th>Rank</th>
                <th>Adjusted</th>
                <th>Wave</th>
                <th v-if="groupByAttribute">{{groupByAttribute}}</th>
                <th v-else>{{sortField}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="p in displaySortedParticipants" :class="rowClassForPar(p)" @contextmenu="showWeightMenu(p, $event)">
                <td class="last-name">{{ p.lastName }}</td>
                <td class="first-name">{{ p.firstName }}</td>
                <td :class="['gender', 'gen-'+p.gender]">{{p.gender}}</td>
                <td class="ranking">{{ (rankingForPar(p, true) * 100).toFixed(1) }}</td>
                <td class="adj ranking" :class="{manual: !!p.waveWeight }">{{ (adjustedRankingForPar(p) * 100).toFixed(1) }}</td>
                <td class="wave">{{ waveForPar(p) }}</td>
                <td v-if="groupByAttribute">{{ p[groupByAttribute] }}</td>
                <td v-else>{{ p[sortField] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      
      <div v-if="show=='waves'" class="flex-scroller-wrapper inset-box">
        <div class="flex-scroller">
          <table>
            <thead>
              <tr>
                <th>Number</th>
                <th>Time</th>
                <th>Count</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="w in waves">
                <td class="w-no">{{ w.id }}</td>
                <td class="w-tm">{{ fmttime(w.time) }}</td>
                <!-- <td class="w-tm">
                  <input type="datetime-local" v-bind-date="w.time" />
                </td> -->
                <!-- <td class="w-tm">{{ w.time }}</td> -->
                <td class="w-ct">{{ w.participants ? w.participants.length : '-' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      
      <footer>
        <label>Average Ranking</label>
        <span class="note">{{ averageRanking }}</span>
      </footer>
      
    </div>
  </section>
</article>
