export default {
  methods: {
    distanceDisplay(meters) {
      const app = this.$root.$children[0];
      if (app.wantsMiles) {
        return `${(meters / 1609.344).toFixed(2)} mi`
      }
      else {
        return `${(meters / 1000).toFixed(2)} km`
      }

      return `${meters}m`
    },
  }
}