
<article class="reassigner">
  <header class="sub">
    <h1>Reassign Crossings</h1>
    <i class="flexible-space" />
    <input v-model="searchQuery" type="search">
  </header>
  
  <header class="crossings">
    <h2>Crossings:</h2> 
    <ul class="crossings">
      <li v-for="c in crossings">
        {{ c.tag }} at {{ c.timestamp }}
      </li>
    </ul>
  </header>
  
  <table class="lookup">
    <thead>
      <tr>
        <th class="bib">
          Bib
        </th>
        <th class="last-name">
          Last
        </th>
        <th class="first-name">
          First
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="par in participants" :class="{selected: par===selectedParticipant, 'lap':true}" @click="selectedParticipant=par">
        <td>{{ par.bib }}</td>
        <td>{{ par.lastName }}</td>
        <td>{{ par.firstName }}</td>
      </tr>
    </tbody>
  </table>
</article>
