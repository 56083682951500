<template>
  <li class="warn">
    <h2>{{ title }}</h2>
    <p class="detail">
      {{ detail }}
    </p>
    <button
      class="clear"
      @click="clear"
    >
      x
    </button>
  </li>
</template>

<style lang="scss" scoped>
li {
  text-align: left;
  background: var(--app-bgcolor);
  border-radius: var(--br);
  position: relative;
  padding-left: 50px !important;
  
  &:before {
    content: '\f071';
    font: normal normal 900 24px 'Font Awesome 5 free';
    display: inline-block;
    color: var(--error-color);
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  h2 {
    display: inline-block;
    font-size: 17px;
    text-align: left;
    line-height: 18px;
    margin-bottom: 0;
    margin-right: 0.5em;
  }
  
  .detail {
    margin: 0;
    color: var(--text-dim1-color);
  }
  
  .clear {
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    line-height: 1em;
    box-shadow: inset 0 0 3px rgba(128,128,128,1);
    text-shadow: 0 -1px 1px var(--text-dim2-color);
    font-weight: bold;
    
  }
}
</style>

<script>
export default {
  props: {
    title:  { type: String, required: true },
    detail: { type: String, required: true },
    action: { type: String, required: false},
    actionTitle: { type: String, required: false},
  },
  
  methods: {
    clear() {
      this.$emit('clear', this);
    }
  }
}
</script>