
  <article class="root" @dragenter="dragenter" @dragover="dragover" @dragleave="dragleave" @drop="dragdrop" :class="{droptarget: isDragging}">
    <input ref="fileInput" type="file" v-bind="fileInputValue" accept="text/csv" style="display:none;" @change="handleFileInputChange">
    <header class="sub">
      <h1 class="flexible-space">
        Import / Update Participants
      </h1>
      <span class="preset">
        <label>Preset</label>
        <select v-model="selectedPresetName" class="preset">
          <option :value="null">Defaults for Event</option>
          <option v-for="p in Object.keys(presets)">{{ p }}</option>
        </select>
        <button class="mini bar" @click="showPresetOptions"></button>
      </span>
    </header>
    
    <div class="always">
      <button @click="askForFile">
        Choose File
      </button>
      <input ref="fileInput" type="file" accept="text/csv" style="display:none;" @change="handleFileInputChange">

      <div v-if="!hasFile">
        Please Choose a CSV File to Import
      </div>
    </div>


    <div v-if="!!error" class="error">
      {{ error }}
    </div>
    
    <ul class="tabs">
      <li :class="{selected: selectedTab=='columns'}" @click="selectedTab='columns'">
        Columns
      </li>
      <li :class="{selected: selectedTab=='addons'}" @click="selectedTab='addons'">
        Add-Ons
      </li>
    </ul>


    <div v-if="selectedTab=='columns'" class="import-columns flex-vertical">
      <div class="inset-box options">
        <h4>{{ fileName }}</h4>
        <div class="form-row">
          <label for="allow_bib_changes">Allow Bib Changes</label>
          <toggle-switch id="allow_bib_changes" v-model="upsertOptions.allowBibChanges" />
        </div>
        <div class="form-row">
          <label for="force_race">Race Assignment</label>
          <div class="select-wrapper">
            <select id="force_race" v-model="upsertOptions.overrides.raceId">
              <option :value="false">
                Use value from file
              </option>
              <optgroup label="──────────" />
              <option value="infer">
                Infer Distance
              </option>
              <optgroup label="──────────" />
              <option v-if="races.length<1" disabled>
                No Available Races
              </option>
              <option v-for="race in races" v-else :value="race.id">
                {{ race.name }}
              </option>
            </select>
          </div>
          <p class="note">
            {{ raceAssignmentExplanation }}
          </p>
        </div>
      </div>
      <div class="flex-scroller-wrapper">
        <div class="flex-scroller">
          <table>
            <thead>
              <tr>
                <th v-for="(col, i) in columns" :title="`${col}${isDuplicateColumnChoice(i) ? ' (Duplicate)' : ''}`" 
                  :class="{ignore: '$ignore$' == columnChoices[i], addon: '$addon$' == columnChoices[i]}"
                >
                  <span class="given" :class="{duplicate: isDuplicateColumnChoice(i)}">{{ col }}</span>
                  <div class="select-wrapper">
                    <select v-model="columnChoices[i]" class="mini">
                      <option value="$ignore$">
                        Ignore
                      </option>
                      <option value="$addon$">
                        Add On
                      </option>
                      <option v-for="k in possibleColumns" :value="k">
                        {{ k }}
                      </option>
                    </select>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody class="alternate-rows">
              <tr v-for="row in this.previewRows">
                <td v-for="(col, i) in columns" :class="{ignore: '$ignore$' == columnChoices[i]}">
                  {{ row[i] }}
                </td>
              </tr>
              <tr v-if="rowCount > 5" class="note">
                <td colspan="100%">
                  And {{ rowCount - 5 }} more…
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div v-if="selectedTab=='addons'" class="addons flex-columns">
      <section class="add-on-definitions varlist-wrapper active-elms">
        <header class="sub" title="Add-ons to Connect to Participants">
          <h1 class="flexible-space">
            Add-Ons
          </h1>
        </header>
        <ul>
          <li v-for="def in addOnDefs" :class="{selected: def == selectedAddOnDef}" @click="setSelectedAddOnDef(def)">
            {{ def.name }}
          </li>
        </ul>
        <footer class="control">
          <button class="add bar" :disabled="false" title="New Add-On Definition" @click="addAddOnDef">
            Add
          </button>
          <button class="del bar" :disabled="false" title="Delete Add-On Definition" @click="removeSelectedAddOnDef">
            Delete
          </button>
          <span class="flexible-space" />
          <button class="add bar" :disabled="false" title="New Add-On Definition from Detected" @click="showDetectedMenu">
            Detect
          </button>
        </footer>
      </section>

      <section class="columns">
        <header class="sub">
          <h1>Map To</h1>
        </header>
        <div v-if="!!selectedAddOnDef" class="wrapper">
          <div class="form-row">
            <label for="addonname">Name:</label>
            <input v-model="selectedAddOnDef.name" name="addonname" type="text" placeholder="Name" ref="addOnDefNameField">
          </div>
          
          <div class="map-wrapper varlist-wrapper active-elms">
            <header><h2>Maps</h2></header>

            <ul>
              <li v-for="c in selectedAddOnDef.columns">
                <select v-model="c.index">
                  <option v-if="c.index>=0 && columns.length > c.index" :value="c.index">
                    {{ columns[c.index] }}
                  </option>
                  <option v-else disabled :value="c.index">
                    (Column not found in file)
                  </option>
                  <option v-for="col, i in unmappedColumns" :value="col.index">
                    {{ col.index }}: {{ col.name }}
                  </option>
                </select>
                
                <label class="variant" for="variant">Variant:</label>
                <input v-model="c.variant" :disabled="c.uncountable" 
									class="variant" name="variant" type="text" :placeholder="c.uncountable ? 'From File' : 'No Variant'">

								<toggle-switch v-model="c.uncountable" />Uncountable

                <span class="flexible-space"></span>
                
                <button class="inline mini danger" title="Remove selected mapping" @click="delAddOnMapping(c)"></button>
              </li>
            </ul>

            <footer class="control">
              <button class="add bar" :disabled="false" title="New Add-On Mapping" @click="newAddOnMapping">
                Add
              </button>
            </footer>
          </div>
        </div>
      </section>
    </div>
  </article>
