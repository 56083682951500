<!-- This is the new wave planner, modularized.-->
<template>
  <article class="wave-assignment wave-planner" :class="{collapsed}">
    <header class="sub flex">
      <h1>Wave Planner</h1>
      <h2>{{race ? race.name : '-' }}</h2>
      <span class="flexible-space" />
      <button class="round center" @click="collapsed=!collapsed">↕️</button>
    </header>
    
    <section class="content-wrap">
      <form v-on:submit.prevent> 
        <fieldset>
          <div class="form-row">
            <label for="elite-waves">Create Waves</label>
            <input type="checkbox" v-model="opts.autoCreateWaves"/>
          </div>
        
          <div class="form-row">
            <label for="separation">Spacing</label>
            <input type="number" step="1" v-model="opts.maxPerWave" class="inline mini" />
            <span> / </span>
            <input type="number" step="1" v-model="opts.waveSpacingMinutes" class="inline mini" /><span class="unit">min</span>
          </div>
          
          <div class="form-row">
            <label for="sort-attr">Sort By</label>
            <select v-model="opts.sortField" title="Choose a Sort Method">
              <option disabled>Choose a Sort Method</option>
              <option v-for="name, field in sortFields" :value="field">{{ name }}</option>
            </select>

            <div v-if="opts.sortField=='ultraSignupRanking'">
              <loading-spinner v-if="loadingRankings" class="tiny-loading" @click="loadingRankings = false"/>
              <span class="status okay" v-else-if="raceEvent && raceEvent.usRankings">✓</span>
              <span class="status err" v-else>⛔️</span>
            </div>
          </div>
          <div class="form-row" v-if="opts.sortField=='ultraSignupRanking'">
            <label for="us-did">UltraSignup dID</label>
            <input type="number" v-model="opts.usdid" :disabled="!this.race"/>
            <button @click="fetchRankings" class="mini" :disabled="!opts.usdid">Fetch</button>
          </div>

          <div class="form-row">
            <label for="group-attribute">Group By Attribute</label>
            <div class="select-wrapper">
              <select v-model="opts.groupByAttribute" placeholder="None">
                <option :value="undefined">None</option>
                <optgroup label="──────────"></optgroup>
                <option v-for="k in availableKeys" :value="k">{{k}}</option>
              </select>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <div class="form-row">
            <label for="gender-weight">Time Pref Weight</label>
            <input type="range" min="0" max="1" step="0.001" v-model="opts.wavePrefWeight" class="inline"/>
            <span class="note freeze" @click="wavePrefWeight=0">{{ parseFloat(opts.wavePrefWeight||0).toFixed(2) }}</span>
          </div>
        
          <div class="form-row">
            <label for="gender-weight">Male/Female Weight</label>
            <input type="range" min="0.25" max="0.75" step="0.001" v-model="opts.genderWeight" class="inline"/>
            <span class="note freeze" @click="opts.genderWeight=0.5">{{ ((0.5 + parseFloat(opts.genderWeight||0.5)) - 1.0).toFixed(2) }}</span>
          </div>
          <div class="form-row">
            <label for="weight-wave-first">Only Weight Leading</label>
            <input type="checkbox" v-model="opts.genderWeightOnlyFirst"/>
            <label for="weight-wave-count">Count</label>
            <input type="number" v-model="opts.genderWeightCount" :disabled="!opts.genderWeightOnlyFirst" class="mini" />
          </div>
          <div class="form-row">
            <label for="elite-waves">Elite Waves</label>
            <input type="checkbox" v-model="opts.eliteWaves"/>
          </div>
          <div class="form-row" v-if="opts.eliteWaves">
            <table>
              <thead><tr><th></th><th>Men</th><th>Women</th></tr></thead>
              <tbody>
                <tr>
                  <th>Athletes</th>
                  <td><input type="number" class="mini" v-model="opts.eliteSize.M" step="1"/></td>
                  <td><input type="number" class="mini" v-model="opts.eliteSize.F" step="1"/></td>
                </tr>
                <tr>
                  <th>Waves</th>
                  <td><input type="number" class="mini" v-model="opts.mensWaveCount" step="1"/></td>
                  <td><input type="number" class="mini" v-model="opts.womensWaveCount" step="1"/></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="form-row">
            <label>Average Ranking</label>
            <span class="note">{{ averageRanking }}</span>
          </div>
        </fieldset>
      </form>
      
      <div class="flex-scroller-wrapper inset-box">
        <div class="flex-scroller par-table">
          <table>
            <thead>
              <tr>
                <th>Last</th>
                <th>First</th>
                <th>Gen</th>
                <th>Rank</th>
                <th>Adjusted</th>
                <th>Wave</th>
                <th v-if="opts.groupByAttribute">{{opts.groupByAttribute}}</th>
                <th v-else>{{opts.sortField}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="a in generatedAssignments" :class="rowClassForPar(a.par)" @contextmenu="showWeightMenu(a.par, $event)">
                <td class="last-name">{{ a.par.lastName }}</td>
                <td class="first-name">{{ a.par.firstName }}</td>
                <td :class="['gender', 'gen-'+a.par.gender]">{{a.par.gender}}</td>
                <td class="ranking">{{ (a.ranking * 100).toFixed(1) }}</td>
                <td class="adj ranking" :class="{manual: !!a.par.waveWeight }">{{ (a.adjRanking * 100).toFixed(1) }}</td>
                <td class="wave">{{ a.waveId }}</td>
                <td v-if="opts.groupByAttribute">{{ a.par[opts.groupByAttribute] }}</td>
                <td v-else>{{ opts.sortField ? a.par[opts.sortField] : '-'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </article>
</template>

<style lang="scss">
  .wave-planner {
    position: fixed;
    z-index: 10;
    bottom: 0;
    background: var(--popover-abgcolor);
    backdrop-filter: blur(20px);
    left: var(--nav-width);
    right: var(--sidebar-width);
    max-width: 1000px;
    padding: 0.5em 1em;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;
    border-top-left-radius: var(--br-sharp);
    border-top-right-radius: var(--br-sharp);
    box-shadow: 0 0 10px rgba(0,0,0,0.3);

    &.collapsed { height: 220px; top: auto;}
    top: 100px; height: auto;
    
    h1 { margin: 0 1em 0 0; font-weight: 100;}
    h2 { margin-left: 1em;}
    
    .spinner {
      position: absolute;
      top: 50%; left: 50%;
      transform: translate(-50%, -50%) scale(50%);
    }
    
    
    .content-wrap {
      display: flex; flex-direction: column;
      align-items: stretch;
      flex: 1;
    }
    
    form {
      display: flex;
      fieldset: { flex: 1; }
    }
    
    table {
      th {
        font-size: 0.9em;
        text-align:left;
        position: sticky;
        top: 0;
        background: var(--app-bgcolor);
        z-index: 100;
        
        &:nth-child(1), &:nth-child(2) { text-align: left; }
        
      }
      tr {
        cursor: pointer;
        &:hover { background: var(--row-hover-bgcolor);}
        td:first-child {
          padding-left: 2em;
        }
    
        td { white-space: nowrap ;}
      }
      .selected {
        background: var(--accent-color);
      }
  
      .buddy, .early, .late {
        td:first-child:before {
          display: inline-block;
          position: absolute;
          z-index: 10;
          width: 2em;
          margin-left: -1.5em;
          margin-right: -0.2em;
        }
      }
      /*.buddy td:first-child:before { content: '❤️'; }*/
      .buddy { background: rgba(200,50,0,0.3);}
      .early td:first-child:before { content: '☀️'; }
      .late  td:first-child:before { content: '🌙'; }
  
      .gen-F { color: #ee0076; }
      .gen-M { color: #1d606c; }

      .ranking { text-align:right;}
      .ranking.manual { font-weight: bold; text-decoration: underline;}
    }
  }
</style>

<script>
  import schema from "@/entity.js"
  import WavePrefs from "@/views/wave-prefs.vue"

  export default {
    name: 'WavePlanner',
    components: {},
    
    data() {
      return {
        collapsed: true,
        
        sortFields: {
          'ultraSignupRanking': 'UltraSignup Ranking',
          'bib': 'Bib',
          'random': 'Random'
        },
        
        loadingRankings: false,
        
        opts: {
          autoCreateWaves: true,
          waveSpacingMinutes: 10, // minutes
          maxPerWave: 10,
          groupByAttribute: undefined,
          wavePrefWeight: 0, // how strongly wave preferences matter
          genderWeight: 0.5,
          genderWeightOnlyFirst: false,
          genderWeightCount: 3,
          eliteWaves: false,
          eliteSize: {M: 10, F: 10},
          mensWaveCount: 0,
          womensWaveCount: 0,
          sortField: 'ultraSignupRanking',
        }
      }
    },
    
    computed: {
      raceEvent()    { return this.$store.state.activeRaceEvent },
      races()        { return !this.raceEvent ? [] : this.$store.getters.races(this.raceEvent.races).sort((a,b) => a.distance - b.distance) },
      selectedRaces(){ return this.$store.state.activeRaces },
      race()         { return this.selectedRaces[0] },
      ranker()       { console.log("selected races: ", this.race); return this.$store.getters.rankerForRace(this.race) },
      participants() { return !this.race ? [] : Object.values(this.$store.state.participants).filter(par=>par.raceId == this.race.id) },
      generatedAssignments() {
        console.log("%cgeneratedAssignments calculating", "color: magenta");
        if (!this.ranker) return [];

        this.ranker.participants = this.participants;
        this.ranker.options = this.opts;

        return this.ranker.rankings;
      },
      
      availableKeys() {
        const raceIds = this.races.map(r=>r.id);
        let p = Object.values(this.$store.state.participants).find(x => raceIds.includes(x.raceId));
        return p ? Object.keys(p).sort() : [];
      },
      
      averageRanking() {
        if (!this.originalRankings || this.loadingRankings) return 0;
        return this.ranker.averageRanking
      }
    },
    
    watch: {
      race() {
        this.fetchExtraAttrs();
      },
      
      participants: {
        deep: true,
        handler() { 
          this.ranker && this.ranker.calculateRankings();
        }
      }
    },
    
    mounted() {
      if (this.selectedRaces.length < 1 && this.races.length)
        this.filterToRace(this.races[0])
        
      this.fetchExtraAttrs();
    },
    
    methods: {
      rowClassForPar(p) {
        let early = false, late = false, strong = false;
        if (p.wavePref && p.wavePref.startsWith) {
          early = p.wavePref.startsWith('early');
          late = p.wavePref.startsWith('late');
          strong = p.wavePref.endsWith('+');
        }
        return {
          'lap': true, 
          selected: p == this.selectedParticipant, 
          buddy: p.waveBuddy,
          early,
          strong,
          late,
        }
      },
      
      showWeightMenu(par, e) {
        e && e.preventDefault();

        this.$nextTick(()=> this.selectedParticipant = par);
      
        Popover.showModalWithParent(this, event, {
          childComponent: WavePrefs,
          childBindings: {
            participant: par,
            waveStartTimes: this.waves,
            participants: this.participants,
          },
        }).then(popover => {
          popover.contentComponent.$on('updateWaveWeight', v => {
            // popover.contentComponent.value = v;
            console.log(`value ${par.id}:`, v);
            par.waveWeight = v;
            this.saveParticipantAttrs(par.id, {waveWeight: v});
          });
        
          popover.contentComponent.$on('updateWavePref', v => {
            console.log(`value ${par.id}:`, v);
            par.wavePref = v;
            this.saveParticipantAttrs(par.id, {wavePref: v});
          });
        
          popover.$on('close', () => {
            this.selectedParticipant = null;
          });
        });
      },
      
      fetchExtraAttrs() {
        const reid = this.raceEvent && this.raceEvent.id;
        if (!reid) return;
        this.loading = true;
        console.debug("fetching extra attrs");
        this.$store.dispatch('loadDetailAttrs', {
          url: `/race_events/${reid}/participants/detail`,
          entity: [schema.participant],
          attributes: [
            "wave_id",
            "wave_start_time",
            "wave_buddy",
            "wave_weight",
            "wave_pref",
            "wave_request",
            "rank",
          ]
        })
        .catch((e) => { console.error('attr load error: ', e); this.loading = false })
        .then(() => this.loading = false);
      },
      
      fetchRankings(e) {
        e && e.preventDefault();
      
        this.loadingRankings = true;
      
        this.$axios.post(`/race_events/${this.raceEvent.id}/rankings`, {
          did: this.opts.usdid
        })
        .then(r => {
          this.raceEvent.usRankings = r.data.usRankings;
          this.loadingRankings = false;
          // this.cacheOriginalRankings();
        })
      },
      
    }
    
    
  }
</script>