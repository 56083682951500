/****
attrForColumnName(name)
      Address: "20179 E. Escalante Road"
      Age: 46
      Aravaipa Reusable Cup 5.32: null
      Aravaipa Reusable Cup 6.38: null
      Bib: 407
      City: "Queen Creek"
      Country: "USA"
      Coupon: 0
      DOB: "08/13/1972"
      Email: "derekcrsn@hotmail.com"
      First Name: "Derek"
      Full Moon Race Times: null
      Last Name: "Carson"
      No Shirt Guarantee: "I understand, a race shirt is not guaranteed. "
      Order ID: 1760009
      Phone: 2817573753
      Price: 44.65
      Price Option: "6K - Registration - $44.65"
      Quantity: 1
      Registration Date: "5/15/2019 3:30:03 AM"
      Removed: "False"
      Salomon Email Opt-In: "Yes"
      Shirt Option: null
      Spot Headlamp 31.89: null
      State: "AZ"
      Zip: 85142
      distance: "6K"
      emergency_name: "Sarah Carson"
      emergency_phone: 6143233268
      gender: "M"
      item_discount: 0
      order_tax: 0
      order_total: 48.21
      order_type: "Credit Card"
      statement_id: 54571
      team_name: null
      ultrasignup_fee: 3.56
*/
    
export default function(columnMap, name) {
    if (undefined === columnMap)
      return console.error("Column Map is required. Maybe look at git?");
  
    // First, check for a column that includes a price
    // If it does, we strip out the price and add "addon"
    let m;
    if ((m = name.match(/\s*[\$\d\.\ ]+$/))) {
      return "addon_" + name.substr(0, m.index);
    }

    // Function to determine if a target column fuzzy-matches a given name
    // Args:
    //   +target+ is an entry in columnMap. 
    //   +name+ is a given column name we're trying to understand.
    //
    let matches = (_target, _name) => {
      const target = _target.toLowerCase();
      const name = _name.toLowerCase();
      if (target == name) return true;
      if (target == name) return true; 
      if (name.replace(/[^\w\d]+?/, '_') == target) return true;
      if (name.includes(target)) return true;
      return false;
    }
    
    // Map the received column name to a target key from the columnMap above
    let targets = Object.keys(columnMap);
    for (let i=0; i < targets.length; i++) {
      let target = targets[i]; 
      
      // 1. Check if we have a column that's identical or close to a target in the map
      if (matches(target, name)) return target;
      
      // 2. Check if the name is identical or close to a mapped name in the array
      for (let j=0; j<columnMap[target].length; j++) {
        let subTarget = columnMap[target][j];

        if (matches(subTarget, name)) {
          return target;
        }
      }
    }
    
    // Nothing in the map.
    let remapped = name.toLowerCase().replace(/[^\w\d]+?/g, '_');
    //console.warn("Could not map column '%s'; returning", name, remapped);
    return remapped;
  }
  