export function decodeToken(tok) {
  const base64Url = tok.split('.')[1]; //console.debug('base64Url:', base64Url);
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'); //console.debug('base64:', base64);
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  
  // console.debug("json:", jsonPayload);

  return JSON.parse(jsonPayload);
}

let _currentAuth;
export function currentAuth() {
  if (undefined === _currentAuth) {
    let tok = localStorage.token;

    if (!tok) return null;
  
    try {
      _currentAuth = decodeToken(tok);
    }
    catch(e) {
      console.error("could not decode token", e);
      _currentAuth = undefined;
    }
  }
  
  return _currentAuth;
}

export function isLoggedIn() {
  let auth;
  try {
    auth = currentAuth();
    return (auth && auth.sub !== undefined);
  }
  catch (e) {
    return false;
  }
}