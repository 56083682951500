
<div>
  <div class="form-row inline">
    <label for="pos-ingress-opts-slug">Event Slug</label>
    <input v-model="internalOptions.slug" type="text" id="pos-ingress-opts-slug" placeholder="ws100">
    <button class="mini" @click="fetchAuthorization">Update Details</button>
    <popover-static class-name="ext-details" button-class-name="mini" title="Show Details" prefer="up">
      <p v-if="!internalOptions.splits || internalOptions.splits.length<1">
        No Splits
      </p>
      <div v-else>
        <h3>UltraLive ID {{internalOptions.extRaceId }}</h3>
        <ul class="naked">
          <li v-for="split in internalOptions.splits">{{ split.name }} - {{ split.distance }}</li>
        </ul>
      </div>
    </popover-static>
    <loading-spinner v-if="loading"/>
  </div>
  
  <div class="form-row no-label">
    <p class="note" :class="responseClass">{{ responseMessage }}</p>
  </div>
  
  <div class="form-row">
    <label for="pos-ingress-opts-pollfreq">Poll Frequency</label>
    <input v-model="internalOptions.pollfreq" type="number" id="pos-ingress-opts-pollfreq"  step="1"/>
  </div>
</div>
