<template>
  <div class="pio-ost">
    <template v-if="editableCredentials">
      <div class="form-row inline">
        <label for="pos-ingress-opts-username">Email</label>
        <input v-model="username" type="text" id="pos-ingress-opts-username">

        <label for="pos-ingress-opts-password">Password</label>
        <input v-model="password" type="password" id="pos-ingress-opts-password">
      
        <button class="mini" @click="fetchToken">Login</button>
      </div>
      <div class="form-row no-label">
        <p class="note" :class="responseClass">{{ responseMessage }}</p>
      </div>
    </template>

    <template v-else>
      <div class="form-row no-label">
        <p class="login">Logged in as {{ username }} <button class="mini" @click="resetCredentials">Change</button></p>
        <p class="control">
          <button @click="importParticipants">Import Participants</button>
        </p>
      </div>
    </template>
    
    <div class="form-row">
      <label for="pos-ingress-opts-slug">Event Slug</label>
      <input v-model="internalOptions.slug" type="text" id="pos-ingress-opts-slug">
    </div>
    <div class="form-row">
      <label for="pos-ingress-opts-pollfreq">Poll Frequency</label>
      <input v-model="internalOptions.pollfreq" type="number" id="pos-ingress-opts-pollfreq"  step="1"/>
    </div>
  </div>
</template>

<style lang="scss">
.pio-ost {
  .login {
    padding-left: 1rem;
  }
  
  .control {
    button {
      white-space: nowrap;
    }
  }
}
</style>

<script>

import entities from '@/entity.js'

export default {
  props: {
    raceEvent: {
      type: Object,
      required: false,
    },
    
    options: {
      type: Object,
      default: () => ({})
    },
  },
  
  data() {
    return {
      internalOptions: {
        slug: undefined,
        pollfreq: 300,
        credentials: {
          username: undefined,
          password: undefined,
        },        // Either an AES encrypted string or a hash
        username: undefined,    // If credentials are encrypted, this is the plaintext username
        token: undefined,       // A token will be placed here if we log in before the raceEvent exists.
        tokenExpiresAt: undefined, 
      },
      
      responseMessage: '',
      responseClass: ''
    }
  },
  
  mounted() {
    Object.assign(this.internalOptions, this.options || {});
  },
  
  watch: {
    internalOptions: {
      deep: true,
      handler() {
        console.debug("emit internaloptions");
        this.$emit('update:options', this.internalOptions);
        Object.assign(this.options, this.internalOptions);
      }
    }
  },
  
  computed: {
    editableCredentials() {
      const creds = this.internalOptions.credentials;
      if (!creds) return true;
      if (typeof creds === 'string') return false;
      return true;
    },
    
    
    // This acts as a switch for storing the backing value of the username/password.
    // Initially, this will be a hash {username: '', password: ''}. Once it's posted to the
    // API, it becomes a string, and the username gets stored separately
    //
    username: {
      get() {
        const creds = this.internalOptions.credentials;
        if (!creds || (typeof creds === 'string')) return this.internalOptions.username;
        return this.internalOptions.credentials.username
      },
      set(v) {
        if (!this.internalOptions.credentials) this.internalOptions.credentials = {};
        if (typeof this.internalOptions.credentials !== 'string') {
          this.internalOptions.credentials.username = v;
        }
        else {
          // We have encrypted credentials
          this.internalOptions.credentials = {
            username: v,
            password: ''
          }
        }
      }
    },
    password: {
      get() {
        const creds = this.internalOptions.credentials;
        if (!creds) return ''; // Edge case
        if (typeof creds === 'string') return 'filled..'; // Will not show; should be disabled.
        return this.internalOptions.credentials.password
      },

      set (v) {
        if (!this.internalOptions.credentials) this.internalOptions.credentials = {};
        if (typeof this.internalOptions.credentials !== 'string') {
          this.internalOptions.credentials.password = v;
        }
        else {
          // Should be disabled.
        }
      }
    }
  },
  
  methods: {
    resetCredentials() {
      this.internalOptions.credentials = {
        username: this.internalOptions.username,
        password: ''
      }
    },
    
    fetchToken(e) {
      e && e.preventDefault();
      
      if (!this.raceEvent || !this.raceEvent.id)
      
      console.debug(`Fetch token with ${this.options.credentials.username} / ${this.options.credentials.password}`);
      
      this.$axios.post('race_events/pio_authorization', {
        type: 'opensplittime',
        credentials: { username: this.username, password: this.password }
      }).then(r=> {
        console.debug("Response:", r);
        if (r.status == 200) {
          // Credentials are now encrypted and we have a token.
          console.debug("Fetched OST token", r.data);
          
          const {auth, credentials} = r.data;
          
          this.responseMessage = 'Successfully logged in to OST ';
          this.responseClass = '';
          this.internalOptions.token = auth.token;
          this.internalOptions.tokenExpiresAt = auth.expiration;
          this.internalOptions.username = this.username;
          this.internalOptions.credentials = credentials; // flips the switch to remove username/pass buttons
        }
        else {
          // Credentials are incorrect or not accepted.
          console.error("Failed to fetch token", r.response.data);
          this.responseMessage = r.response.data.errors?.join(', ');
          this.responseClass = 'error';
        }
      });
    },
    
    importParticipants(e) {
      e && e.preventDefault();
      
      this.$cable.perform({channel: 'ControlChannel', action: 'external_import_participants', data: {
        reid: this.raceEvent.id,
        token: this.internalOptions.token
      }});
    }
  }
}
</script>