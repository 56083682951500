<template>
  <input :id="formId" v-model="internalValue" type="checkbox" class="toggle">
</template>

<style lang="scss">
.toggle {
  --toggle-size: 32px;
  appearance: none;
  min-width: calc(var(--toggle-size) * 1.5625);
  width: stretch;
  max-width: 60px;
  height: var(--toggle-size);
  position: relative;
  background: var(--med-bgcolor);
  border-radius: calc(var(--toggle-size) * 0.50);
  cursor: pointer;
  box-shadow: inset 0 0 5px rgba(128,128,128,0.5);
  transition: all 0.74s ease;
  margin-top: 0;
  margin-bottom: 0;
  
  &:focus {
    outline: none;
    
    &:after {
      box-shadow: 0 0 4px rgba(255,255,255,0);
    }
  }
  
  // Label
  &:before {
    content: 'Off'; 
    display: none;
  }
  
  // Thumb
  &:after {
    content: '.';
    text-indent: -9999px;
    display: block;
    position: absolute;
    top: 50%;
    left: 4px;
    transform: translateY(-50%);
    width:  calc(var(--toggle-size) - 8px);
    height: calc(var(--toggle-size) - 8px);
    background: var(--neg-bgcolor);
    border-radius: 50%;
    transition: left 0.14s ease-out;
  }
  
  &:checked {
    background: var(--accent-color);
    
    &:after {
      left: unset;
      left: calc(100% - var(--toggle-size) + 4px);
    }
  };
  
  &:disabled {
    background: var(--button-disabled-color);
    pointer-events: none;
  }
  
}
</style>

<script>
export default {
  
  props: {
    value: {
      required: true,
    },
    
    formId: {
      type: String
    }
  },
  
  data() {
    return {
      internalValueLock: false,
      internalValue: this.value
    }
  },
  
  watch: {
    value(newVal) {
      this.setInternalValueFromValue();
    },
    
    internalValue(val) {
      if (this.internalValueLock) 
        return;

      this.$emit('input', val);
    }
  },
  
  created() {
    this.setInternalValueFromValue();
  },
  
  methods: {
    setInternalValueFromValue() {
      this.internalValueLock = true;
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.internalValue = this.value;
          this.internalValueLock = false;
        });
      });
    }
  }
}
</script>