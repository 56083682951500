<template>
  <article class="reassigner">
    <header class="sub">
      <h1>Reassign Crossings</h1>
      <i class="flexible-space" />
      <input v-model="searchQuery" type="search">
    </header>
    
    <header class="crossings">
      <h2>Crossings:</h2> 
      <ul class="crossings">
        <li v-for="c in crossings">
          {{ c.tag }} at {{ c.timestamp }}
        </li>
      </ul>
    </header>
    
    <table class="lookup">
      <thead>
        <tr>
          <th class="bib">
            Bib
          </th>
          <th class="last-name">
            Last
          </th>
          <th class="first-name">
            First
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="par in participants" :class="{selected: par===selectedParticipant, 'lap':true}" @click="selectedParticipant=par">
          <td>{{ par.bib }}</td>
          <td>{{ par.lastName }}</td>
          <td>{{ par.firstName }}</td>
        </tr>
      </tbody>
    </table>
  </article>
</template>

<style scoped>
  .reassigner { 
    min-width: 60vh;
  }
  
  h2    { font-size: 16px; }
  
  table { border-collapse: collapse; border-spacing: 0; width: 100%; margin-bottom: 12px; 
          border-radius: var(--br);
        }
  table tr { margin: 0 5px; }
  thead tr { line-height: 44px; text-align: left; }
  tbody tr { line-height: 32px; cursor: default; user-select: none; }
  tbody tr.selected { background: #1057A4!important; color: #fff!important; }
  tbody td { padding: 4 8px; }
  
  .bib { min-width: 50px; }
  .last-name  { min-width: 165px; }
  .first-name { min-width: 125px; }
  
</style>

<script>
  export default {
    props: {
      crossings: {
        type: Array,
        required: true,
      },
      perPage: {
        type: Number,
        default: 12
      }
    },

    data() {
      return {
        newParticipant: undefined,
        searchQuery: '',
        page: 0,
        selectedParticipant: undefined
      }
    },
    
    computed: {
      allParticipants() { return Object.values(this.$store.state.participants) },
      filteredParticipants() {
        
        let q = this.searchQuery?.trim() || '';
        q = q.toLowerCase();
        
        return this.allParticipants.filter(par => {

          // @TODO: Stolen from participants.vue; might be good to create a mixin.
          // must match one of these OR conditions
          if (this.searchQuery && this.searchQuery.length) {
            let match = false;
            if ((par.firstName || '').toLowerCase().includes(q)) match = true;
            if ((par.lastName || '').toLowerCase().includes(q)) match = true;
            if (String(par.bib).indexOf(q) === 0) match = true;
            if (par.teamName && par.teamName?.toLowerCase().includes(q)) match = true;

            if (!match) return false;
          }

          return true;
        })
      },
      
      participants() {
        const startIndex = this.perPage * this.page;
        return this.filteredParticipants.slice(startIndex, startIndex + this.perPage);
      },
      
      pageCount() {
        return this.filteredParticipants.length / this.perPage;
      }, 
      
      isOnLastPage() {
        return (this.page+1) * this.perPage > this.filteredParticipants.length;
      },
      
      isOnFirstPage() {
        return this.page <= 0
      }
    },
    
    watch: {
      searchQuery() {
        this.page = 0
      }
    },
    
    methods: {
      nextPage() {
        if (!this.isOnLastPage) {
          this.page++;
        }
      },

      prevPage() {
        if (!this.isOnFirstPage) {
          this.page--;
        }
      }
    }
  }
</script>