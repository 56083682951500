// Ripped from here: https://github.com/vuejs/vue/blob/ec78fc8b6d03e59da669be1adf4b4b5abf670a34/dist/vue.js
// to support v-bind-date directive on datetime-local fields.
//
// The point of this is to take a v-bind-date="expression" and return an object describing how
// to access the target of that expression. v-bind-date="attributes.timestamp" gets us something 
// like {exp: 'attributes', key: 'timestamp'} so that we can call vm.$set(exp, key);
//

let len, str, chr, index$1, expressionPos, expressionEndPos;

export function parseModel(val) {
  // Fix https://github.com/vuejs/vue/pull/7730
  // allow v-model="obj.val " (trailing whitespace)
  val = val.trim();
  len = val.length;

  if (val.indexOf('[') < 0 || val.lastIndexOf(']') < len - 1) {
    index$1 = val.lastIndexOf('.');
    if (index$1 > -1) {
      return {
        exp: val.slice(0, index$1),
        // key: '"' + val.slice(index$1 + 1) + '"' // Removed ADC
        key: val.slice(index$1 + 1)
      }
    } else {
      return {
        exp: val,
        key: null
      }
    }
  }

  str = val;
  index$1 = expressionPos = expressionEndPos = 0;

  while (!eof()) {
    chr = next();
    /* istanbul ignore if */
    if (isStringStart(chr)) {
      parseString(chr);
    } else if (chr === 0x5B) {
      parseBracket(chr);
    }
  }
  return {
    exp: val.slice(0, expressionPos),
    key: val.slice(expressionPos + 1, expressionEndPos)
  }
}

function next() {
  return str.charCodeAt(++index$1)
}

function eof() {
  return index$1 >= len
}

function isStringStart(chr) {
  return chr === 0x22 || chr === 0x27
}

function parseBracket(chr) {
  var inBracket = 1;
  expressionPos = index$1;
  while (!eof()) {
    chr = next();
    if (isStringStart(chr)) {
      parseString(chr);
      continue
    }
    if (chr === 0x5B) {
      inBracket++;
    }
    if (chr === 0x5D) {
      inBracket--;
    }
    if (inBracket === 0) {
      expressionEndPos = index$1;
      break
    }
  }
}

function parseString(chr) {
  var stringQuote = chr;
  while (!eof()) {
    chr = next();
    if (chr === stringQuote) {
      break
    }
  }
}

export default { parseModel };
