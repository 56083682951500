<template>
  <div class="chart">
    <h3 v-if="showTitle">Finishers</h3>
    <canvas ref="canvas" />
  </div>
</template>

<style lang="scss">
  
</style>

<script>

 // Mostly just going through our database and getting as many points as 
 // possible (age ranges, states, countries, gender, anything else 
 // I'm not thinking of... repeat runners?)

import schema from "@/entity.js"
import participantsFiltering from "@/lib/participants-filtering.js"
import Chart from 'chart.js'


export default {
  name: 'ChartFinishers',
  
  props: {
    showTitle: {
      type: Boolean,
      required: false,
      default: true
    },
    filteredRaces: {
      type: Object,
      default() { return {} }
    },
    filteredGenders: {
      type: Object,
      default() { return {} }
    },
  },
  
  data() {
    return {
      loading: false,
      searchQuery: '',
      
      bibQuery: '',
      lastNameQuery: '',
      firstNameQuery: '',

      // filteredRaces: {'shownone': true}, // show nothing until races get loaded.
      filteredAges: {},
      filteredStatuses: {},
      selectedRace: undefined,
      
      sortKey: 'id',
      sortDir: 1,
      sortTransform: null,
      
      
      ranges: [
        {name: 'AZ', count: 0},
        //...plus whatever else is found.
      ]
    }
  },
  
  mounted() {
    console.debug("What.");
    console.warn("warn");
    debugger;
  },
  
  /* Desparately ripped off from participants.vue */
  
  computed: {
    
    participantsAreLoading() {
      const x = this.$store.state.loadingEntities.includes("participants");
      console.debug("chart-finishers: participantsAreLoading? ", x, this.$store.state.loading);
      return x;
    },
    
    ...participantsFiltering,
    
    dataCount() {
      let ranges = [
        {name: 'Current',         value: 0,  count: 0, color: 'rgb(16, 182, 186)'},
        {name: 'Finished',        value: 11, count: 0, color: 'rgb(121, 190, 66)'},
        {name: 'Did Not Start',   value: 1,  count: 0, color: 'rgb(242, 138, 33)'},
        {name: 'Did Not Finish',  value: 2,  count: 0, color: '#dd341a'},
        {name: 'Other',           value: undefined, count: 0, color: 'rgb(222, 26, 99)'},
      ]
      
      const pars = this.participants;
      
      for (let i = 0; i < pars.length; i++) {
        let p = pars[i];
        
        // Determine status; might not match the attribute value if a
        // different status is implied (e.g. finished and valid 0 == status 11)
        //
        let status = p.status;
        switch(status) {
          case 0: 
            if (p.ft) { status = 11; break; } // Finished
            if (!p.st) { status = 1; break } ; // DNS
          default: break;
        }
        
        let found = false;
        for (let j=0; j<ranges.length; j++) {
          const entry = ranges[j];

          if (status == entry.value) {
            // Fits in this range.
            found = true;
            entry.count++;
            break;
          }
        }

        if (!found) {
          // other
          console.warn("Unknown status for par", p);
          ranges[ranges.length-1].count++;
        }
      };
      
      return ranges.sort((a,b) => b.count - a.count);
    },
    
    colors() {
      return this.dataCount.map(d=> d.color );
    },
    
    chartData() {
      console.log("calc chartData gender");
      return {
        datasets: [{
          data: this.dataCount.map(r => r.count),
          backgroundColor: this.colors
        }],
      
        labels: this.dataCount.map(r => r.name),
      }
    },
    
    chartOptions() {
      return {
        type: 'pie',
        data: this.chartData,
        options: {
          cutoutPercentage: 50,
          tooltips: {
            enabled: true
          },
          plugins: {
            // needs chartjs-plugin-datalabels
            datalabels: {
                formatter: (value, ctx) => {
                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map(data => {
                        sum += data;
                    });
                    let percentage = (value*100 / sum).toFixed(2)+"%";
                    return percentage;
                },
                color: '#fff',
              }
            }
        }
        
      }
    }
  },
  
  
  watch: {
    // These get called if we win the race and load participants first
    dataCount() { this.updateChart(); },
    loading(val) { this.updateChart(); },
    
    // This gets called if we lose and someone else loaded participants first.
    participantsAreLoading(v) {
      this.updateChart();
    },
    
    chartData() { this.updateChart(); },
  },
  
  mounted() {
    this.createChart();
  },
  
  methods: {
    createChart() {
      const c = this.$refs.canvas.getContext('2d');
      this.chart = new Chart(c, this.chartOptions);
    },
    
    updateChart() {
      const d = this.chartData.datasets[0];
      this.chart.data.datasets[0].data = d.data;
      this.chart.data.datasets[0].backgroundColor = this.chartData.datasets[0].backgroundColor;
      this.chart.data.labels = this.chartData.labels;
      this.chart.update();

    }
  }
}

</script>