<template>
  <section class="lastseen">
    <header>
      <h1>Crossings</h1>
    </header>
    <header>
      <p>
        {{ selectedRace && selectedRace.name }}
      </p>
      <div class="select-wrapper">
        <select v-model="selectedSplit">
          <optgroup
            v-for="g in this.timingPointGroups"
            :label="g.race.name"
          >
            <option
              v-for="s in g.splits"
              :value="s"
            >
              {{ s.name }}
            </option>
          </optgroup>
        </select>
      </div>
    </header>

    <transition-group
      name="sidebar-list"
      tag="ul"
      class="lastseen-crossings"
    >
      <crossing-row-min
        v-for="c in crossings"
        :key="c.id"
        :timestamp-formatter="timestampFormatter"
        :crossing="c"
      />
    </transition-group>

    <loading-spinner v-if="loading" />
  </section>
</template>

<style lang="scss">
.lastseen {
  .spinner {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translate(-50%);
  }
  .select-wrapper {
    width: stretch;
    select { 
      width: stretch;
    }
  }
  
  .lastseen-crossings {
    list-style: none;
    margin: 0;
    padding: 0; 

    li {
      position: relative;
      font-size: 0.8em;
      padding-left: calc(6ex + 0.5em);
      margin-top: 0.75em;
      .bib { 
        position: absolute; top: 0; left: 0; height: 1.75em; width: 6ex;
        background: var(--light-bgcolor);
        color: var(--race-color);
        text-shadow: 0 -1px 1px rgba(0,0,0,0.7);
        text-align: center;
        border-radius: var(--br-sharp);
        border-top: 10px solid var(--race-color);
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
      
      .name { display: block; overflow: hidden; text-overflow: ellipsis; cursor: pointer;}
      .timestamp { color: var(--text-dim1-color); font-size: 0.9em; }
    }
  }
}
</style>

<script>
import entity from '@/entity.js'
import CrossingRowMin from "@/components/crossing-row-min.vue"

export default {
  name: 'LastSeen', components: {CrossingRowMin},
  
  data() {
    return {
      loading: false,
      selectedTimingPointRace: undefined,
      selectedSplit: undefined,
      timestampFormatter: new Intl.DateTimeFormat('en-US', {
        weekday: 'short', 
        month: 'short', 
        day: 'numeric', 
        hour: 'numeric', 
        minute: '2-digit', 
        second: '2-digit', 
        hour12: true,
      }),

    }
  },
  
  computed: {
    raceEvent() { return this.$store.state.activeRaceEvent; },

    races() { 
      if (!this.raceEvent) 
        return []
      return this.$store.getters.entity('races', this.raceEvent.races); 
    },

    selectedRace() {
      if (!this.raceEvent || !this.selectedSplit)
        return undefined;
      const race = this.$store.state.races[this.selectedSplit.raceId];
      
      if (race.raceEventId != this.raceEvent.id)
        return undefined;
      
      return race;
    },

    timingPointGroups() {
      // We have a choice here. We can combine all similar splits between races
      // into a "timing point" which would have the effect of showing all runners
      // through an (e.g.) aid station, or keep them separate by race.
      //
      // This method creates groupings by race.
      //
      return this.races.map(r => ({
        race: r,
        splits: this.$store.getters.entity('splits', r.splits).sort((a,b)=>a.distance - b.distance)
      })).sort((a,b)=>a.distance-b.distance);
    },
    
    
    crossings() {
      console.log("LastSeen: calculating crossings");
      let crossings = [];
      
      if (!this.selectedSplit)
        return crossings;
      
      this.$store.getters.sortedCrossings.every(c => {
        if (c.splitId == this.selectedSplit.id)
          crossings.push(c);
        return crossings.length < 12
      });
      
      return crossings;
    },
    
    lastSelectedSplit() {
      if (this.selectedSplit)
        return this.selectedSplit;
      
      // this all might fail and that's okay.
      const lastSelectedSplitId = this.$store.state.settings.lastSeenSelectedSplitId;
      console.log("Hey, got lastSelectedSplitId", lastSelectedSplitId);
      this.selectedSplit = this.$store.state.splits[lastSelectedSplitId];
      console.log("lastseen setting selectedSplit", this.selectedSplit);
    }
    
  },
  
  watch: {
    raceEvent() {
      // Need to clear the selectedTimingPointGroup
      this.selectedSplit = null;
    },
    
    // This gets called on mount or change.
    selectedSplit(newSplit) {
      // wait until after the update is done.
      this.$nextTick(()=> {

        if (!this.raceEvent || !this.selectedSplit)
          return;
    
        // stash this and attempt to restore it.
        this.$store.commit('setting', {name: 'lastSeenSelectedSplitId', value: this.selectedSplit.id});
      
        // we can request an array of splits here.
        this.loading = true;
        this.$store.dispatch('loadEntities', {
          url: `race_events/${this.raceEvent.id}/crossings/recent?split_id=${this.selectedSplit.id}`,
          entity: [entity.crossing]
        }).then(r=> {
          this.loading = false
          // we cannot let the server do the work of filtering to recent because it locks
          // us to using the most recent crossings at time of load. 
          // this.filteredCrossingIds = r.result.slice(0,15);
        });
      });
    },
    
    // On load, we want to preselect the last used split ID.
    // However, that split might not be loaded yet.
    // This gets around that fact by reacting to the change in value.
    //
    lastSelectedSplit(v) {
      this.$nextTick(()=> {
        if (!this.selectedSplit) {
          this.selectedSplit = this.lastSelectedSplit
        }
      });
    }
  },
  
}
</script>