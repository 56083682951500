export default {
  methods: {
    statusToString(status) {
      switch (parseInt(status))
      {
        case 0: {
          if (!this.participant.st && !this.participant.lapCount) 
            return 'Expected';

          if (this.race.isTimed)
            return this.$options.filters.mToDistance((this.participant.lapCount+1) * this.race.distance, {units: true});

          if (this.raceSplits.length < 1)
            return '-';
          
          const finishSplit = this.raceSplits[this.raceSplits.length-1];
          if (this.participant.lastSplitId == finishSplit.id) 
            return 'Finished';

          return 'Current';
        }
        case 1: return 'DNS';
        case 2: return 'DNF';
        case 3: return 'DQ';
        case 4: return 'Unofficial';
        case 9: return 'Other';
      default:
          return '(' + status + ')';
      }
    },
  }
}