
<article class="bib-planner">
  <header class="sub">
    <h2>Bib Assignment Planner</h2>
  </header>
  <section class="content-wrap">
    <form @submit.prevent> 
      <div class="form-row">
        <label>Race</label>
        <select v-model="selectedRace">
          <option v-for="race in races" :value="race">
            {{ race.name }}
          </option>
        </select>
      </div>
      <div class="form-row">
        <label>Sort By</label>
        <select v-model="sortField">
          <option v-for="name, field in sortFields" :value="field">
            {{ name }}
          </option>
        </select>
      </div>
      <div class="form-row">
        <label>Then Sort</label>
        <select v-model="secSortField">
          <option v-for="name, field in sortFields" :value="field">
            {{ name }}
          </option>
        </select>
      </div>
      
      <div class="form-row">
        <textarea v-model="ranges" placeholder="e.g. 1,3,4-10"/>
      </div>
      <div v-if="rangesError" class="errors">
        {{ rangesError }}
      </div>
    
      <div class="form-row stats">
        <span class="count">Participants: <span>{{ participants.length }}</span></span>
        <span class="preassigned">Preassigned: <span>{{ preAssignedCount }}</span></span>
        <span class="assigned">Will Assign: <span>{{ assignedCount }}</span></span>
        <span class="preassigned">Unassigned: <span>{{ unassignedCount }}</span></span>
        <span class="leftover">Extra Bibs: 
          <span>{{ leftoverBibs.length }} 
            <span v-if="!isNaN(leftoverPercentage)">( {{ leftoverPercentage }}% )</span>
          </span>
        </span>
      </div>
      <div class="form-row control">
        <button :disabled="!this.valid" @click="doPreview">
          Preview
        </button>
        <button :disabled="!this.valid" @click="doAssignment">
          Assign
        </button>
        <span class="flexible-space" />
        <button class="danger" @click="doClearBibs">
          Clear
        </button>
      </div>
    </form>
  
    <div class="preview-tables">
      <div class="flex-scroller-wrapper inset-box">
        <div class="flex-scroller">
          <table>
            <thead>
              <tr>
                <th>Last</th>
                <th>First</th>
                <th>Bib</th>
                <th>{{ sortFields[sortField] }}</th>
              </tr>
            </thead>
            <tbody class="alternate-rows">
              <tr v-for="p in participants">
                <td class="last-name">
                  {{ p.lastName }}
                </td>
                <td class="first-name">
                  {{ p.firstName }}
                </td>
                <td class="bib">
                  {{ bibForPar(p) }}
                </td>
                <td class="metric">
                  {{ sortAttrForPar(p) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</article>
