<template>
  <section class="crossing-details">
    <h1>Edit Crossing</h1>
    <form class="content" onsubmit="return false">
      <div class="form-row">
        <label for="event_source">Event Source</label>
        <select v-model="attributes.eventSourceId" name="event_source">
          <option disabled :value="undefined">
            Choose an Event Source
          </option>
          <option v-for="es in eventSources" :value="es.id">
            {{ es.name }}
          </option>
        </select>
      </div>

      <div v-if="participant" class="form-row">
        <label for="split">Split</label>
        <select v-model="chosenSplitId" name="split">
          <option disabled :value="undefined">
            Automatic {{ currentSplit ? '('+currentSplit.name+')' : '' }}
          </option>
          <option v-for="s in possibleSplits" :value="s.id">
            {{ s.name }}
          </option>
        </select>
      </div>

      <div class="form-row">
        <label for="locked">Locked</label>
        <input v-model="attributes.splitLocked" type="checkbox">
        <span class="note inline">Locked crossings will maintain their split association even through split 
          recalculation. Use this when an earlier crossing was missed and this crossing is being wrongly 
          interpreted as an earlier crossing.</span>
      </div>
       

      <div class="form-row">
        <label for="timestamp">Timestamp</label>
        <!-- <input type="text" v-model="timestampInput" :placeholder="placeHolder"> -->
        <!-- <input type="datetime-local" v-model.lazy="isoTimestamp" :class="{invalid: isInvalidTimestamp}" step="1"> -->
        <!-- <input type="datetime-local" v-bind-date="attributes.timestamp" :class="{invalid: isInvalidTimestamp}" step="1"> -->
        <!-- <span class="note">{{ this.localTimestamp }}</span> -->
        <!-- <datetime v-model="attributes.timestamp" type="datetime"></datetime> -->
        <!-- <input type="datetime-local"> -->
        <!-- <input type="datetime-local" v-bind-date="attributes.timestamp" :class="{invalid: isInvalidTimestamp}" step="1"> -->
        <datetime-picker v-model="attributes.timestamp" :class="{invalid: isInvalidTimestamp}"/>
      </div>
    </form>
  </section>
</template>

<style scoped lang="scss">
  .form-row {
    flex-flow: row wrap;

    label {
      width: 100px;
    }
    
    .datetime-picker,
    input[type=datetime-local] {
      max-width: auto;
    }
  }
/*  .note:not(.inline) {
    width: 100%;
  }*/
  .note.inline { 
    font-size: 11px;
  }
  
  .invalid {
    border-color: #A10009;
  }
</style>

<script>
  
  /*
  {
    "id":35086,
    "eventSourceId":1,
    "participantId":513,
    "splitId":26,
    "tag":"000000000290",
    "readerId":"A",
    "timestamp":"[native Date Sat Oct 26 2019 05:55:03 GMT-0700 (Mountain Standard Time)]",
    "validCrossing":false,
    "genderPlace":null,
    "overallPlace":null
  }
  */
  
  import schema from '@/entity.js'
  
  export default {  
    
    props: {
      crossing: {}
    },

    data() {
      return {
        // timestamp: undefined,
        isInvalidTimestamp: false,
        timestampInput: '',
        lastTimestamp: null,
        attributes: {},
      }
    },
    
    computed: {
      participant()     { return !this.attributes.participantId ? undefined : this.$store.getters.entity('participants', this.attributes.participantId) }, // may be undefined
      race()            { return !this.participant ? undefined : this.$store.getters.entity('races', this.participant.raceId); },
      possibleSplits()  { return !this.participant ? undefined : this.$store.getters.entity('splits', this.race.splits).sort((a,b)=>a.distance-b.distance)},
      eventRoutes()     { return !this.possibleSplits ? [] : this.$store.getters.entity('eventRoutes', this.possibleSplits.flatMap(s=>s.eventRoutes))},
      eventSources()    { 
        if (!this.eventRoutes)
          return [];
        let s = new Set();
        this.eventRoutes.forEach(r=> r && s.add(r.eventSourceId));
        return !this.eventRoutes ? [] : this.$store.getters.entity('eventSources', [...s]) 
      },
      placeHolder()     { return this.dateToIpico(new Date()); },
      // selectedEventSource() { return this.eventSources.find((e) => e.id == attributes.eventSourceId); }
      timestamp() { 
        console.log('calc timestamp', this.attributes.timestamp);
        return this.attributes.timestamp;
      },
      localTimestamp() {
        let timestamp = this.attributes.timestamp;
        const offset = timestamp.getTimezoneOffset(); //420 for -7 (?!)
        timestamp.setMinutes(timestamp.getMinutes() + offset); // UTC -> Local ( This should be opposite using minus, but it works? Set vs. get? )
        return timestamp;
      },
      
      timeSinceEpoch: {
        get() {
          if (!this.attributes || !this.attributes.timestamp) return undefined;
          return new Date(this.attributes.timestamp).getTime();
        },
        
        set(date) {
          let timestamp = new Date(v); 
          this.attributes.timestamp = timestamp;
        }
      },
      
      isoTimestamp: {
        get()  {
          // Our timestamps are in UTC. We want to show them in local timezone.
          //
          if (!this.attributes || !this.attributes.timestamp) return '';
          
          // very important to copy this date since we're going to modify it.
          // timestamp will be the local time
          //
          let timestamp = new Date(this.attributes.timestamp); 

          
          const offset = timestamp.getTimezoneOffset(); //420 for -7 (?!)
          console.debug("isoTimestamp offset: ", offset);
          
          timestamp.setMinutes(timestamp.getMinutes() - offset); // UTC -> Local
          let str;
          try {
            str = timestamp.toISOString();
          }
          catch(e) {
            str = this.lastTimestamp ? this.lastTimestamp.toISOString() : ' ';
          }
          
          const s = str.substr(0, str.length-1); // Remove trailing Z because local time is assumed.
          console.debug(`isoTimestamp return: "${s}"`);
          return s;
        },
        set(v) {
          console.log("isoTimestamp: set", v);
          // We receive times in local timezone. We need to convert them to UTC.
          const offset = new Date().getTimezoneOffset(); //420 for -7 (?!)
          let timestamp = new Date(v); // assumes local
          
          if (isNaN(timestamp)) {
            // invalid date, just wait.
            console.log("Ignoring invalid Date", timestamp, v);
            this.isInvalidTimestamp = true
            return;
          }
          else {
            this.isInvalidTimestamp = false;
            this.$set(this.attributes, 'timestamp', timestamp);
          }

        }
      },
      
      chosenSplitId: {
        get() {
          // Return undefined if split is not locked, so that the split chooser will show "Automatic"
          if (!this.attributes.splitLocked)
            return undefined;
          
          return this.attributes.splitId
        },
        
        set(v) {
          this.attributes.splitId = v;
          this.attributes.splitLocked = true;
        }
      },
      
      currentSplit() {
        return this.$store.getters.entity('splits', this.crossing.splitId);
      }
    },
    
    watch: {
      
      timestampInput() {
        const comp = this.timestampInput.match(/\d\d/g)
        const str =  "20" + comp.slice(0,3).join('/') + ' ' + comp.slice(3).join(':'); // "2019/10/26 09:00:00"
        let d;
        try {
          d = new Date(Date.parse(str))
        } catch(e) {
          // Invalid date
          d = undefined;
        }
        
        this.attributes.timestamp = d;
      },      
    },
    
    mounted() {
      Object.keys(this.crossing).forEach(k => {
        if (this.crossing.hasOwnProperty(k)) {
          this.$set(this.attributes, k, this.crossing[k]);
        }
      });
      
      this.lastTimestamp = this.attributes.timestamp;
    },
    
    methods: {
      dateToIpico(date) {
        const y = date.getFullYear().toString().slice(2);
        const m = ("0"+ (date.getMonth() + 1)).slice(-2);
        const d = ("0"+date.getDate()).slice(-2);
        
        const h = ("0"+date.getHours()).slice(-2);
        const mi= ("0"+date.getMinutes()).slice(-2);
        const s = ("0"+date.getSeconds()).slice(-2);
        
        return y + m + d + h + mi +s;
      },
      
      deleteCrossing() {
        
        // We need these for the paths. It's pretty specific to prevent data loss.
        // Only crossings that have been routed can be deleted. The others don't
        // really show up anyway, or at least don't affect the race. 
        const raceEventId = this.race.raceEventId;
        const participantId = this.crossing.participantId;
        const crossingId = this.crossing.id;
        
        if (!raceEventId || !participantId || !crossingId) {
          alert(`Unrouted crossings cannot be deleted\nRaceEvent: ${raceEventId}\nParticipant:${participantId}\nCrossing${crossingId}`);
          return;
        }

        if (!confirm('Really delete this crossing? This cannot be undone.'))
          return;

        /* /api/v1/race_events/:race_event_id/participants/:participant_id/crossings/:id */
        this.$store.dispatch('destroyEntity', {
          entity: this.crossing,
          entitySchema: schema.crossing,
          path: `race_events/${raceEventId}/participants/${participantId}/crossings/${crossingId}`
        }).then(r => {
          this.$modalWindow.cancel();
        });
      }
    }
    
  }
</script>