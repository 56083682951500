
<div class="race-events" @escapepressed="escapePressed">
  <header class="sub">
    <h1>Currently Active Event</h1>
    <span class="flexible-space" />
    <button class="add" @click="editRaceEvent(null)">
      New Event
    </button>
  </header>
  
  <header v-if="activeRaceEvent" class="active sub inset-box">
    <h1>{{ activeRaceEvent.name }}</h1>
    <button class="mini inset" @click="editRaceEvent(activeRaceEvent)">
      Edit
    </button>
  </header>
  
  <div v-if="raceEventsLoading" class="loading">
    Loading...
  </div>
  
  <div v-if="raceEvents.length == 0" class="no-content">
    No Race Events
  </div>

  <ul v-else class="naked" ref="raceEventsList">
    <li v-for="raceEvent in raceEvents" :class="{selected: activeRaceEvent && raceEvent.id === activeRaceEvent.id}" @click.stop="setActiveRaceEvent(raceEvent)">
      <figure class="icon">
        <img :src="raceEventImageUrl(raceEvent)" alt="race logo" @error="raceEventImageError">
      </figure>
      <span class="name" :title="raceEvent.name">{{ raceEvent.name }}</span>
      <span class="year">{{ $root.shortDateFmt.format(raceEvent.startTime) }}</span>
    </li>
    <li class="full button">
      <button @click="loadMore">More</button>
    </li>
  </ul>
</div>  
