<template>
  <article class="insert-laps">
    <header class="sub">
      <h1>Insert Laps Crossings</h1>
      <i class="flexible-space" />
    </header>
    
    <header class="details">
      <form>
        <div class="form-row">
          <label>Insert</label>
          <input class="mini" type="number" step="1" min="1" v-model.number="insertCount">
          
          <label>Event Source</label>
          <label v-if="selectedSplit">{{ selectedSplit.name }} →</label>
          <select v-model="selectedEventSourceId">
            <option v-for="es in eventSources" :key="es.id" :value="es.id">{{ es.name }}</option>
          </select>
        </div>
        <div class="form-row">
          <label>Apply Jitter</label>
          <input type="checkbox" v-model="applyJitter">
        </div>
      </form>
      <p class="dur">
        Inserting into
        <span class="hours" v-if="deltaTimeDur.hours">{{deltaTimeDur.hours}}</span>
        <span class="min">{{ deltaTimeDur.minutes }}</span>
        <span class="sec">{{ pad(deltaTimeDur.seconds, 2) }}</span>
        <span class="ms">{{ pad(deltaTimeDur.ms, 3) }}</span>
        gap; Will add {{ (finishSplit.distance * newCrossingDescriptors.length) | mToDistance({units: true}) }}
      </p>
    </header>
    
    <div class="table-scroll">
      <table class="lap-preview ">
        <thead>
          <tr>
            <th class="lap">
              Lap
            </th>
            <th class="ts">
              Timestamp
            </th>
            <th class="dur">
              Lap Duration
            </th>
          </tr>
        </thead>

        <tbody class="">
          <tr class="existing from" v-if="prevRealCrossing">
            <td>{{ prevRealCrossing.loopId }}</td>
            <td>{{ prevRealCrossing.timestamp && dateFormatter.format(prevRealCrossing.timestamp) || '-' }}</td>
            <td>-</td>
          </tr>
          <tr v-else>
            <td>-</td>
            <td>Race Start</td>
            <td>-</td>
          </tr>
        
          <tr class="new" v-for="desc in newCrossingDescriptors" :key="desc.loopId">
            <td>{{ desc.loopId }}</td>
            <td>{{ dateFormatter.format(desc.timestamp) }}</td>
  <!--needs duration-display -->
            <td class="dur" v-if="desc.duration">
              <span class="hours" v-if="desc.duration.hours">{{desc.duration.hours}}</span>
              <span class="min">{{ desc.duration.minutes }}</span>
              <span class="sec">{{ pad(desc.duration.seconds, 2) }}</span>
              <span class="ms">{{ pad(desc.duration.ms, 3) }}</span>
            </td>
          </tr>

          <tr class="existing to" v-if="beforeCrossing">
            <td>{{ beforeCrossing.loopId + newCrossingDescriptors.length }}</td>
            <td>{{ beforeCrossing.timestamp && dateFormatter.format(beforeCrossing.timestamp) || '-' }}</td>
            <td class="dur" v-if="lastDuration">
              <span class="hours" v-if="lastDuration.hours">{{lastDuration.hours}}</span>
              <span class="min">{{ lastDuration.minutes }}</span>
              <span class="sec">{{ pad(lastDuration.seconds, 2) }}</span>
              <span class="ms">{{ pad(lastDuration.ms, 3) }}</span>
            </td>
            <td class="dur unknown" v-else>-</td>
          </tr>
        </tbody>
      </table>
    </div>
  </article>
</template>

<style scoped lang="scss">
  .insert-laps { 
    min-width: 60vh;
    
    .new {
      background-color: rgba(0,255,0, 0.1);
    }

    .dur {
      .hours:after, .min:after {
        content: ':';
      }
    
      .ms { 
        color: var(--text-dim1-color); font-size: 0.95em;
        &:before { content: '.'; }
      }
    }
    
    .table-scroll {
      max-height: 20em;
      overflow-y: scroll;
      position: relative;
      
      thead tr {
        position: sticky;
        background: var(--app-bgcolor);
        top: 0;
      }
    }
  }
  
  h2    { font-size: 16px; }
  
  table { border-collapse: collapse; border-spacing: 0; width: 100%; margin-bottom: 12px; 
          border-radius: var(--br);
        }
  table tr { margin: 0 5px; }
  thead tr { line-height: 44px; text-align: left; }
  tbody tr { line-height: 32px; cursor: default; user-select: none; }
  tbody tr.selected { background: #1057A4!important; color: #fff!important; }
  tbody td { padding: 4 8px; }
  
  .bib { min-width: 50px; }
  .last-name  { min-width: 165px; }
  .first-name { min-width: 125px; }
  
</style>

<script>
  import {normalize} from 'normalizr'
  import schema from '@/entity.js'

  function bsearch(arr, comp) {
    let min = 0;
    let max = arr.length - 1;
    let mid;
    let val;
    while (min <= max) {
      mid = (min + max) >>> 1;
      val = comp(arr[mid], mid);
      // console.log(`search ${min}-${max}`)
      if (val === 0) {
        // console.log('comp = 0')
        return arr[mid];
        // return mid;
      } else if (val < 0) {
        // console.log('comp < 0; inc')
        min = mid + 1;
      } else {
        // console.log('comp > 0; dec')
        max = mid - 1;
      }
    }

    // return -1;
    return undefined;
  }

  export default {
    name: 'InsertCrossings',
    
    props: {
      participant: {
        type: Object,
        required: true,
      },
      beforeCrossing: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        loading: false,
        insertCount: 1,
        selectedEventSourceId: undefined,
        applyJitter: false,
        
        dateFormatter: new Intl.DateTimeFormat('en-US', {
          weekday: 'short', 
          month: 'short', 
          day: 'numeric', 
          hour: '2-digit', 
          minute: '2-digit', 
          second: '2-digit', 
          hour12: true,
        }),
        
      }
    },
    
    computed: {
      race() {
        return this.$store.getters.entity('races', this.participant.raceId);
      },
      
      splits() {
        return this.$store.getters.entity('splits', this.race.splits).sort((a,b)=>a.distance-b.distance);
      },
      
      finishSplit() {
        if (!this.splits?.length) return undefined;
        return this.splits[this.splits.length-1];
      },
      
      selectedSplit() {
        return this.finishSplit
      },
      
      selectedSplitEventRoutes() {
        if (!this.selectedSplit) return [];
        return this.$store.getters.entity('eventRoutes', this.selectedSplit.eventRoutes);
      },
      
      eventSources() {
        return this.$store.getters.entity('eventSources', this.selectedSplitEventRoutes.map(er=> er.eventSourceId));
      },
      
      crossings() {
        let crs = this.$store.getters.entity('crossings', this.participant.crossings).filter(c=>c.validCrossing);
        crs.sort((a,b) => a.timestamp - b.timestamp);
        return crs;
      },
      
      prevRealCrossing() {
        if (!this.crossings) return undefined;
        // const ts = this.beforeCrossing.timestamp;
        // return bsearch(this.crossings, c=> c.timestamp - ts);
        const i = this.crossings.indexOf(this.beforeCrossing);
        if (i < 1) return undefined;
        
        return this.crossings[i-1];
      },
      
      deltaTime() {
        let ts = new Date(this.prevRealCrossing?.timestamp);
        if (!ts) return 0;
        return this.beforeCrossing.timestamp.getTime() - ts.getTime();
      },
      
      deltaTimeDur() {
        return this.$breakDuration(this.deltaTime)
      },

      newCrossingDescriptors() {
        let loopId = this.prevRealCrossing?.loopId;
        let ts = new Date(this.prevRealCrossing?.timestamp).getTime();
        if (loopId===undefined || !ts) {
          console.error("Unable to determine previous real crossing loop ID");
          return [];
        }
        
        const count        = this.insertCount + 1; 
        const deltaTime    = this.deltaTime;
        const loopDuration = deltaTime / count;
        
        console.log(`Delta: ${deltaTime} / ${count} = ${loopDuration} ms:`);
        
        return Array.from({length: this.insertCount}).map(i=> {
          let dur = loopDuration; // could vary this randomly or using a editable lookup table if needed

          if (this.applyJitter)
            dur += ((Math.random() * 10) - 5) * 1000;

          console.log("loop duration:", this.$breakDuration(dur));
          ts += dur;
          ++loopId;
          return {
            loopId,
            timestamp: new Date(ts),
            duration: this.$breakDuration(dur),
          }
        })
      },
      
      lastDuration() {
        if (!this.newCrossingDescriptors.length) return undefined;
        const lastNewLap = this.newCrossingDescriptors[this.newCrossingDescriptors.length-1];
        
        return this.$breakDuration(this.beforeCrossing.timestamp - lastNewLap.timestamp);
      },
      
      insertedCrossings() {
        return this.newCrossingDescriptors.map(desc => ({
          id: this.$store.getters.randomID(),
          readerId: 'manual',
          participantId: this.participant.id,
          eventSourceId: this.selectedEventSourceId,
          timestamp: desc.timestamp,
          tag: this.participant.bib,
        }));
      }
    },
    
    watch: {
      selectedSplit(s) {
        this.selectedEventSource = this.eventSources[0];
      }
    },
    
    mounted() {
      this.loading = true;
      const participantId = this.participant?.id;
      const raceEventId = this.$store.state.activeRaceEvent?.id;
      if (!participantId || !raceEventId) {
        console.warn(`Missing context for participant detail load: reid: ${raceEventId}; par: ${participantId}`);
        this.loading = false;
        return;
      }
      
      this.$store.dispatch('loadEntities', {
        url: `/race_events/${raceEventId}/participants/${participantId}`,
        entity: schema.participant
      }).then(r => {
        this.loading = false
      });
      
      this.$nextTick(() => {
        console.log("Will initially select event source", this.eventSources);
        this.selectedEventSourceId = this.eventSources[0].id;
      });
    },
    
    methods: {
      pad: (num, width) => num < 100 ? ('0000'+num).slice(width*-1) : num,
      
      doInsertCrossings() {
        const raceEventId = this.race.raceEventId;
        const path = `race_events/${raceEventId}/crossings/batch`;
        
        // The Vuex saveEntity action expects a single entity
        //
        return this.$axios.post(path, {
          crossings: this.insertedCrossings
        }).then(response=> {
          const data = normalize(response.data, [schema.crossing]);
          this.$store.dispatch('commitEntities', data).then(()=> {
            this.$cable.perform({
              channel: 'ControlChannel', action: 'recalculate_splits', data: {ids: [this.participant.id] }
            });
          });
        });

      }
    }
  }
</script>