
<li
  class="participant-result"
  :class="`gen-${subject.gender}`"
  @click="handleClick"
  :title="`${subject.firstName} ${subject.lastName}`"
>
  <span class="bib"><span :style="bibStyle">{{ subject.bib }}</span></span>
  <span class="name">
    <span class="first-name">{{ subject.firstName }}</span>
    <span class="last-name">{{ subject.lastName }}</span>
  </span>
  <span class="gender">{{ subject.gender }}</span>
  <span class="age">{{ age }}</span>
  <span
    v-if="subject.waveId"
    class="wave"
  >W-{{ subject.waveId }}</span>
  <span class="race-name">{{ race.name }}</span>
  <span class="flexible-space" />
  <span class="status">{{ statusString }}</span>
</li>
