
<div class="login">
  <h1>Login</h1>

  <div ref="loginbox" class="group" :class="{shake: isShaking}">
    <input ref="un" v-model="un" type="text" placeholder="Username" autofocus @keyup="detectReturn">
    <input ref="pw" v-model="pw" type="password" placeholder="Password" @keyup="detectReturn">
    <loading-spinner v-if="loading" />
    <button class="round go" @click="doLogin">
      →
    </button>
  </div>
</div>
