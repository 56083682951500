<template>
  <article class="root" @dragenter="dragenter" @dragover="dragover" @dragleave="dragleave" @drop="dragdrop" :class="{droptarget: isDragging}">
    <input ref="fileInput" type="file" v-bind="fileInputValue" accept="text/csv" style="display:none;" @change="handleFileInputChange">
    <header class="sub">
      <h1 class="flexible-space">
        Import / Update Participants
      </h1>
      <span class="preset">
        <label>Preset</label>
        <select v-model="selectedPresetName" class="preset">
          <option :value="null">Defaults for Event</option>
          <option v-for="p in Object.keys(presets)">{{ p }}</option>
        </select>
        <button class="mini bar" @click="showPresetOptions"></button>
      </span>
    </header>
    
    <div class="always">
      <button @click="askForFile">
        Choose File
      </button>
      <input ref="fileInput" type="file" accept="text/csv" style="display:none;" @change="handleFileInputChange">

      <div v-if="!hasFile">
        Please Choose a CSV File to Import
      </div>
    </div>


    <div v-if="!!error" class="error">
      {{ error }}
    </div>
    
    <ul class="tabs">
      <li :class="{selected: selectedTab=='columns'}" @click="selectedTab='columns'">
        Columns
      </li>
      <li :class="{selected: selectedTab=='addons'}" @click="selectedTab='addons'">
        Add-Ons
      </li>
    </ul>


    <div v-if="selectedTab=='columns'" class="import-columns flex-vertical">
      <div class="inset-box options">
        <h4>{{ fileName }}</h4>
        <div class="form-row">
          <label for="allow_bib_changes">Allow Bib Changes</label>
          <toggle-switch id="allow_bib_changes" v-model="upsertOptions.allowBibChanges" />
        </div>
        <div class="form-row">
          <label for="force_race">Race Assignment</label>
          <div class="select-wrapper">
            <select id="force_race" v-model="upsertOptions.overrides.raceId">
              <option :value="false">
                Use value from file
              </option>
              <optgroup label="──────────" />
              <option value="infer">
                Infer Distance
              </option>
              <optgroup label="──────────" />
              <option v-if="races.length<1" disabled>
                No Available Races
              </option>
              <option v-for="race in races" v-else :value="race.id">
                {{ race.name }}
              </option>
            </select>
          </div>
          <p class="note">
            {{ raceAssignmentExplanation }}
          </p>
        </div>
      </div>
      <div class="flex-scroller-wrapper">
        <div class="flex-scroller">
          <table>
            <thead>
              <tr>
                <th v-for="(col, i) in columns" :title="`${col}${isDuplicateColumnChoice(i) ? ' (Duplicate)' : ''}`" 
                  :class="{ignore: '$ignore$' == columnChoices[i], addon: '$addon$' == columnChoices[i]}"
                >
                  <span class="given" :class="{duplicate: isDuplicateColumnChoice(i)}">{{ col }}</span>
                  <div class="select-wrapper">
                    <select v-model="columnChoices[i]" class="mini">
                      <option value="$ignore$">
                        Ignore
                      </option>
                      <option value="$addon$">
                        Add On
                      </option>
                      <option v-for="k in possibleColumns" :value="k">
                        {{ k }}
                      </option>
                    </select>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody class="alternate-rows">
              <tr v-for="row in this.previewRows">
                <td v-for="(col, i) in columns" :class="{ignore: '$ignore$' == columnChoices[i]}">
                  {{ row[i] }}
                </td>
              </tr>
              <tr v-if="rowCount > 5" class="note">
                <td colspan="100%">
                  And {{ rowCount - 5 }} more…
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div v-if="selectedTab=='addons'" class="addons flex-columns">
      <section class="add-on-definitions varlist-wrapper active-elms">
        <header class="sub" title="Add-ons to Connect to Participants">
          <h1 class="flexible-space">
            Add-Ons
          </h1>
        </header>
        <ul>
          <li v-for="def in addOnDefs" :class="{selected: def == selectedAddOnDef}" @click="setSelectedAddOnDef(def)">
            {{ def.name }}
          </li>
        </ul>
        <footer class="control">
          <button class="add bar" :disabled="false" title="New Add-On Definition" @click="addAddOnDef">
            Add
          </button>
          <button class="del bar" :disabled="false" title="Delete Add-On Definition" @click="removeSelectedAddOnDef">
            Delete
          </button>
          <span class="flexible-space" />
          <button class="add bar" :disabled="false" title="New Add-On Definition from Detected" @click="showDetectedMenu">
            Detect
          </button>
        </footer>
      </section>

      <section class="columns">
        <header class="sub">
          <h1>Map To</h1>
        </header>
        <div v-if="!!selectedAddOnDef" class="wrapper">
          <div class="form-row">
            <label for="addonname">Name:</label>
            <input v-model="selectedAddOnDef.name" name="addonname" type="text" placeholder="Name" ref="addOnDefNameField">
          </div>
          
          <div class="map-wrapper varlist-wrapper active-elms">
            <header><h2>Maps</h2></header>

            <ul>
              <li v-for="c in selectedAddOnDef.columns">
                <select v-model="c.index">
                  <option v-if="c.index>=0 && columns.length > c.index" :value="c.index">
                    {{ columns[c.index] }}
                  </option>
                  <option v-else disabled :value="c.index">
                    (Column not found in file)
                  </option>
                  <option v-for="col, i in unmappedColumns" :value="col.index">
                    {{ col.index }}: {{ col.name }}
                  </option>
                </select>
                
                <label class="variant" for="variant">Variant:</label>
                <input v-model="c.variant" :disabled="c.uncountable" 
									class="variant" name="variant" type="text" :placeholder="c.uncountable ? 'From File' : 'No Variant'">

								<toggle-switch v-model="c.uncountable" />Uncountable

                <span class="flexible-space"></span>
                
                <button class="inline mini danger" title="Remove selected mapping" @click="delAddOnMapping(c)"></button>
              </li>
            </ul>

            <footer class="control">
              <button class="add bar" :disabled="false" title="New Add-On Mapping" @click="newAddOnMapping">
                Add
              </button>
            </footer>
          </div>
        </div>
      </section>
    </div>
  </article>
</template>

<style lang="scss" scoped>

.root {
  min-width: 70vw;
  height: 66vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-left: 1em;
  
  &.droptarget {
    background: var(--popover-abgcolor);
    border: 2px solid  var(--accent-color);
    border-radius: var(--br);
  }
  
  .preset {
    label {
      font-size: 0.75em;
      text-transform: uppercase;
      color: var(--text-dim2-color);
      font-weight: 800;
      text-shadow: var(--inset-text);
    }
    button { font-family: 'Font Awesome 5 Free'; }
  }
  
  select.preset {
    width: 150px;
    margin-left: .5em;
    border-radius: 4px;
  }
  
  .preset-save, .preset-load {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    margin: 0;
  }
  .preset-load {
    margin-right: 2em;
  }
}

.flex-columns {
  display: flex;
  flex-direction: row;
  align-items: stretch;

  height: 1px;  // keeps it from overflowing in chrome.
  flex-grow: 1; // keeps it from being 1px tall.
}

.options {
  font-size: .9em;
  padding: 0 1em;
}

// The import tab
.import-columns {
  width: stretch;
  display:flex;
  height: 100%;
  
  table {
    box-shadow: inset 0 0 5px rgba(0,0,0,0.4);
    border-radius: var(--br);
    padding-left: 4px;
    
    thead {
      
      margin-bottom: 4px;
      
      th { 
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; 
        max-width: 100px;
        font-size: 0.85em;
        padding: 0 1px;
        text-align: left;
        
        .given {
          display:block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          
          &.duplicate {
            color: var(--error-color);
          }
        }
        
        .select-wrapper {
          width: 100%;
          select { width: stretch;}
        }
      }      
    }
    
    td.ignore,
    th.ignore {
      color: var(--text-dim3-color);
    }
    
    td.addon,
    th.addon {
      color: var(--accent-color);
    }
    
    td {
      font-size: 0.85em;
    }
    
    tr.note td {
      font-style: italic;
      font-size: 0.85em;
      position: fixed;
      left: 50%;
    }
    
    tr {
      font-size: 0.95em;
      line-height: 1.8em;
      
      td:first-child { padding-left: 1.25em; }
      td:last-child { padding-right: 1.25em; min-width: 2em;}
      
      td.bib { text-align: right; }
    }
    
  }
}

// These are our add ons
.add-on-definitions {
  width: 30%;
  li {
    margin-bottom: .5em;
  
    input[type=text] {
      line-height: 26px;
      font-size: 17px;
      padding: .25em;
      border-radius: 4px;
      border: 1px solid rgba(128,128,128,0.1);
      margin-right: 0.75em;
    }
  }
}

.columns {
  height: 100%;
  overflow: hidden;
  padding-left: .5em;
  flex-grow: 1;

  .wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  .varlist-wrapper {
    flex-grow: 1;
    height: 1px; // let it grow to 100%
    margin-bottom: 44px;
  }
  ul {
    overflow-y: auto;
    max-height: calc(100% - 150px);
    padding-top: 0.5em;
  }
  li {
    display: flex;
    align-items: center;
    user-select: none;
    line-height: 16px;
    transition: background 0.4s ease-in-out;
    
    &:hover { 
      background: var(--alternate-row-bgcolor) !important; 
    }
    
    .mapped { margin-left: .25em; font-family: 'Font Awesome 5 Free'; font-weight: 900; color: var(--text-dim3-color);}
    .mapped.hasmap { color: var(--success-color); }
        
    select   { width: 200px; height: 2em; border-radius: var(--br); padding: 0 .5em;}
    label.variant { margin-left: 1em;}
    input.variant { margin-left: 0.5em; padding: 0 1em; width: 85px; border: none; border-radius: var(--br); line-height: 2em; }
    button { height: 2em; line-height: 1.65em; }
  }
  .index {
    color: var(--text-dim2-color);
    margin: 0 0.5em;
  }
}

.add-on-definition {
  padding-left: 1em;
  margin-top: .5em;
}

</style>

<script>
  import Papa from 'papaparse'
  import participantsColumnMapper from "@/lib/participants-csv-map.js"
  import Popover from "@/components/popover.vue"
  import MenuContents from "@/components/menu-contents.vue"
  import schema from '@/entity.js'

  export default {
    
    props: {
      initialColumnMap: {
        type: Object,
        default() { return {
          unique_id:['Order ID'],
          bib: [],
          chips: [],
          first_name: ["First", "firstName"],
          last_name: ["Last", "lastName"],
          full_name: [],
          distance: ['race'],
          gender: ["sex"],
          dob: ["birthday"],
          age:[],
          email: [],
          address: [],
          city: [],
          state:[],
          zip: ["postal_code", "postalcode"],
          country:[],
          phone: [],
          team_name: ["team"],
          emergency_name:['emergency_name'],
          emergency_phone:['emergency_phone'],
          shirt_size:['shirt size', 'shirt option'],
          expects_shirt: ['No Shirt Guarantee'],
          registered_at: ['Registration Date', 'Order Date',],
          notes: ['nickname'],
          remove: ['Removed'],
          wave_request: [],
          wave_start_time: [],
        }}
      }
    },
    
    data() {
      let p;
      try {
        p = JSON.parse(window.localStorage.importMapPresets);
      }
      catch(e) { 
        p = []; 
      }
      
      return {
        presets: p,
        selectedPresetName: null,
        
        selectedTab: 'columns',
        
        upsertOptions: {
          allowBibChanges: false,
          overrides: {
            raceId: false // this is mostly here to allow a v-model directive
          },
        },
        
        fileName: undefined,
        uploadFile: null, // File object
        
        error: null, // csv file error
        fileInputValue: null,
        columnMap: Object.assign({}, this.initialColumnMap),
        columns: [], // given from CSV
        columnChoices: [], // chosen from dropdowns
        rowCount: 0,
        previewRows: [],
        addOnDefs: [],
        selectedAddOnDef: null,
        
        isDragging: false
      }
    },
    
    computed: {
      
      raceEvent() {
        return this.$store.state.activeRaceEvent;
      },
      
      races() {
        return this.$store.getters.entity('races', this.raceEvent.races);
      },
      
      raceAssignmentExplanation() {
        if (!this.upsertOptions || !this.upsertOptions.overrides || !this.upsertOptions.overrides.raceId)
          return 'Races with names matching the values in the distance column will be used, being created if needed.';
        
        const raceId = this.upsertOptions.overrides.raceId;
        
        if (!isNaN(parseInt(raceId))) {
          const race = this.$store.state.races[parseInt(raceId)];
          if (!race) {
            return `All participants will be assigned race ID ${raceId}, which may not exist`;
          }
          
          return `All participants will be entered in "${race.name}"`
        }
        
        switch (raceId) {
          case 'infer': 
            return 'Races will be determined by inferring distances of races by their names. The actual column names will be assigned to the participant\'s waveRequest field.';
          default: 
            return 'An unknown condition will lead to races being assigned by the value in the distance column';
        }
      },
      
      hasFile() {
        return this.columns.length > 0;
      },
            
      possibleColumns() {
        return Object.keys(this.columnMap);
      },
      
      // Only the columns that are detected as applying to an add-on
      addOnColumns() {
        if (!this.columns) return [];

        let r = [];
        let choices = this.columnChoices;
        this.columns.forEach((name, i) => {
          let m;
          if ((choices[i]) == '$addon$' || (m = name.match(/\s*[\$\d\.]+$/))) {
            r.push({
              index: i,
              name: m ? name.substr(0, m.index) : name, 
              mapped: false,
            });
          }
        });

        return r;
      },
      
      // Unmapped to an addon; columns can only be mapped to a single addon. 
      // 
      unmappedColumns() {
        return this.addOnColumns.filter(c => {
          // return false if we find an addon with this column
          if (!this.addOnDefs) return true;
          
          return !this.addOnDefs.find(a => {
            let x = a.columns.find(mc=> mc.index == c.index)
            return x;
          });
        });
      },
      
      selectedPreset() {
        return this.presets[this.selectedPresetName];
      },
      
      // Returns the current mutable state. Almost everything else
      // in data is comprised of scratchpad-type members.
      //
      // ** Remember to also load these members in +mounted()+ **
      //
      presetAttributes() {
        return {
          upsertOptions: this.upsertOptions,
          columnChoices: this.columnChoices,
          addOns: this.addOnDefs,
        }
      }
      
    },
    
    mounted() {
      if (this.raceEvent && this.raceEvent.importPreset)
        this.loadPreset(this.raceEvent.importPreset);
    },
    
    methods: {
      askForFile() {
        this.$refs.fileInput.click();
      },
      
      dragenter(e) {
        e.preventDefault();
        this.isDragging = true;
      },
      
      dragover(e) {
        e.preventDefault();
      },

      dragleave(e) {
        e.preventDefault();
        this.isDragging = false;
      },
      
      dragdrop(e) {
        e.preventDefault();
        this.isDragging = false;
        
        if (e.dataTransfer.items) {
          // Use DataTransferItemList interface to access the file(s)
          for (var i = 0; i < e.dataTransfer.items.length; i++) {
            // If dropped items aren't files, reject them
            if (e.dataTransfer.items[i].kind === 'file') {
              var file = e.dataTransfer.items[i].getAsFile();
              this.handleNewFile(file);
              break;
            }
          }
        } else {
          // Use DataTransfer interface to access the file(s)
          for (var i = 0; i < e.dataTransfer.files.length; i++) {
            this.handleNewFile(e.dataTransfer.files[0]);
            break;
          }
        }
      },

      handleFileInputChange() {
        let file = this.$refs.fileInput.files[0];
        this.handleNewFile(file);
      },

      handleNewFile(file) {
        // This is where we give people the ability to map columns themselves.
        // we make educated guesses too.
        //
        const isFirstFile = !this.fileName;
        this.fileName = file.name;
        this.uploadFile = file;
        
        const result = Papa.parse(file, {
          dynamicTyping: true,
          // preview: 5,
          error: (error, file) => {
            console.error("UploadCSV: Unable to read file: ", error, file);
            this.error = `UploadCSV: Unable to read file: ${error}"`
          },
          complete: result => {
            let data = result.data;
            this.columns = data.shift();
            this.rowCount = data.length;
            this.previewRows = data.slice(0,5);
            
            // Guess column mapping
            if (!isFirstFile || this.columnChoices.length < 1) {
              this.guessColumnMapping();
            }

            this.dynamicTypeColumns();
        	}
        });
      },
      
      guessColumnMapping() {
        this.columnChoices = [];
        this.columns.forEach((col, i) => {
          let guess = this.mapColumn(col, true);
          let possibleIndex = this.possibleColumns.indexOf(guess);
          if (possibleIndex >=0 ) {
            // We found what this given column is supposed to be mapped to.
            // It's supposed to be mapped to 
            this.columnChoices[i] = guess;
          }
          else {
            this.columnChoices[i] = '$ignore$';
          }
        });
      },
      
      // Transform values to their correct values. 
      dynamicTypeColumns()   {
        console.log("Doing dymanic typing");
        this.columns.forEach(col => {
          
        })
      },
      
      doUpload() {
        console.debug("Doing Upload!")
        this.uploadNewFile()
      },
      
      uploadRaceEventPresets() {
        const attrs = {
          id: this.raceEvent.id,
          importPreset: this.presetAttributes
        }
        
        this.$store.dispatch('saveEntity', {
          path: `/race_events/`,
          entity: schema.raceEvent,
          object: attrs
        });
      },
      
      uploadNewFile(){
        let data = [];
        let file = this.uploadFile;
        
        this.uploadRaceEventPresets();

        if (!file)
          return console.error("Expected file for participant upload");

        this.loading = true;
    
        let promises = [];
        
        Papa.parse(file, {
          dynamicTyping: true,
          header: true,
          skipEmptyLines: true,
          quoteChar: '"',
          delimeter: ',',
          fastMode: false, // handles quotes poorly
          transformHeader: (columnName) => this.mapColumn(columnName /* and do not guess */),
          error: (error, file) => {
            console.error("UploadCSV: Unable to read file: ", error, file);
          },
          chunk: ((chunk) => { 
            // Handle Errors
            // The error type will be one of "Quotes", "Delimiter", or "FieldMismatch".
            // It's common to get a TooFewFields error code on the last row of UltraSignup CSV files.
            // @TODO: Do we need to handle these here? If the row succeeds backend validation, shouldn't we keep it?
            //
            if (chunk.errors && chunk.errors.length) {
              console.warn("UploadCSV: parse errors: ", chunk.errors);
              debugger
            }
                    
            // Load into Vuex store
            // we're sending all the column headers here; it's the store's job to 
            // normalize and create relationship (e.g. with headlamp addons)
            console.warn("UploadCSV: push upsertParticipantsChunk promise");
            // Actually start the upload:
            promises.push(
              this.$store.dispatch('upsertParticipantsChunk', {
                addOnMap: Object.assign({}, this.addOnDefs),
                options: this.upsertOptions,
                participants: chunk.data,
              })
            );
          }).bind(this), // I thought we didn't have to bind arrow functions?
          complete: () =>  { 
            console.log("Parse Complete, maybe still uploading", promises);
            Promise.all(promises).then(r => {
              console.debug("Upsert: all promises complete");
              this.$modalWindow.cancel();
              window.location.reload();
            })
            
          }
        });
      },
      
      isDuplicateColumnChoice(index) {
        const needle = this.columnChoices[index];

        if (!needle || needle.startsWith('$')) // '$ignore$', '$addon$', etc
          return false;
        
        const columnChoices = this.columnChoices;
        
        for (var i = columnChoices.length - 1; i >= 0; i--) {
          if (i == index) continue;
          if (columnChoices[i] == needle) return true;
        }
        return false;
      },
      
      // Used as a transformHeader callback for PapaParse
      mapColumn(name, guess=false) {
        console.debug("Mapping Column: ", name);
                
        const columnIndex = this.columns.indexOf(name);
        if (columnIndex < 0) {
          console.warn(`Unexpected column "${name}". Leaving unchanged`);
          return name;
        }
        
        // Check if it's an addon
        //
        // Find the column in the addons map, if it exists.
        const def = this.addOnDefs.find(d => {
          // Does this def's columns include columnIndex x?
          return d.columns.find(c => c.index == columnIndex);
        });

        if (def) {
          console.debug(`Found addon definition for col ${columnIndex}:`, def);
          const defIndex = this.addOnDefs.indexOf(def); // could do this above
          return `mapped_addon_${defIndex}_x_${columnIndex}` // make sure this key isn't duplicate.
        }
        
        // If we've already set our columns and we're not
        // guessing, return what we've set
        if (!guess && this.columnChoices[columnIndex]) {
          return this.columnChoices[columnIndex];
        }

        // Guess at what column this is.
        // Get mapped representation, maybe just sanitized.
        const mappedName = participantsColumnMapper(this.columnMap, name);

        return mappedName;
      },
      
      //////////////////
      
      showDetectedMenu(e) {
        e && e.preventDefault();
        Popover.showModalWithParent(this, event, {
          childComponent: MenuContents,
          childBindings: {
            menus: this.unmappedColumns.map(col => ({
              // icon: '',
              title: col.name,
              action: () => {
                const def = {
                  name: col.name,
                  showOnReg: true,
                  columns: [{index: col.index, variant: null}], // array of map definitions {column: 31, variant: 'M'}
                };
                this.setSelectedAddOnDef(def);
                this.addOnDefs.push(def)
              }
            }))
          },
        });
      },
      
      showPresetOptions(event) {
        // <button class="mini bar preset-save" @click="savePreset" title="Save Preset"></button>
        // <button class="mini bar preset-load" @click="loadPreset" title="Load Selected Preset"></button>
        Popover.showModalWithParent(this, event, {
          childComponent: MenuContents,
          childBindings: {
            menus: [
              {
                icon: '',
                title: ` Save Preset`,
                action: () => this.savePreset()
              },
              {
                icon: '',
                title: `Load Preset`,
                action: () => this.loadPreset(this.selectedPreset)
              },
              {
                type: 'separator'
              },
              {
                icon: '',
                title: `Clear Current Preset`,
                action: () => this.clearPreset()
              },
              {
                type: 'separator'
              },
              {
                title: `Delete Selected Preset`,
                action: () => { console.error("unimplemented"); }
              }
            ],
          },
        });
      },
      
      savePreset() {
        // Get name. Overwrite old preset
        let name = prompt("Preset Name:");

        this.presets[name] = Object.assign({}, this.presetAttributes);
          
        // Save json rep
        this.$nextTick(() => {
          // Save as JSON
          window.localStorage.importMapPresets = JSON.stringify(Object.assign({},this.presets));

          // Ensure the selection is correct
          this.selectedPresetName = name;
        })
      },
      
      loadPreset(preset) {
        console.debug("Loading preset ", preset);
        
        if (!preset.upsertOptions) {
          // Old localStorage schema version
          this.setAddOnDefs(Object.values(preset));
          return;
        }
        
        this.upsertOptions = preset.upsertOptions;
        this.setAddOnDefs(preset.addOns);
        
        if (preset.columnChoices && preset.columnChoices instanceof Array) {
          this.setColumnChoices(preset.columnChoices);
        }
      },
      
      setColumnChoices(c) {
        if (!c) return;
        this.columnChoices.length && this.columnChoices.splice(0, this.columnChoices.length);
        c.forEach(choice => this.columnChoices.push(choice));
      },
      
      setAddOnDefs(defs) {
        if (!defs)
          return;
        
        // clear
        this.addOnDefs.length && this.addOnDefs.splice(0,this.addOnDefs.length);

        // add, respecting reactivity
        defs.forEach(i => this.addOnDefs.push(i));
      },
      
      //////////////////
      
      addAddOnDef() {
        let def = {
          name: 'New Add On',
          showOnReg: true,
          columns: [], // array of map definitions {column: 31, variant: 'M'}
        };
        
        this.addOnDefs.push(def);
        
        this.setSelectedAddOnDef(def);
        this.$nextTick(()=> {
          const elm = this.$refs.addOnDefNameField;
          if (elm) {
            elm.focus();
            elm.select();
          }
        });
      },
      
      removeAddOnDef(def) {
        const i = this.addOnDefs.indexOf(def);
        if (i < 0) return;
        this.addOnDefs.splice(i, 1);
        
        // get closest index to avoid empty selection
        const l = this.addOnDefs.length;
        if (i >= l) {
          // deleted the last add on def 
          if (l > 0) this.setSelectedAddOnDef(this.addOnDefs[l-1]);
        } 
        else {
          if (l > 0) this.setSelectedAddOnDef(this.addOnDefs[0]);
        }
      },
      
      //?a
      removeSelectedAddOnDef() {
        this.removeAddOnDef(this.selectedAddOnDef);
      },
      
      setSelectedAddOnDef(def) {
        console.debug("selected", def);
        this.selectedAddOnDef = def;
      },
      
      clearPreset() {
        this.selectedAddOnDef = null;
        this.addOnDefs = [];
        this.guessColumnMapping();
      },
      
      newAddOnMapping() {
        if (!this.selectedAddOnDef) return;
        
        this.selectedAddOnDef.columns.push({index: undefined, variant: null });
      },
      
      delAddOnMapping(mapping) {
        let cols = this.selectedAddOnDef.columns;
        let i = cols.indexOf(mapping);
        if (i < 0) return; //wat.
        cols.splice(i, 1);
      }
      
    }
  }

</script>