<template>
  <div class="race-event-summary">
    <header class="sub">
      <h1>Event Summary</h1>
    </header>
    <ul>
      <li v-for="race in races" :class="classNameForRace(race)">
        <span class="race-name">{{ race.name }}</span>
        <span class="race-start">{{ startTimeStringForRace(race) }}</span>
      </li>
    </ul>
  </div>
</template>

<style lang="scss">
.race-event-summary {
  color: var(--text-color);

  h1 {
    font-size: 20px;
    margin-left: 0px;
    padding: 0;
  }
  
  ul {
    margin: 4px 4px 0;
    padding: 0;
    list-style: none;
    min-width: 250px;
  }
  
  li {
    display: flex;
    
    span       { flex: 1; }
    .race-name { font-weight: bold; }
  }
  
  
}
</style>

<script>
  export default {
    props: {
      raceEvent: {
        type: Object,
        required: true,
      }
    },
    
    computed: {
      races() {
        return this.$store.getters.races(this.raceEvent.races)
          .sort((a,b) => {
            a = a && a.startTime || new Date('2100-01-01'); // return a distant future date to influence sorting of unset values.
            b = b && b.startTime || new Date('2100-01-01');
            return a - b;
          });
      },
      
      timeLang () { return 'en-US'},
      timeOptions() { return { weekday:'short', hour:'2-digit', minute:'2-digit', hour12: true }}
    },
    
    methods: {
      classNameForRace(race) {
        return 'race'; //@TODO: set class name based on color, maybe inline SVG color icon, etc.
      },
      
      startTimeStringForRace(race) {
        if (!race.startTime)
          return '-';
        
        return race.startTime.toLocaleString(this.timeLang, this.timeOptions)
      }
    }
    
  }
</script>