<template>
  <section>
    <header class="sub">
      <h1 class="flexible-space">
        Recents
      </h1>
      <button
        class="mini"
        :disabled="recentParticipants.length<1"
        @click="clearAll"
      >
        Clear
      </button>
    </header>

    <transition-group
      name="sidebar-list"
      tag="ul"
      class="notifications naked"
    >
      <li
        v-for="par in recentParticipants"
        :key="par.id"
        class="par"
        @click="showParticipant(par)"
      >
        <span
          class="bib"
          :style="bibStyle(par)"
        ><span>{{ par.bib }}</span></span>
        <span class="fn">{{ par.firstName }}</span> <span class="ln">{{ par.lastName }}</span>
      </li>
    </transition-group>
  </section>
</template>

<style lang="scss" scoped>

.par {
  text-align: left;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.85em;
  
  &:hover { background: var(--row-hover-bgcolor);}
  
  .bib {
    color: var(--light-color); 
    background:var(--light-bgcolor); 
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    padding: 2px;
    border-radius: 4px; 
    font-weight: bold; 
    text-align: center;
    min-width: 2em;
    display: inline-block;
    line-height: 1.25em;
  
    min-height: 1em;
    margin-right: 0.5em;
  }
  
  .fn { margin-right: 0.5ex}
  .ln { font-weight: 900;}
}


</style>

<script>

export default {
  name: 'SidebarRecents',

  data() {
    return {
      recents: [
        // {
        //   type: 'nr-warning',
        //   id: 'asdf',
        //   title: "Event Source 'Orca1' disconnected",
        //   detail: "An error occurred"
        // }
      ]
    }
  },
  
  computed: {
    recentParticipants() { return this.$store.state.recentParticipants; }
  },

  mounted() {
  },
  
  methods: {
    bibStyle(par) {
      const race = this.$store.getters.entity('races', par.raceId);
      if (race)
        return `border-top: 8px solid ${race.color}; padding-top: 0px`;
      else
        return `border-top: 8px solid var(--med-bgcolor); padding-top: 0px`;
    },
    
    clearAll() {
      this.$store.commit('clearRecents');
    },
    
    clearNotification(note) {
    },
    
    showParticipant(par) {
      const targetView = localStorage.lastPdTab || 'pd-performance';
      this.$store.commit('pushRecentParticipant', par);
      this.$router.push({name: targetView, params: {id: par.id} });
    }
    
  }
}

</script>