<template>
  <div class="popover-group" :style="elementInlineStyle" @click="groupClicked">
    <div ref="menu" key="popover-menu" :class="elementClassName">
      <!--class: popover-menu-wrap-->
      <div class="popover-menu" @mouseleave="mouseleave">
        <div class="arrow-border" />
        <div class="popover-content">
          <div class="backdrop" />
          <slot />
        </div>
        <div v-if="doneLabel" class="popover-control">
          <button @click.stop="hide">
            {{ doneLabel }}
          </button>
        </div>
      </div>
    </div>
    
    <div key="modalCover" ref="modalCover" class="modal-cover popover"/>
  </div>
</template>

<style src="@/assets/popover.scss" lang="scss"></style>

<script>  
  
  import Vue  from 'vue'
  import popoverSupport from './popover-support'

  const Popover = {
    inheritAttrs: false,
    props: {
      
      zIndex: {
        type: Number,
        default: 500
      },

      doneLabel: {
        type: String,
        default: undefined
      },
      
      prefer: {
        type: String,
        default: 'dn',
      },    
      
      closeOnMouseLeave: {
        type: Boolean,
        default: false
      },
      
      destroyOnClose: {
        type: Boolean,
        default: true
      }
    },
    
    created() {
      this.$root.$on('popover', (e, from) => {
        switch(e) {
          case 'close':
            if (this != from) {
              // console.log("popover close cmd caught from, this", from, this);
              this.hide();
            }
            break;
          default: break;
        }
      })
    },

    mounted(){
      console.log("Dynamic popover mounted", this.$el);
      if (!Popover.didAttachHideEventListener) {
        Popover.didAttachHideEventListener = true;

        console.log("Popover: attach hide event listener");
        document.body.addEventListener('click', Popover.handlePotentialHideClick, true)
      }
      
      this.$parent.$on('update', () => {
        console.log("popover parent updated");
      })
    },
    
    updated() {
      console.debug("Popover updated");
    },
    
    // mounted() {
    //   console.log("popover mounted: menu", this.$refs.menu)
    //   this.position(this.$refs.menu);
    //
    // },
    
    data() { 
      return {
        showingMenu: false,
        direction: 'dn',
        caching: false,
      }
    },
    
    computed: {
      elementClassName() {
        return 'popover-menu-wrap ' + this.direction
      },
      
      elementInlineStyle() {
        if (this.zIndex)
          return `z-index: ${this.zIndex}px`;

        return null;
      }
    },
    
    
    methods: {
    
      ...popoverSupport,
    
      // Will receive modal cover clicks
      groupClicked(e) {
        if (e.target.classList.contains('modal-cover'))
          return;

        if (this.showingMenu)
          return;
        
        this.hide(e);
      },
            
      show(e) {
        if (!this.targetButton)
          console.error("Cannot show popover without a targetButton; please pass into showModalWithParent")
        console.log("Popover: showing from target button:", this.targetButton, e);
        
        // Tell other popovers to close
        this.$root.$emit('popover', 'close', this);
        
        if (!this.$el.parentNode)
          document.body.appendChild(this.$el);
        
        this.position(this.$refs.menu);
        
        window.addEventListener('scroll', this.reposition);
        window.addEventListener('resize', this.reposition);

        // reorder
        let i = Popover.openPopovers.indexOf(this);
        if (i >= 0) Popover.openPopovers.splice(i, 1);
        Popover.openPopovers.push(this);

        this.showingMenu = true
      },
      
      hide() {
        this.showingMenu = false
        console.warn("popover.hide() called; cacheName", this.cacheName);
        
        window.removeEventListener('scroll', this.reposition);
        window.removeEventListener('resize', this.reposition);
        
        // PopoverGroup will contain the modal-cover, but 
        // the menu will be its sibling and won't get removed.
        // [this.$el, this.$refs.menu].forEach((el) => el && el.parentNode && el.parentNode.removeChild(el));
        [this.$el].forEach((el) => el && el.parentNode && el.parentNode.removeChild(el));
        
        // Remove from list
        let i = Popover.openPopovers.indexOf(this);
        if (i >= 0) Popover.openPopovers.splice(i, 1);

        // Emit to anonymous parent node that mounted us dynamically
        this.$emit('close');
        this.$destroy();
      },
      
      mouseleave(e){
        if (this.closeOnMouseLeave && e.target == e.currentTarget) {
          console.log("popover mouseleave");
          this.hide(e);
        }
      },
    
                
      popoverDidClose(arg) {
        this.$emit('close', this);
      },
      
      reposition(e){
        this.position(this.$refs.menu);        
      }

    },
    
    // A global cache of popover elements, ordered by appearance
    openPopovers: [],
    
    // Tracks whether the close event listener is attached to the body
    // so we don't attach it twice.
    //
    didAttachHideEventListener: false,
    
    // a means of checking which popover should close on a document.body click
    handlePotentialHideClick(e){
      // We don't have anything to close.
      if (Popover.openPopovers.length < 1)
        return;
      
      let topPopover;
      for ( let i=Popover.openPopovers.length-1; i>=0; i--) {
        topPopover = Popover.openPopovers[i];
        if (topPopover.showingMenu) {
          // This popover is done opening.
          break;
        }
      }

      const targetElm = topPopover.$refs.menu;
      let el = e.target;
      
      while (el) {
        if (el == targetElm) {
          // This was a click in the topmost popover.
          return;
        }
        el = el.parentElement;
      }
      
      // If we're still here we didn't click in the topmost popover, so close it.
      topPopover.hide();
    },
    
    
    // Returns a promise that resolve in the next tick with the dynamically created child component
    //
    // ModalWindow.showModalWithParent(this, {
    //   childComponent: TattooDisplay,
    //   childBindings: {
    //     linestringFeature: this.linestringFeature,
    //     aidStations: this.aidStations,
    //     colorOptions: this.colorOptions
    //   },
    // });
    //
    showModalWithParent(parent, event, opts={}) { 

      // Create the Modal Window modalWindow
      //
      let childInstance, slotElm;
      var ModalComponent = Vue.extend(Popover);
      ModalComponent.prototype.$store = parent.$store;
      var popover = new ModalComponent({
        parent: parent,
        components: opts.components,
        propsData: Object.assign({/*default props*/}, opts.bindings)
      })
      popover.$mount();
      document.body.appendChild(popover.$el);

      if (opts.childComponent) {
        //@TODO: compile if opts.childComponent is a String
        let ChildComponent = Vue.extend(opts.childComponent);
        Object.keys(parent).filter(k=>k[0]=='$').forEach(k => ChildComponent.prototype[k] = parent[k]);

        childInstance = new ChildComponent({
          components: opts.components,
          parent: popover,
          propsData: opts.childBindings
        });

        childInstance.$isInPopover = true;
        childInstance.$mount(); // generates $el
        slotElm = popover.$createElement('div', childInstance)

        let slots = [slotElm];
        popover.$slots.default = slots;
        
        Vue.nextTick(() => {
          // create a vnode to go in the default modal window slot
          popover.$slots.default = slots;
          popover.$forceUpdate();
        })
      }
    
      // Add buttons
      // if (opts.buttons) {
      //   popover.controlButtons = opts.buttons;
      // }

      // Mount the modal window on the body
      //
      popover.$on('cancel', (e) => {
        Vue.nextTick(() => { 
          console.debug("Removing Popover", popover)
          popover.$el.parentNode.removeChild(popover.$el);
          popover.$destroy();
        })
      })
    
      // Determine the target
      if (event.currentTarget)
        popover.targetButton = event.currentTarget || event.target;
      else
        popover.targetButton = event;
    
    
      // Display the popover
      // parent.$forceUpdate();      
      popover.show();
      
      return new Promise((resolve, reject) => {

        popover.$forceUpdate()

        // We really do need two ticks for the slotElm to
        // generate a real DOM node.
        Vue.nextTick(() => { 
          Vue.nextTick(() => {
            slotElm.elm.appendChild(childInstance.$el);
          
            popover.contentComponent = childInstance;
            popover.position(popover.$refs.menu);

            resolve(popover)
          })
        })
      })
    }    

  }
  
  export default Popover
 
</script>