<template>
  <li :class="crossing.validCrossing ? 'valid' : 'invalid'" :title="tooltip">
    <span class="bib" :title="tooltip" :style="bibStyle">
      <span>{{ bibContent }}</span>
    </span>
    <span class="name" @click="clickParticipant">{{ name.length && name || "Unknown Participant" }}</span>
    <span class="timestamp">
      <span>{{ timestampFormatter.format(crossing.timestamp) }}</span>
      <span class="ms">{{ }}</span>
    </span>
  </li>
</template>

<script>
export default {
  name: 'CrossingRow',
  props: ['crossing', 'timestampFormatter'],
  computed: {
    participant() { return this.$store.getters.entity('participants', this.crossing.participantId) },
    split() { return this.$store.getters.entity('splits', this.crossing.splitId) },
    race()  { return this.participant && this.$store.getters.entity('races', this.participant.raceId) },
    name()  { 
      if (!this.participant)
        return '?';
      return [this.participant.lastName, this.participant.firstName].join(', ');
    },
    bibStyle() {
      let color = this.race.color;
      if (!color) color = 'var(--text-dim3-color)';
      return `--race-color: ${color}`
    },
    bibContent() {
      if (this.participant) {
        if (this.participant.bib) return this.participant.bib;
        // We might not have loaded this participant yet; they might be in a different race.
      }
      
      if (this.crossing && this.crossing.tag) {
        const tag = this.crossing.tag;
        return tag.substr(tag.length-4);
      }
      
      return '--';
    },
    tooltip() {
      return `${this.crossing.tag}/${this.crossing.readerId}`
    },
    crossingMs(){
      if (this.crossing.timestamp) {
        return this.crossing.timestamp.getMilliseconds()
      }
      
      return '---';
    }
  },
  
  methods: {
    clickParticipant() {
      const activeRaceEvent = this.$route.params.activeRaceEvent
      const id = this.participant.id
      this.$router.push({ name: 'participant', params: { activeRaceEvent, id }});
    },
    
    showOptions() {
      console.debug("CrossingRow: Show Options clicked");
    }
  }
}
</script>