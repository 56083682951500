
  <div class="split-options">

    <div v-if="splitIsRoaming" class="form-rows">
      <h1>Roaming Event Sources</h1>
      <p class="note">
        Roaming event sources are not tied to a particular split. They may depend on GPS location,
        or not need a location at all, such as a spotter line.
      </p>
    </div>

    <div v-else class="form-rows">
      <div class="form-row">
        <label for="split-name">Name</label>
        <input ref="firstInput" v-model="split.name" type="text" :disabled="splitIsNull">
      </div>

      <div class="form-row">
        <label for="split-distance">Distance</label>
        <input v-model.lazy="split.distance" type="number" :disabled="splitIsNull" 
        >
        <span class="units">meters</span>
      </div>

<!--          @mousedown="onDistanceChange" 
          @mouseup="onDistanceChange"
          @keyup="onDistanceChange"
 -->

      <div class="form-row">
        <label for="split-cutoff">Cutoff</label>
        <!-- <input type="datetime-local" v-model="split.cutoff" :disabled="splitIsNull" placeholder="No Cutoff"> -->
        <datetime-picker v-model="split.cutoff" :disabled="splitIsNull"/>
      </div>
      
      <div v-if="split.point && split.point.geometry && split.point.geometry.coordinates" class="form-row loc">
        <label>Location:</label>
        <span>{{ locDisplay }}</span>
      </div>

      <div class="form-row pub">
        <label>Public:</label>
        <toggle-switch v-model="split.isPublic" form-id="is_public" />
        <label>Transition Point:</label>
        <toggle-switch v-model="split.isTransitionPoint" form-id="is_transition_point" />
      </div>
      <!-- <div class="form-row">
        <span class="date">{{ split.cutoff | formatDate('MMMM Do YY', 'No Cutoff')}}</span>
      </div>
      <div class="form-row">
        <span class="time">{{ split.cutoff | formatDate("h:mm:ss a", ' ')}}</span>
      </div> -->
    </div>
    
    <div class="event-routes varlist-wrapper">
      <header>
        <label for="event-source">Event Routing</label>
      </header>
      <ul class="no-highlight">
        <li v-for="route in eventRoutes"> 
          <span>Source</span>
          <select v-model="route.eventSourceId" name="event-source" @change="eventRouteChanged(route, 'eventSourceId')">
            <option default disabled :value="undefined">
              Unset
            </option>
            <option v-for="es in eventSources" :value="es.id">
              {{ es.name }}
            </option>
          </select>
          <!-- <input type="text" v-model="route.readerId" placeholder="All Readers"> -->
          <button class="del bar" :disabled="splitIsNull" title="Remove Route" @click="removeEventRoute(route)">
            Delete
          </button>
          <div class="debug">
            {{ route }}
          </div>
        </li>
      </ul>
      <footer class="control">
        <button class="add bar" :disabled="splitIsNull" title="Add Event Route" @click="addEventRoute">
          Add
        </button>
        <i class="flexible-space" />
        <button class="bar" :disabled="splitIsNull || splitIsRoaming" title="Apply event sources to all" @click="applyEventRoutingToSimilar">
          Apply to All
        </button>
        
      </footer>
    </div>
  </div>
