import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'

const AxiosVue = {
  install(Vue, options={}) {
    if (Vue.axios) return;

    let tap = options.tap;
    delete options.tap;
    
    // Create axios instance with global config
    //
    let axios = Axios.create(options)


    // Create an interceptor to set the csrf token
    //
    if (options['csrfHeader']) {
      // Send authenticity token with each request.
      axios.interceptors.request.use(function(config) {
        const mv = document.querySelector('meta[name=csrf-token]');
        if (mv) {
          const t = mv ? mv.getAttribute('content') : '';
          config.headers['x-csrf-token'] = t;
        }
        return config
      });
    }
    
    if (options.token) {
      axios.interceptors.request.use(function(config) {
        const tok = options.token();
        if (tok) {
          config.headers['Authentication'] = `Bearer ${tok}`;
        }

        return config
      });
      
      
      axios.interceptors.response.use((c) => c,
        function(err) {
          if (err.response?.status != 401)
            return Promise.reject(err);
          
          console.debug("Unauthorized");

          if (options.onAuthorizationFailed)
            options.onAuthorizationFailed(err.response)

          return err;
        }
      );
      
    }

    
    if (options['basePath']) {
      axios.interceptors.request.use(function(config) {
        debugger;
      });
    }

    // Yield 
    if (Array.isArray(tap)) {
      tap.forEach(fn => axios = fn(axios));
    }
    else
    if (typeof tap === 'function') {
      axios = tap(axios);
    }

    // Distribute the instance to everything
    // that needs it.
    Vue.axios = axios;
    Vue.prototype.$axios = axios;
    Vuex.Store.prototype.$axios = axios
  }
}

export default AxiosVue