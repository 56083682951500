<template>
  <article class="livetracking">
    <header class="sub">
      <h1 class="flexible-space">
        Live Tracking
      </h1>
      <loading-spinner v-if="livetrackingLoading" />
      <indicator v-else :fill="livetrackColor"/>
    </header>
    <form @submit.prevent>
      <div class="form-row">
        <label for="server">Server</label>
        <input v-model="livetrackingServer" class="lt-s wide" placeholder="timing.aravaiparunning.com">
      </div>
      
      <div class="form-row">
        <label for="username">User:</label>
        <input v-model="livetrackingUser" class="lt-u" type="text" name="username">

        <label for="username">Pass:</label>
        <input v-model="livetrackingPass" class="lt-p" type="password" name="password">
      </div>
    </form>
  </article>
</template>

<style lang="scss">
.livetracking {
  .control {
    width: 115px;
    .indicator {
      margin: 0 0.5em;
    }
    .spinner {
      transform: translate(-16px,-21px) scale(0.30);
      float: left;
      margin-right: -32px;
    }
    span {
      vertical-align: middle;
      margin-right: 8px;
    }
  }
  
  input      { margin-right: 30px;}
  input.lt-s { width: stretch;}
  input.lt-u,
  input.lt-p { width: 130px; }
  input.wide { width: stretch;}
  
  .control-row {
    display: block;
    text-align: right;
    
    button {
      margin-left: 16px;
      padding: 0 10px;
    }
  }
  
  .notes { max-width: 400px;}
}
</style>

<script>
import Indicator from "@/components/indicator.vue"

export default {
  name: 'LiveTrackingDetails', components: { Indicator },

  data() {
    return {
      livetrackingLoading: false,
      livetrackingServer: null,
      livetrackingUser: 'shovel',
      livetrackingPass: 'shovel',
    }
  },
  
  computed: {
    activeRaceEvent() { return this.$store.state.activeRaceEvent },
    raceEventsLoading() { return this.$store.state.raceEventsState == "loading" },
    
    livetrackingIsRunning() { return this.$store.state.livetracking; },
    livetrackingNextAction() {
      if (this.livetrackingLoading)   return {title: 'Working'};
      if (this.livetrackingIsRunning) return {func: this.stopLivetracking, title: 'Stop'};
      return { func: this.startLivetracking, title: 'Start'};
    },
    
    livetrackColor() {
      const lastHB = this.$store.state.livetrackLastHB;
      const delta = (new Date()) - lastHB ;
      
      if (delta > 15000)
        return 'red';
      else
        return 'green';
    },
    
  },
  
  watch: {
    livetrackingLoading(val) {
      if (val) {
        // we're going to set a timeout
        if (this._livetrackingLoadingTimeout)
          clearTimeout(this._livetrackingLoadingTimeout);
        
        setTimeout(() => {
          console.debug("livetracking loading timed out");
          this.livetrackingLoading = false;
        }, 5000);
      }
    }
  },

  mounted() {
    this.$root.$on('livetrackStatusChange', (payload) => {
      console.debug("Got livetrack status change: ", payload);
      this.livetrackingLoading = false;
    });
  },
  
  methods: {
    livetrackingDoNext() {
      const next = this.livetrackingNextAction;
      console.log("livetracking action: ", next);
      next.func.bind(this)();
    },
    
    stopLivetracking() {
      this.livetrackingLoading = true;
      this.$cable.perform({
        channel: 'ControlChannel', 
        action: 'stop_livetracking'
      });
    },
    
    startLivetracking() {
      console.log("livetracking startLivetracking");
      this.livetrackingLoading = true;
      this.$cable.perform({
        channel: 'ControlChannel', 
        action: 'start_livetracking', 
        data: {
          raceEventId: this.activeRaceEvent.id,
          host: this.livetrackingServer,
          user: this.livetrackingUser,
          pass: this.livetrackingPass,
        }
      });
    },
    
    sendKeyframe() {
      this.$cable.perform({
        channel: 'ControlChannel',
        action: 'send_keyframe',
      });
    },
  }
  
}
</script>