<template>
  <section>
    <header class="sub">
      <h1 class="flexible-space">
        Notifications
      </h1>
      <button
        v-if="debug"
        class="mini"
        @click="testAdd"
      >
        Add
      </button>
      <button
        class="mini"
        :disabled="notifications.length<1"
        @click="clearAll"
      >
        Clear
      </button>
    </header>

    <transition-group
      name="sidebar-list"
      tag="ul"
      class="notifications naked"
    >
      <component
        :is="n.type"
        v-for="n in notifications"
        :key="n.id"
        :title="n.title"
        :detail="n.detail"
        :action="n.action"
        :action-title="n.actionTitle"
        @clear="clearNotification(n)"
      />
    </transition-group>
  
    <div v-if="debug">    
      <h3>Debug:</h3>
      <ul>
        <li v-for="n in notifications">
          <pre>
            {{ n.id }}=> {{ n }}
          </pre>
        </li>
      </ul>
    </div>
  </section>
</template>

<style lang="scss" scoped>
#sidebar {
  max-width: 344px;
  z-index: 9;
    
  ul {
    overflow-x: hidden;
    li {
      text-align: left;
      margin: 8px 0;
    }
  }
}

</style>

<script>
import schema from "@/entity.js"
import NrWarning from "@/components/notification-row/nr-warning.vue"

export default {
  name: 'NotificationBar', components: { NrWarning },

  data() {
    return {
      debug: false,
      notifications: [
        // {
        //   type: 'nr-warning',
        //   id: 'asdf',
        //   title: "Event Source 'Orca1' disconnected",
        //   detail: "An error occurred"
        // }
      ]
    }
  },

  mounted() {
    // Load some notifications
    this.$store.dispatch('loadEntities', {
      url: `/notifications`,
      entity: [schema.notification],
    }).then(result => {
      // concat to what's almost certianly an empty array
      Object.values(result.entities.notifications).forEach(n => this.notifications.push(n));
    });
    
    this.$root.progress.$on('progress', e => {
      // Check if any of our notifications need to hear about this progress event
      
    });
    
    this.$root.$on('notification', n => {
      console.log(`notification-bar: recv note (currently have ${this.notifications.length})`, n, this.notifications);
      this.notifications.push(n);
      console.log(`notifications-bar: now have ${this.notifications.length} notes`);
    }) 
  },
  
  methods: {
    clearAll() {
      this.$store.dispatch('clearNotifications').then(r => {
        for (var i = this.notifications.length - 1; i >= 0; i--) {
          setTimeout(() => {
            this.notifications.splice(i, 1);
          }, i * 50);
        }
      });
    },
    
    clearNotification(note) {
      this.$store.dispatch('destroyEntity', {
        entity: note, 
        entitySchema: schema.notification,
        path: `/notifications/${note.id}`
      }).then(note => {
        // Route is now deleted and this is the only reference.
        const i = this.notifications.indexOf(note);
        this.notifications.splice(i, 1);
      });
    },
    
    testAdd() {
      const dummy = {
        type: 'nr-warning',
        id: new Date().getTime(),
        title: `Event Source '${this.notifications.length}' disconnected`,
        detail: "This is a test Notification"
      };
      
      this.notifications.push(dummy);
    }
  }
}

</script>