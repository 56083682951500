<template>
  <article class="bib-planner">
    <header class="sub">
      <h2>Bib Assignment Planner</h2>
    </header>
    <section class="content-wrap">
      <form @submit.prevent> 
        <div class="form-row">
          <label>Race</label>
          <select v-model="selectedRace">
            <option v-for="race in races" :value="race">
              {{ race.name }}
            </option>
          </select>
        </div>
        <div class="form-row">
          <label>Sort By</label>
          <select v-model="sortField">
            <option v-for="name, field in sortFields" :value="field">
              {{ name }}
            </option>
          </select>
        </div>
        <div class="form-row">
          <label>Then Sort</label>
          <select v-model="secSortField">
            <option v-for="name, field in sortFields" :value="field">
              {{ name }}
            </option>
          </select>
        </div>
        
        <div class="form-row">
          <textarea v-model="ranges" placeholder="e.g. 1,3,4-10"/>
        </div>
        <div v-if="rangesError" class="errors">
          {{ rangesError }}
        </div>
      
        <div class="form-row stats">
          <span class="count">Participants: <span>{{ participants.length }}</span></span>
          <span class="preassigned">Preassigned: <span>{{ preAssignedCount }}</span></span>
          <span class="assigned">Will Assign: <span>{{ assignedCount }}</span></span>
          <span class="preassigned">Unassigned: <span>{{ unassignedCount }}</span></span>
          <span class="leftover">Extra Bibs: 
            <span>{{ leftoverBibs.length }} 
              <span v-if="!isNaN(leftoverPercentage)">( {{ leftoverPercentage }}% )</span>
            </span>
          </span>
        </div>
        <div class="form-row control">
          <button :disabled="!this.valid" @click="doPreview">
            Preview
          </button>
          <button :disabled="!this.valid" @click="doAssignment">
            Assign
          </button>
          <span class="flexible-space" />
          <button class="danger" @click="doClearBibs">
            Clear
          </button>
        </div>
      </form>
    
      <div class="preview-tables">
        <div class="flex-scroller-wrapper inset-box">
          <div class="flex-scroller">
            <table>
              <thead>
                <tr>
                  <th>Last</th>
                  <th>First</th>
                  <th>Bib</th>
                  <th>{{ sortFields[sortField] }}</th>
                </tr>
              </thead>
              <tbody class="alternate-rows">
                <tr v-for="p in participants">
                  <td class="last-name">
                    {{ p.lastName }}
                  </td>
                  <td class="first-name">
                    {{ p.firstName }}
                  </td>
                  <td class="bib">
                    {{ bibForPar(p) }}
                  </td>
                  <td class="metric">
                    {{ sortAttrForPar(p) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </article>
</template>

<style lang="scss" scoped>

.content-wrap {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  
  button.danger {
    min-width: 86px;
  }
  
  form {
    textarea {
      height: 8em;
    }
    
    .stats {
      display: block; 
      &>span { display: block; }
    }
  }

  .preview-tables {
    min-width: 480px;
    display: flex;
    flex-direction: column;
    margin-left: 1em;
    
    th { text-align:left;}
    td { padding: 0 .5em;}
    td:first-child { padding-left: 1em;}
  }
  
}



.errors {
  display: block;
  color: var(--error-color);
  font-weight: bold;
}

.assigned,
.leftover {
  margin-left: .5em;
}

textarea {
  width: 100%;
}
</style>

<script>

import schema from "@/entity.js"
import BibRangeParser from "@/lib/bib-range-parser.js"

export default {
  
  data() {
    return {
      dateFormatter: new Intl.DateTimeFormat('en-US', {
        month: 'short', 
        day: 'numeric', 
        hour: 'numeric', 
        minute: '2-digit', 
        second: '2-digit', 
        hour12: true,
      }),
      
      selectedRace: null,
      ranges: '',
      rangesError: null,
      sortField: 'registeredAt',
      secSortField: 'lastName',
      sortFields: {
        'registeredAt': 'Registration Date',
        'lastName': 'Last Name',
        'waveId': 'Wave',
        'usRank': 'Rank'
      },
      
      assignments: {
        // participantId: proposedNumber
      },
      preAssignments: {
        // participantId: proposedNumber; for display only
      },
      
      assignedCount: 0,
      leftoverBibs: [],
    }
  },
  
  computed: {
    valid() { 
      return !!this.selectedRace && !!this.ranges
    },

    raceEvent() { return this.$store.state.activeRaceEvent; },

    races() { 
      if (!this.raceEvent) return [];
      return this.$store.getters.races(this.raceEvent.races).sort((a,b) => a.distance - b.distance);
    },

    unsortedParticipants() {
      return this.$store.getters.entity('participants', this.selectedRace.participants );
    },

    participantRankings() {
      let participants = this.unsortedParticipants;
      let cache = {};
      let rankings = this.raceEvent.usRankings;

      participants.forEach(p=> {
        const r = rankings.find(r => 
          r.firstName.toLowerCase() == p.firstName.toLowerCase() && 
          r.lastName.toLowerCase() == p.lastName.toLowerCase()
        )

        if (r) {
          cache[p.id] =  {
            ranking: r.ranking,
            count: r.count
          };
        }
      });
      
      return cache;
    },

    participants() {
      if (!this.selectedRace)
        return [];
      
      let participants = this.unsortedParticipants;

      participants.sort(this.sortFuncForField(this.secSortField));
      participants.sort(this.sortFuncForField(this.sortField));
      
      return participants;
    },
    
    preAssignedParticipants() {
      return this.participants.filter(p => p.bib);
    },
    
    preAssignedCount(){
      return this.preAssignedParticipants.length;
    },
    
    unassignedCount() {
      return this.participants.length - this.preAssignedCount;
    },
    
    leftoverPercentage() {
      if (0==this.preAssignedCount)
        return NaN;
      
      return (this.leftoverBibs.length / this.preAssignedCount).toFixed(2) * 100;
    },
    
    parsedRanges() {
      const rangeParser = new BibRangeParser(this.ranges);
      if (!rangeParser.parseRanges()) {
        this.rangesError = rangeParser.error;
      }
      
      return rangeParser.parsedNumbers;
    },

    
  },
  
  watch: {
    selectedRace() {
      this.ranges = this.selectedRace.bibRanges
    },
    
    participants() {
      // Note already-assigned numbers so they will
      // display correctly.
      this.participants.forEach(p => {
        if (p.bib) this.preAssignments[p.id] = p.bib;
      });
    }
  },
  
  methods: {
    
    sortFuncForField(field) {
      let sortFunc;
      
      switch (field) {
      case 'usRank': {
        let cache = this.participantRankings;
        sortFunc = (a,b) => {
          const aRank = cache[a.id] && cache[a.id].ranking || -Infinity, 
                bRank = cache[b.id] && cache[b.id].ranking || -Infinity;

          console.debug("sort ranks", aRank, bRank);

          if (aRank > bRank) return -1;
          if (aRank < bRank) return 1;
          return 0;
        }

        break;
      }
      default: 
        sortFunc = (a,b) => {
          if (a[field] < b[field]) return -1;
          if (a[field] > b[field]) return 1;
          return 0;
        }
      }
      
      return sortFunc;
    },
    
    bibForPar(p) {
      return this.assignments[p.id] || this.preAssignments[p.id];
    },
    
    sortAttrForPar(par) {
      switch (this.sortField) {
      case 'usRank': {
        let r = this.participantRankings[par.id] && this.participantRankings[par.id].ranking;
        return r && (r * 100).toFixed(1);
      }
      case 'registeredAt': {
        let d = par[this.sortField];
        return d && this.dateFormatter.format(d) || '-';
      }
      default:
        return par[this.sortField]
      }
    },
    
    doClearBibs(){
      if (!confirm("Really clear all bibs for this race? This cannot be undone"))
        return;
      
      this.participants.forEach(p => {
        this.preAssignments[p.id] = null;
        this.$set(this.assignments, p.id, null);
      });
      
      this.leftoverBibs = [...this.parsedRanges];
      
      this.doAssignment();
    },
    
    assignBibs() {
      // reset
      this.assignedCount = 0;
      Object.keys(this.assignments).forEach(k => this.$delete(this.assignments, k));
      Object.keys(this.preAssignments).forEach(k => this.$delete(this.preAssignments, k));
        
      // Assign
      let numbers = [...this.parsedRanges];
      
      // Remove already-assigned numbers from the list
      // of bibs we're willing to assign.
      this.participants.forEach(p => {
        if (!p.bib) return;
        const i = numbers.indexOf(parseInt(p.bib));
        if (i >= 0) numbers.splice(i,1);
        this.preAssignments[p.id] = p.bib;
      });

      console.log("Assigning");
      this.participants.every(p => {
        // set participantId = assignment
        if (numbers.length < 1) 
          return false;
        
        if (p.bib) {
          console.warn("Skipping already-assigned", p.bib)
          return true; // continue
        }
        
        let bib = numbers.shift();
        console.debug("Assigning ", bib, p);

        this.$set(this.assignments, p.id, bib);
        this.assignedCount++;
        return true;
      });
      
      this.leftoverBibs = numbers;
    },
    
    doPreview(e){
      console.debug("Do Preview", this.parsedRanges);
      this.assignBibs();
    },
    
    doAssignment(e) {
      console.debug("Do Assign");
      
      // Assign the race ranges
      this.selectedRace.bibRanges = this.ranges;
      this.$store.dispatch('saveEntity', {
        path: `/race_events/${this.selectedRace.raceEventId}/races`,
        entity: schema.race,
        object: this.selectedRace
      });
      
      let payload = {
        assignments: {} // parId: bib
      };
      
      Object.keys(this.assignments).forEach(parId => {
        let p = this.$store.state.participants[parId];
        p.bib = this.assignments[parId];
        
        payload.assignments[parId] = p.bib;
      });

      const path = `/race_events/${this.selectedRace.raceEventId}/participants/assign_bibs`;
      this.$axios.request({
        method: 'put',
        url: path,
        data: payload
      })
      .then(r => console.log("assignment ok", r))
      .catch(r=> {
        console.error("assignment err", r);
        this.rangesError = r;
      });

    }
  }
}
</script>