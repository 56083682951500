
<section>
  <header class="sub">
    <h1 class="flexible-space">
      Notifications
    </h1>
    <button
      v-if="debug"
      class="mini"
      @click="testAdd"
    >
      Add
    </button>
    <button
      class="mini"
      :disabled="notifications.length<1"
      @click="clearAll"
    >
      Clear
    </button>
  </header>

  <transition-group
    name="sidebar-list"
    tag="ul"
    class="notifications naked"
  >
    <component
      :is="n.type"
      v-for="n in notifications"
      :key="n.id"
      :title="n.title"
      :detail="n.detail"
      :action="n.action"
      :action-title="n.actionTitle"
      @clear="clearNotification(n)"
    />
  </transition-group>

  <div v-if="debug">    
    <h3>Debug:</h3>
    <ul>
      <li v-for="n in notifications">
        <pre>
          {{ n.id }}=> {{ n }}
        </pre>
      </li>
    </ul>
  </div>
</section>
