
function setEventNameForBinding(el, binding) {
  // Event UX: lazy (on blur) or immediate?
  binding.eventName = 'blur';
  
  const isImmediateElement = () => {
    if (binding.arg.indexOf('!!') >= 0) return true;
    return (el.nodeName == 'SELECT' || el.type == 'checkbox' || el.type=='radio' || el.type=='range');
  }

  // Support explicit event name setting
  if (binding.arg.indexOf('^') >= 0) {
    let [arg, eventName] = binding.arg.split('^');
    binding.arg = arg;
    binding.eventName = eventName;
    return;
  }

  // Support forcing immediate mode by placing
  // an exclamation mark at the end of the attr name
  if (binding.arg.indexOf('!') == binding.arg.length-1) {
    console.log(`detect-change: will use immediate mode on ${binding.arg}`);
    binding.arg = binding.arg.substr(0, binding.arg.length-1);
    
    if (isImmediateElement(el)) {
      binding.eventName = 'change';
    }
    else {
      binding.eventName = 'keyup';
    }
  }
  else
  // Some elements need to be immediate by default
  if (isImmediateElement(el)) {
    binding.eventName = 'change';
  }
}

function mapElementToAttr(vm, attr, el) {
  if (!vm.attrElementMap)
    vm.attrElementMap = {};
  
  if (!vm.attrElementMap[attr])
    vm.attrElementMap[attr] = new Set();
  
  // console.log(`map ${attr} => `, el);
  
  vm.attrElementMap[attr].add(el);
}

function getValue(elm) {
  let val;

  if (elm.__vue__)
    val = elm.__vue__.value;
  else
    val = elm._vnode.data.domProps?.value;
  
  switch (elm.type) {
    case 'number': console.log(`parseFloat(${val})`); val = parseFloat(val); break;
    default: break;
  };
  
  return val;
}

const detectChangeDirective = {
  bind(el, binding, vnode) {
    console.log('%cdetect-change: bind called', 'color:magenta', el, binding);
    const vm = vnode.context;

    if (!vm.attributeChanged) {
      console.error("Cannot pass detected changes to vue component; vm.attributeChanged(attr) is not defined.", vm.$options.name || vm)
      return;
    }
    
    setEventNameForBinding(el, binding);

    let attrs = [binding.arg];
    Object.keys(binding.modifiers).forEach(a=>attrs.push(a)); //concat
    attrs.forEach(attr => mapElementToAttr(vm, attr, el));

    binding.listener = (e) => {
      console.log(`%cdetect-change: ${binding.eventName} on ${binding.arg} (${attrs.length} args)`,'color:magenta', attrs, vm);
      console.log(`%cdetect-change: ${binding.eventName} on ${binding.arg}`,'color:magenta', e.target.dataset.oldValue, e.target.value);
      
      let elm = e.target;
      let val = getValue(elm);
      
      attrs.forEach(a => vm.attributeChanged(a.replace('@', '.'), val, elm._lastValue));
      elm._lastValue = val;
    };

    el.addEventListener(binding.eventName, binding.listener);
  },

  inserted(el, binding, vNode, oldVNode) {
    el._vnode = vNode;
    el._lastValue = getValue(el);
  },

  update(el, binding, vNode, oldVNode) {
    // Track vNode to be able to access last value.
    el._vnode = vNode;
  },

  unbind(el, binding) {
    if (binding.listener) {
      // console.debug('%cdetect-change: detect-change: unbind', 'color:magenta', el);
      el.removeEventListener(binding.eventName, binding.listener);
    }
  }
}

// This mixin adds support for reacting to model changes that come
// in over a websocket.
//
// It adds a method to prevent a change from being overwritten if
// a local attribute is currently being edited when a remote client
// changes its value in the background.
//
// This is called by the watcher, which catches deep attribute modification
// on the given model object
//
export const detectChangeMixin = (modelName) => {
  let mixin = {
    watch: {},
    methods: {
      attrIsFocused(a) {
        if (!this.attrElementMap)
          return false;
        
        const elms = this.attrElementMap[a];
        if (!elms) return false;
        let f=false;
        elms.forEach(e=> {
          if (document.activeElement == e) f = true;
        })
        return f;
      },
    }
  };
  
  mixin.watch[modelName] = {
    deep: true,
    handler(val) {
      const p = this[modelName];
      Object.keys(p).forEach( k=> {
        // copy the key unless an element is selected
        // console.debug(`deep watcher ${this.$options.name}: might copy ${k}: (changed? ${this.attributes[k] != p[k] ? 'yes':'no'}; unfocused? ${!this.attrIsFocused(k)})`);
        if (this.attributes[k] != p[k] && !this.attrIsFocused(k))  {
          // console.debug(`deep watcher ${this.$options.name}: set ${k}=${p[k]}`);
          this.$set(this.attributes, k, p[k]);
        }
      });
    }
  }
  
  return mixin;
}

export function installAttributeChange(vue) {
  
  // Shorthand to allow <input v-detect-change:bib > to call vm.attributeChanged('bib') on blur
  
  detectChangeDirective.vue = vue;
  vue.directive('detect-change', detectChangeDirective);
}